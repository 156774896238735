import React, { useEffect, useState } from 'react';
import { useModal } from '../contexts/ModalContext';
import { useAlert } from '../contexts/AlertContext';
import useFetch from '../hooks/useFetch';
import RdvDisp from './RdvDisp';
import activeStar from '../svgs/activeStar.svg';
import inactiveStar from '../svgs/inactiveStar.svg';
import { useNavigate } from 'react-router-dom';


// Composant pour afficher une étoile unique
const Star = ({ displayed, onClick, onMouseEnter, onMouseLeave }) => (
    <img
        className={`starimg ${displayed ? 'active' : ''}`}
        src={displayed ? activeStar : inactiveStar}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
        alt="star"
    />
);

// Composant pour afficher et sélectionner la note avec aperçu au survol
const Rating = ({ label, value, setValue }) => {
    const [hoverIndex, setHoverIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    const handleClick = (index) => {
        setValue(index + 1);
    };

    return (
        <div className='raterow'>
            <p>{label}: <span className='strong'>{value}/5</span> </p>
            <div className='note'>
                {[...Array(5)].map((_, index) => (
                    <Star
                        key={index}
                        displayed={hoverIndex !== null ? index <= hoverIndex : index < value}
                        onClick={() => handleClick(index)}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    />
                ))}
            </div>
        </div>
    );
};



const Rate = () => {
    const navigate = useNavigate();
    const [sendSubmitRdv, setSendSubmitRdv] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const { openAlert } = useAlert();
    const [disponibilite, setDisponibilite] = useState(0);
    const [ponctualite, setPonctualite] = useState(0);
    const [qualiteService, setQualiteService] = useState(0);
    const [relationnel, setRelationnel] = useState(0);
    const [experienceClient, setExperienceClient] = useState(0);
    const [prix, setPrix] = useState(0);
    const [errorNb, setErrorNb] = useState(null);
    const { closeModal, reload, rdvForModal } = useModal();
    const { data: rdvcanceltxt, loading, error } = useFetch(`/prestataire-pages?need=textAnnulRdv`, 'GET', null, true, false);
    const { data: rdvAddRate, loading: rdvAddRateLogin, error: rdvAddRateError } = useFetch(`/notes`, 'post', submitData, true, sendSubmitRdv);

    const close = () => {
        closeModal();
    }

    useEffect
        (() => {
            if (rdvAddRate) {
                reload(rdvForModal);
                openAlert("Votre note à été enregistrée avec succés", true);
                closeModal();
            }
            else if (error) { 
                setSendSubmitRdv(false);
            }
        }
        , [rdvAddRate, error]);

  
    

    // Fonction pour soumettre les notes
    const submitReview = () => {
        // Ici, vous traiteriez les données, comme les envoyer à votre backend
        // crée un tableau avec chaque valeur et met le dans submitData 
        if (disponibilite === 0 || ponctualite === 0 || qualiteService === 0 || relationnel === 0 || experienceClient === 0 || prix === 0) {
            setErrorNb("Veuillez noter tous les critères");
        }
        else {
            const nSubmitData = {
                disponibilite: disponibilite,
                ponctualite: ponctualite,
                service: qualiteService,
                relationnel: relationnel,
                exp: experienceClient,
                prix: prix,
                rdv: rdvForModal
            };
            setSubmitData({data: nSubmitData});
            // Ensuite, vous pouvez envoyer submitData à votre backend
            setSendSubmitRdv(true);
           

        }
    };
    return (
        <div className='rate'>
            <h3>Évaluer le rendez-vous</h3>
            <div className="left">
                { rdvForModal && <RdvDisp idrdv={rdvForModal} type={"note"} onLoaded={() => null} />} 
            </div>
            <div className="form">
                <Rating label="Disponibilité" value={disponibilite} setValue={setDisponibilite} />
                <Rating label="Ponctualité" value={ponctualite} setValue={setPonctualite} />
                <Rating label="Qualité du service" value={qualiteService} setValue={setQualiteService} />
                <Rating label="Relationnel" value={relationnel} setValue={setRelationnel} />
                <Rating label="Expérience du client" value={experienceClient} setValue={setExperienceClient} />
                <Rating label="Prix" value={prix} setValue={setPrix} />
                {errorNb && <p>{errorNb}</p>}
            </div>
            <div className="actions">

                <button className='btn-pro scnd' type='button' onClick={() =>( closeModal() && navigate("/compte" ) )}> <p>Annuler</p></button>

                <button className='btn-pro' type='button' onClick={submitReview}><p>Envoyer</p></button>
            </div>
        </div>

    )
};

export default Rate;