import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../img/logo.png';
import Searchbar from './Searchbar';
import { useAlert } from '../contexts/AlertContext';
import Alert from './Alert';
import { useModal } from '../contexts/ModalContext';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import NotifPage from './NotifPage';

const Header = () => {
    const [isOpen, setIsOpen] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const codePass = params.get('codePass');

    useEffect(() => {
        const path = location.pathname;
        if (path.endsWith("/connexion")) {
            handleOpenLogin();
        } else if (path.endsWith("/inscription")) {
            handleOpenRegister();
        } else if (path.endsWith("/inscriptionpro")) {
            handleOpenRegisterPro();
        }
    }, [location.pathname]);

    const isActive = (path) => {
        return location.pathname === path;
    };

    const { openModal, closeModal, openNotif, modalContent, nbNotifs, setNbNotifs } = useModal();
    const { isAuthenticated, logout, userRole, userInfo } = useAuth();

    if (codePass) {
        openModal('lost');
    }
    // useEffect(() => {
    //     // Redirection si l'utilisateur n'est pas authentifié ou si son rôle n'est pas 'pro'
    //     if (!isAuthenticated()) {
    //         // Rediriger vers l'accueil
    //         // Note : Vous devrez peut-être ajuster cette partie pour qu'elle fonctionne dans votre environnement de routage
    //         window.location.href = '/';
    //     }
    // }, [isAuthenticated, userRole]);

    useEffect(() => {
        if (userInfo && userInfo.nb_notif > 0) {
            setNbNotifs(userInfo.nb_notif);
        }
    }
        , [userInfo]);

    useEffect(() => {
        if (isAuthenticated()) {
            closeModal();
        }

    }, [userRole,]);

    const handleOpenLogin = () => {
        // Modifier l'URL sans recharger la page
        window.history.pushState(null, null, '/connexion');
        // Ouvrir la modalité de connexion
        openModal('login');
    };

    const handleOpenRegister = () => {
        // Modifier l'URL sans recharger la page
        window.history.pushState(null, null, '/inscription');
        // Ouvrir la modalité d'inscription
        openModal('register');
    };

    const handleOpenRegisterPro = () => {
        // Modifier l'URL sans recharger la page
        window.history.pushState(null, null, '/inscriptionpro');
        // Ouvrir la modalité d'inscription professionnelle
        openModal('registerpro');
    };
    const toValue = userRole === 'pro' ? "/pro/accueil" : "/compte";


    return (
        <div className='header'>
            <NotifPage />
            <div className="contentheader">

                <div className="logo">
                    <img src={Logo} alt="" />
                </div>
                <div className="nav">
                    <div className="topnav">
                        <Searchbar />
                        {isAuthenticated() ?
                            <button className='btn-base danger' onClick={() => logout()}> <p>Deconnexion</p></button>
                            : <button className='btn-base' onClick={handleOpenRegisterPro}> <p>Inscrire votre entreprise</p></button>
                        }
                    </div>
                    <div className="mainnav">
                        <nav className='mainmenu'>
                            <ul>
                                <li className={isActive('/') ? 'active' : ''}>
                                    <Link to="/" >
                                        Accueil
                                    </Link>
                                </li>
                                <li className={isActive('/categorie/coiffure') ? 'active' : ''}>
                                    <Link to="/categorie/coiffure" >
                                        Coiffure
                                    </Link>
                                </li>
                                <li className={isActive('/categorie/soins-esthetiques') ? 'active' : ''}>
                                    <Link to="/categorie/soins-esthetiques" >
                                        Soins esthétiques
                                    </Link>
                                </li>
                                <li className={isActive('/categorie/onglerie') ? 'active' : ''}>
                                    <Link to="/categorie/onglerie" >
                                        Onglerie
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        {isAuthenticated() && userRole ?
                            <nav>
                                <div className='notifs' onClick={openNotif}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                        <path d="M9.46081 13.943C9.46094 14.3568 9.30992 14.7554 9.03803 15.0589C8.76614 15.3623 8.39347 15.5482 7.99474 15.5792L7.87587 15.5833H6.29092C5.89105 15.5835 5.50592 15.4272 5.21272 15.1458C4.91952 14.8644 4.73992 14.4787 4.70993 14.066L4.70597 13.943H9.46081ZM7.08339 7.82502e-10C8.52172 -2.45602e-05 9.90382 0.578136 10.9382 1.61253C11.9725 2.64691 12.5782 4.05666 12.6275 5.54438L12.6307 5.74123V8.82836L14.0746 11.8171C14.1376 11.9475 14.1691 12.0918 14.1665 12.2375C14.1639 12.3833 14.1271 12.5263 14.0594 12.6541C13.9917 12.782 13.8951 12.891 13.7778 12.9718C13.6605 13.0525 13.526 13.1027 13.3859 13.1179L13.2948 13.1228H0.871979C0.731049 13.1228 0.592209 13.0875 0.467358 13.0199C0.342508 12.9522 0.235368 12.8542 0.155121 12.7343C0.0748732 12.6144 0.0239109 12.4762 0.00660081 12.3314C-0.0107093 12.1867 0.00614895 12.0397 0.0557308 11.9032L0.0921846 11.8171L1.53607 8.82836V5.74123C1.53607 4.21856 2.12052 2.75825 3.16084 1.68157C4.20117 0.604877 5.61215 7.82503e-10 7.08339 7.82502e-10Z" fill="#FF5E00" />
                                    </svg>
                                    <p className='nouvsoum strong' >Nouvelles soumissions</p>
                                    {nbNotifs && nbNotifs > 0 && <div className='nbnotif'><p>{nbNotifs}</p></div>}
                                </div>
                                <Link className={isActive('/compte') ? ' btn-front spe active' : 'btn-front spe'} to={toValue} >
                                    <span />
                                    <p>Mon Compte </p>
                                    <div className="icon">
                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99999 7.2C9.98822 7.2 11.6 5.58822 11.6 3.6C11.6 1.61177 9.98822 0 7.99999 0C6.01177 0 4.39999 1.61177 4.39999 3.6C4.39999 5.58822 6.01177 7.2 7.99999 7.2Z" fill="black" />
                                            <path d="M15.2 13.95C15.2 16.1865 15.2 18 7.99999 18C0.799988 18 0.799988 16.1865 0.799988 13.95C0.799988 11.7135 4.02379 9.90002 7.99999 9.90002C11.9762 9.90002 15.2 11.7135 15.2 13.95Z" fill="black" />
                                        </svg>

                                    </div>
                                </Link>
                            </nav>

                            :
                            <nav className='connectmemu'>
                                <button className='btn-front spe ' onClick={handleOpenRegister}>
                                    <span />
                                    <p>Inscription</p>
                                    <div className="icon">
                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.99999 7.2C9.98822 7.2 11.6 5.58822 11.6 3.6C11.6 1.61177 9.98822 0 7.99999 0C6.01177 0 4.39999 1.61177 4.39999 3.6C4.39999 5.58822 6.01177 7.2 7.99999 7.2Z" fill="black" />
                                            <path d="M15.2 13.95C15.2 16.1865 15.2 18 7.99999 18C0.799988 18 0.799988 16.1865 0.799988 13.95C0.799988 11.7135 4.02379 9.90002 7.99999 9.90002C11.9762 9.90002 15.2 11.7135 15.2 13.95Z" fill="black" />
                                        </svg>
                                    </div>
                                </button>
                                <button onClick={handleOpenLogin} className='btn-front black'>
                                    <p>Connexion</p>
                                </button></nav>
                        }


                    </div>
                </div>


            </div>
            <div className="mobileheader">
                <div className="btnmenu" onClick={() => menuOpen ? setMenuOpen(false) : setMenuOpen(true)}>
                    {menuOpen ?
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="14.8492" width="21" height="2" transform="rotate(-45 0 14.8492)" fill="#006963" />
                            <rect x="1.41422" width="21" height="2" transform="rotate(45 1.41422 0)" fill="#006963" />
                        </svg>
                        :
                        <svg viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="21" height="2" fill="#006963" />
                            <rect y="7" width="21" height="2" fill="#006963" />
                            <rect y="14" width="21" height="2" fill="#006963" />
                        </svg>}
                </div>
                <img src={Logo} alt="" />

            </div>

            <div className={menuOpen ? "leftMobileMenu" : " leftMobileMenu closed"} >
                <div className="btnmenu" onClick={() => setMenuOpen(false)}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="14.8492" width="21" height="2" transform="rotate(-45 0 14.8492)" fill="#006963" />
                        <rect x="1.41422" width="21" height="2" transform="rotate(45 1.41422 0)" fill="#006963" />
                    </svg>

                </div>
                <nav className='mainmenu'>
                    <ul>
                        <li className={isActive('/') ? 'active' : ''}>
                            <Link to="/" >
                                Accueil
                            </Link>
                        </li>
                        <li className={isActive('/categorie/coiffure') ? 'active' : ''}>
                            <Link to="/categorie/coiffure" >
                                Coiffure
                            </Link>
                        </li>
                        <li className={isActive('/categorie/soins-esthetiques') ? 'active' : ''}>
                            <Link to="/categorie/soins-esthetiques" >
                                Soins esthétiques
                            </Link>
                        </li>
                        <li className={isActive('/categorie/onglerie') ? 'active' : ''}>
                            <Link to="/categorie/onglerie" >
                                Onglerie
                            </Link>
                        </li>
                    </ul>
                </nav>
                {isAuthenticated() && userRole ?
                    <nav>
                        <div className='notifs' onClick={openNotif}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                <path d="M9.46081 13.943C9.46094 14.3568 9.30992 14.7554 9.03803 15.0589C8.76614 15.3623 8.39347 15.5482 7.99474 15.5792L7.87587 15.5833H6.29092C5.89105 15.5835 5.50592 15.4272 5.21272 15.1458C4.91952 14.8644 4.73992 14.4787 4.70993 14.066L4.70597 13.943H9.46081ZM7.08339 7.82502e-10C8.52172 -2.45602e-05 9.90382 0.578136 10.9382 1.61253C11.9725 2.64691 12.5782 4.05666 12.6275 5.54438L12.6307 5.74123V8.82836L14.0746 11.8171C14.1376 11.9475 14.1691 12.0918 14.1665 12.2375C14.1639 12.3833 14.1271 12.5263 14.0594 12.6541C13.9917 12.782 13.8951 12.891 13.7778 12.9718C13.6605 13.0525 13.526 13.1027 13.3859 13.1179L13.2948 13.1228H0.871979C0.731049 13.1228 0.592209 13.0875 0.467358 13.0199C0.342508 12.9522 0.235368 12.8542 0.155121 12.7343C0.0748732 12.6144 0.0239109 12.4762 0.00660081 12.3314C-0.0107093 12.1867 0.00614895 12.0397 0.0557308 11.9032L0.0921846 11.8171L1.53607 8.82836V5.74123C1.53607 4.21856 2.12052 2.75825 3.16084 1.68157C4.20117 0.604877 5.61215 7.82503e-10 7.08339 7.82502e-10Z" fill="#FF5E00" />
                            </svg>
                            {nbNotifs && nbNotifs > 0 && <div className='nbnotif'><p>{nbNotifs}</p></div>}
                        </div>
                        <Link className={isActive('/compte') ? ' btn-front spe active' : 'btn-front spe'} to={toValue} >
                            <span />
                            <p>Mon Compte</p>
                            <div className="icon">
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99999 7.2C9.98822 7.2 11.6 5.58822 11.6 3.6C11.6 1.61177 9.98822 0 7.99999 0C6.01177 0 4.39999 1.61177 4.39999 3.6C4.39999 5.58822 6.01177 7.2 7.99999 7.2Z" fill="black" />
                                    <path d="M15.2 13.95C15.2 16.1865 15.2 18 7.99999 18C0.799988 18 0.799988 16.1865 0.799988 13.95C0.799988 11.7135 4.02379 9.90002 7.99999 9.90002C11.9762 9.90002 15.2 11.7135 15.2 13.95Z" fill="black" />
                                </svg>

                            </div>
                        </Link>
                    </nav>

                    :
                    <nav className='connectmemu'>
                        <button className='btn-front spe ' onClick={handleOpenRegister}>
                            <span />
                            <p>Inscription</p>
                            <div className="icon">
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99999 7.2C9.98822 7.2 11.6 5.58822 11.6 3.6C11.6 1.61177 9.98822 0 7.99999 0C6.01177 0 4.39999 1.61177 4.39999 3.6C4.39999 5.58822 6.01177 7.2 7.99999 7.2Z" fill="black" />
                                    <path d="M15.2 13.95C15.2 16.1865 15.2 18 7.99999 18C0.799988 18 0.799988 16.1865 0.799988 13.95C0.799988 11.7135 4.02379 9.90002 7.99999 9.90002C11.9762 9.90002 15.2 11.7135 15.2 13.95Z" fill="black" />
                                </svg>
                            </div>
                        </button>
                        <button onClick={handleOpenLogin} className='btn-front black'>
                            <p>Connexion</p>
                        </button>
                    </nav>
                }


            </div>
        </div >
    );
};

export default Header;