import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ send, action }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, userRole, isAuthenticated, connectErr } = useAuth();
    const { closeModal,openModal } = useModal();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(email, password);
            // La connexion a réussi
        } catch (error) {
            // Gérer les erreurs de connexion ici
        }
    };

    const handleOpenLogin = () => {
        openModal('register');
    };

    const handleOpenLost = () => {
        openModal('lost');
    };

    useEffect(() => {
        if (isAuthenticated()) {
            closeModal();
        }
        if (isAuthenticated() && userRole == "pro") {
            navigate(`/pro/accueil`);
        }
    }, [userRole, closeModal]);


    return (
        <form className='login' onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            {!send &&
                <h3>Connexion</h3>}
            <div className="form-group">

                <label htmlFor="email">Adresse email</label>
                <input
                    type="email"
                    className="inputs"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
            </div>
            <div className="form-group">

                <label htmlFor="passoworld">Mot de passe</label>
                <input
                    type="password"
                    className="inputs"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                />
            </div>
            
            {connectErr &&
            <div className="form-group"> <p className="bigerror">{connectErr}</p></div>
            }
            
                <div className="btns">
                    {!send && <button className="btn-pro scnd " type='button' onClick={closeModal}><p>Retour</p></button>}
                    <button className='btn-pro'type="submit" onClick={handleSubmit}><p>Se connecter</p></button>

                </div>
                <div className="btns">
                     <button className='btn-base'type="button" onClick={ handleOpenLost} ><p>Mot de passe oublié</p></button>
                     <button className='btn-base' type="button" onClick={ handleOpenLogin}><p>Inscrivez-vous</p></button>
                </div>

        </form>
    );
};

export default LoginForm;