import React, { useEffect, useState} from 'react';
import { fr } from 'date-fns/locale';
import useFetch from '../hooks/useFetch';
import { useAuth } from '../contexts/AuthContext';
import RdvDisp from './RdvDisp';
import { useModal } from '../contexts/ModalContext';
import { useAlert } from '../contexts/AlertContext';
import { format, parseISO, set } from 'date-fns';
import Loader from './Loader';
import DOMPurify from 'dompurify';


const RdvCancel = ({rdv}) => {
    const { closeModal, reload } = useModal();
    const { user, userRole  } = useAuth();
    const [txtCancel, setTxtCancel] = useState(null);
    const [sendSubmitRdv, setSendSubmitRdv] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const [type, setType] = useState('pro');
    const { data: rdvcanceltxt, loading, error } = useFetch(`/prestataire-pages?need=textAnnulRdv`, 'GET', null, true, userRole === 'pro' ? true : false);
    const { data: rdvcancelSubmit, loading: rdvcancelLogin, error:rdvcancelError } = useFetch(`/rdvs/${rdv.id}`, 'PUT', submitData, true, sendSubmitRdv);
    const {openAlert} = useAlert();

    const formatTxtCancel = (txt, rdv) => {
        if (!rdv) return txt; 
        // Formater la date
        const formattedDate = format(parseISO(rdv.date), "dd/MM/yyyy", { locale: fr });

        // Remplacer les placeholders dans le texte
        return txt.replace("[*date*]", formattedDate)
                  .replace("[*début*]", rdv.debut)
                  .replace(/\[\*n\*\]/g, '\n')
                  .replace("[*fin*]", rdv.fin); 
    };

    useEffect(() => {   
        if(rdvcanceltxt && rdvcanceltxt.textAnnulRdv) { 
            const formattedTxtCancel = formatTxtCancel(rdvcanceltxt.textAnnulRdv, rdv);
            setTxtCancel(formattedTxtCancel);
        }
    }, [rdvcanceltxt, rdv]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        const sanitizedFormState = {};
        sanitizedFormState["id"] = rdv.id;
        sanitizedFormState["type"] ="cancel";
         sanitizedFormState["messageEmail"] = sanitizeInput(txtCancel);
         setSubmitData({data: sanitizedFormState});
        setSendSubmitRdv(true);
    }

    const sanitizeInput = (input) => {
        const sanitizedInput = DOMPurify.sanitize(input);
        return sanitizedInput;
    };

    useEffect(() => {
        if(rdvcancelSubmit) { 
            reload(rdv.id, 'cancel');
            openAlert( "L'événement a été annulé avec succès", true);
            closeModal();
        }
    }
    , [rdvcancelSubmit]); 

    return ( 
        <div className='rdvcancel'>
            <h3>Annuler l'événement suivant:</h3>
            <RdvDisp bRdv={rdv} type={"small"} onLoaded={() => null} />
            <form action="">

            {rdv.etape != "pro" && (
                   !loading ?  
                    <div className="form-group">
                        <label htmlFor="cancelReason"> {userRole === "pro" ?"Message pour le client (modifiez au besoin):" : " Message pour le professionnel:" }</label>
                        <textarea onChange={(e)=>setTxtCancel(e.target.value)} className="inputs" id="cancelReason" value={txtCancel} rows="3"></textarea>
                    </div> : <Loader></Loader> )
                }
                
                <div className="btns">
                    <button className='btn-pro scnd' onClick={closeModal} type='button'><p>Retour</p></button>
                    <button type="submit" onClick={handleSubmit} className="btn-pro danger"  > {rdvcancelLogin ? <Loader></Loader> : <p>Annuler l'événement</p>}</button>
                </div> 

            </form> 
        </div> 
    );
};

export default RdvCancel;