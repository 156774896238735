import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';
import AddressAuto from './AddressAuto';
import DOMPurify from 'dompurify';
import validator from 'validator';
import useFetch from '../hooks/useFetch';
import { useDropzone } from 'react-dropzone';
import StepsIndicator from './StepsIndicator';
import Loader from './Loader';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import Switch from './Switch';
import { set } from 'date-fns';
import Pricing from './Pricing';

const RegisterPro = () => {
    const { data: dataComp, loading: compLoading } = useFetch(`/competences?populate=img`, 'GET', null, false, true);
    const [competences, setCompetences] = useState([]);
    const [selectedCompetences, setSelectedCompetences] = useState([]);
    const [tacceptedFiles, setTacceptedFiles] = useState([]);
    const stesps = ["Notre offre", "Votre entreprise", "Confirmation"];
    const [submitData, setSubmitData] = useState(null);
    const [currentStep, setCurrentStep] = useState(0)
    const [sendPage, setSendPage] = useState(false);

    const handleFileDrop = (nacceptedFiles) => {
        const filteredFiles = nacceptedFiles.filter(file => {
            const fileType = file.type;
            return (
                fileType === 'image/jpeg' ||
                fileType === 'image/png' ||
                fileType === 'application/pdf'
            );
        }).map(file => ({
            ...file,
            id: `${Date.now()}-${file.name}`,
            data: file
        }));

        setTacceptedFiles(prevFiles => [...prevFiles, ...filteredFiles]);
    };

    const handleFileDelete = (fileToDelete, event) => {
        event.stopPropagation();
        // Supposons que chaque fichier a une propriété unique, par exemple 'id'
        const updatedFiles = tacceptedFiles.filter(file => file.id !== fileToDelete.id);
        setTacceptedFiles(updatedFiles);
    };


    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        onDrop: handleFileDrop
    });

    useEffect(() => {
        if (dataComp) {
            setCompetences(dataComp);
        }
    }, [dataComp]);

    const getImageSrc = (url) => {
        return process.env.REACT_APP_UPLOAD_URL + url;

    };

    const { data: pageinfo, loading, error } = useFetch('/pro-infos', 'POST', submitData, false, sendPage);

    const [proFormstate, setProFormstate] = useState({
        nomentreprise: '',
        siret: '',
        competences: '',
        telephone: '',
        rue: '',
        ville: [],
        codepostal: '',
        mail: '',
        accepteConditions: false,
        // Assurez-vous d'ajouter des valeurs initiales pour tous les champs, y compris les nouveaux champs que vous pourriez ajouter plus tard
        prenom: '', // Ajouté comme exemple
        nom: '', // Ajouté comme exemple
        password: '', // Assurez-vous que ce champ est aussi initialisé
        confirmPassword: '', // Assurez-vous que ce champ est aussi initialisé
    });
 
    const { closeModal } = useModal();
    const [errors, setErrors] = useState({});
    
    const validateFields = () => {
        let newErrors = {};
        let isValid = true;


        if (!proFormstate.siret) {
            newErrors.siret = "Le numéro SIRET est requis.";
            isValid = false;
        } else if (!/^\d{14}$/.test(proFormstate.siret)) {
            newErrors.siret = "Le numéro SIRET doit contenir 14 chiffres.";
            isValid = false;
        }

        if (!proFormstate.ville) {
            newErrors.mail = "Veillez indiquer votre ville.";
            isValid = false;
        }

        // Nom et Prénom sanitation (simple exemple, peut être étendu/adapté)
        ['rue', 'codepostal', 'nomentreprise', 'nom', 'prenom'].forEach(field => {
            if (!proFormstate[field]) {
                newErrors[field] = `L'adresse est requise.`;
                if (field === 'rue') {
                } else if (field === 'nomentreprise') {
                    newErrors[field] = `Le nom de l'entreprise est requis.`;
                } else {
                    newErrors[field] = `Le ${field} est requis.`;
                }
                isValid = false;
            } else {
                proFormstate[field] = validator.escape(proFormstate[field]);
            }
        });

        if (selectedCompetences.length === 0) {
            newErrors.competence = "Veuillez sélectionner au moins une compétence.";
            isValid = false;
        }

        if (tacceptedFiles.length === 0) {
            newErrors.documents = "Veuillez télécharger au moins un document.";
            isValid = false;
        }

        // Password validation
        if (!proFormstate.password) {
            newErrors.password = "Le mot de passe est requis.";
            isValid = false;
        } else {
            let passwordError = "";
            if (proFormstate.password.length < 8) {
                passwordError += "Le mot de passe doit contenir au moins 8 caractères. ";
            }
            if (!/\d/.test(proFormstate.password)) {
                passwordError += "Le mot de passe doit contenir au moins un chiffre. ";
            }
            if (!/[!@#$%^&*]/.test(proFormstate.password)) {
                passwordError += "Le mot de passe doit contenir au moins un caractère spécial (!@#$%^&*). ";
            }
            if (proFormstate.password !== proFormstate.confirmPassword) {
                newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
                isValid = false;
            }
            if (passwordError) {
                newErrors.password = passwordError;
                isValid = false;
            }
        }



        // Téléphone validation
        if (!proFormstate.telephone) {
            newErrors.telephone = "Le numéro de téléphone est requis.";
            isValid = false;
        } else if (!validator.isMobilePhone(proFormstate.telephone, 'any', { strictMode: false })) {
            newErrors.telephone = "Le numéro de téléphone est invalide.";
            isValid = false;
        }

        // Conditions validation
        if (!proFormstate.accepteConditions) {
            newErrors.accepteConditions = "Vous devez accepter les conditions générales.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    const sanitizeInput = (input) => {
        const sanitizedInput = DOMPurify.sanitize(input);
        return sanitizedInput;
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const sanitizedValue = sanitizeInput(value);
        setProFormstate({
            ...proFormstate,
            [name]: type === 'checkbox' ? checked : sanitizedValue
        });
    };

    const handleCompetenceClick = (competence) => {
        let updatedSelectedCompetences;
        if (selectedCompetences.includes(competence)) {
            // Retirer la compétence de la sélection
            updatedSelectedCompetences = selectedCompetences.filter(comp => comp !== competence);
        } else {
            // Ajouter la compétence à la sélection
            updatedSelectedCompetences = [...selectedCompetences, competence];
        }

        // Mettre à jour l'état des compétences sélectionnées
        setSelectedCompetences(updatedSelectedCompetences);

        // Mettre à jour proFormstate avec les compétences sélectionnées
        // Assumons que vous stockez les ID des compétences dans un tableau
        const competencesIds = updatedSelectedCompetences.map(comp => comp.id);

        setProFormstate(prevState => ({
            ...prevState,
            competences: competencesIds // ou tout autre format adapté à votre backend
        }));
    };


    const handlesuivant = () => {
        if (currentStep === 1) {
            if (validateFields()) {
                handleSubmit();
                //setCurrentStep(currentStep + 1);
            }
        }
        else
        setCurrentStep(currentStep + 1);
    }

    useEffect(() => {
        if (submitData || error) {
            setSendPage(true);
        }
    }, [submitData]);

    useEffect(() => {
        if (pageinfo || error) {
            setCurrentStep(currentStep + 1);
            setSendPage(false);
        }
    }, [pageinfo, error]);

    const handleSubmit = () => {
        if (validateFields()) {
            const formData = new FormData();
            const sanitizedFormState = {};

            for (const key in proFormstate) {
                if (key === 'competences') {
                    sanitizedFormState['competences'] = proFormstate[key];
                }
                else if (key === 'ville') {
                    sanitizedFormState['ville'] = proFormstate[key];
                }
                else sanitizedFormState[key] = sanitizeInput(proFormstate[key]);
            }

            tacceptedFiles.forEach((file, index) => {

                formData.append(`files.preuves`, file.data, file.name);// "file1", "file2", etc.
            });

            formData.append('data', JSON.stringify(sanitizedFormState));
            setSubmitData(formData);

        }
    };

    const retour = () => {
        if (currentStep === 0 || currentStep === 2) {
            closeModal();
        } 
        else setCurrentStep(currentStep - 1);
    }


    return (
        <div className='registerPro'>
            <div className='formheader'>
                <button class="btn-base " onClick={retour}><svg
                    viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.36555 0.0634767L6.31252 1.11651L6.31239 1.11637L3.47689 3.95187L3.47685 3.95183L0.276298 7.15238H0.218262L0.24728 7.1814L0.218295 7.21039H0.276266L3.07575 10.0099L3.07572 10.0099L4.5296 11.4638H4.52993L5.91158 12.8454L5.91154 12.8455L7.36541 14.2993L10.9745 14.2993L8.11701 11.4418L8.11705 11.4418L6.66317 9.98791H6.66284L5.28122 8.60629L5.28125 8.60626L3.85639 7.1814L4.7083 6.32949L4.70834 6.32953L7.71597 3.3219L7.7161 3.32203L10.9747 0.0634766L7.36555 0.0634767Z" fill="#006963"></path></svg>
                    <p>Retour</p>
                </button>
                <StepsIndicator steps={stesps} currentStep={currentStep}></StepsIndicator>

            </div>
            {currentStep === 0 &&
                <div className="pricing">
                    <Pricing></Pricing>
                </div>
                }
            {currentStep === 1 &&
                <form action="">
                    <h3>Inscrire votre entreprise</h3>
                    <div className="form-group">



                        <label htmlFor="">Sélectionnez au moins une compétence</label>
                        <div className="profe">
                            {competences?.map((competence, index) => (
                                <div key={index} className={`item ${selectedCompetences.includes(competence) ? 'active' : ''}`} onClick={() => handleCompetenceClick(competence)}>
                                    {competence?.attributes?.img?.data?.attributes &&
                                        <img src={getImageSrc(competence?.attributes?.img?.data?.attributes.url)} alt={competence.attributes.nom} />
                                    }
                                    <p>{competence.attributes.nom}</p>
                                </div>
                            ))}
                        </div>
                        {errors.competence && <p className="error">{errors.competence}</p>}

                    </div>
                    <div className="form-group">
                        <label htmlFor="">Documents qui confiment votre expertise : diplôme, ou autres...  </label>

                        <div className='drop' {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ul>
                                {tacceptedFiles.map((file, index) => (
                                    <li className='' key={index}> 
                                        {file.path}
                                        <button className='btn-base danger' onClick={(event) => handleFileDelete(file, event)} type='button'><p>Supprimer</p></button>
                                    </li>
                                ))}
                            </ul>
                            <h4>Faites glisser/déposez des fichiers ici, ou <a onClick={(e) => e.preventDefault}>cliquez pour sélectionner</a> des fichiers. (image ou pdf) </h4>



                        </div>
                        {errors.documents && <p className="error">{errors.documents}</p>}

                    </div>
                    <div className="form-group line">
                        <label htmlFor="prenom  line">Prénom</label>
                        <input
                            type="text"
                            className="inputs"
                            name="prenom"
                            value={proFormstate.prenom}
                            onChange={handleInputChange}
                            placeholder="Prénom"
                        />
                        {errors.prenom && <p className="error">{errors.prenom}</p>}
                    </div>
                    <div className="form-group line">
                        <label htmlFor="nom">Nom</label>
                        <input
                            type="text"
                            className="inputs"
                            name="nom"
                            value={proFormstate.nom}
                            onChange={handleInputChange}
                            placeholder="Nom"
                        />
                        {errors.nom && <p className="error">{errors.nom}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">email</label>
                        <input
                            type="mail"
                            className="inputs"
                            name="mail"
                            value={proFormstate.mail}
                            onChange={handleInputChange}
                            placeholder="email"
                        />
                        {errors.mail && <p className="error">{errors.mail}</p>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="nomentreprise">Nom de l'entreprise</label>
                        <input
                            type="text"
                            className="inputs"
                            name="nomentreprise"
                            value={proFormstate.nomentreprise}
                            onChange={handleInputChange}
                            placeholder="Nom de l'entreprise"
                        />
                        {errors.nomentreprise && <p className="error">{errors.nomentreprise}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="siret">SIRET</label>
                        <input
                            type="text"
                            className="inputs"
                            name="siret"
                            value={proFormstate.siret}
                            onChange={handleInputChange}
                            placeholder="SIRET"
                        />
                        {errors.siret && <p className="error">{errors.siret}</p>}

                    </div>
                    <div className="form-group">
                        <label htmlFor="telephone">Téléphone</label>
                        <input
                            type="tel"
                            className="inputs"
                            name="telephone"
                            value={proFormstate.telephone}
                            onChange={handleInputChange}
                            placeholder="Téléphone"
                        />
                        {errors.telephone && <p className="error">{errors.telephone}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="rue">Adresse</label>
                        <input
                            type="text"
                            className="inputs"
                            name="rue"
                            value={proFormstate.rue}
                            onChange={handleInputChange}
                            placeholder="Rue"
                        />
                        {errors.rue && <p className="error">{errors.rue}</p>}
                    </div> 
                    <AddressAuto  err={errors.ville ? errors.ville : false}  type={"cities"} onAddressSelect={(ville) => setProFormstate({ ...proFormstate, ville: ville })} newSelect={proFormstate.ville}></AddressAuto>
                    <div className="form-group line">
                        <label htmlFor="codepostal">Code postal</label>
                        <input
                            type="tel"
                            className="inputs"
                            name="codepostal"
                            value={proFormstate.codepostal}
                            onChange={handleInputChange}
                            placeholder="Code postal"
                        />
                        {errors.codepostal && <p className="error">Le code postal est requis</p>}
                    </div>
                    <div className="form-group line">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            className="inputs"
                            name="password"
                            value={proFormstate.password}
                            onChange={handleInputChange}
                            placeholder="Mot de passe"
                        />
                        <p className='desc'> ( Au moins 8 caractères, un chiffre et un caractère special )
                        </p>
                        {errors.password && <p className="error">{errors.password}</p>}
                    </div>
                    <div className="form-group line">
                        <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                        <input
                            type="password"
                            className="inputs"
                            name="confirmPassword"
                            value={proFormstate.confirmPassword}
                            onChange={handleInputChange}
                            placeholder="Confirmer le mot de passe"
                        />
                        {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                    </div>


                    <div className="form-group check">
                        <input
                            type="checkbox"
                            name="accepteConditions"
                            checked={proFormstate.accepteConditions}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="accepteConditions">J'accepte les <a href="/cgv" target="_blank" >conditions générales Bykahomes</a>.</label>

                    </div>


                    {errors.accepteConditions && <p className="error">{errors.accepteConditions}</p>}
                </form>
            }
            {currentStep === 2 &&
                <div className='confirmation'>
                    {pageinfo ?
                        <div className='result'>
                            <div className='illu'>
                                <svg viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27.5" cy="28" r="27.5" fill="url(#paint0_linear_200_1731)" />
                                    <path d="M21.791 34.7176L13.9947 27.4885L11.3398 29.9329L21.791 39.6236L44.2264 18.8206L41.5903 16.3762L21.791 34.7176Z" fill="white" />
                                    <defs>
                                        <linearGradient id="paint0_linear_200_1731" x1="27.5" y1="0.5" x2="27.5" y2="55.5" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#25AAA5" />
                                            <stop offset="1" stop-color="#006963" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className="txt">
                                    <h4>Félicitation!</h4>
                                    <p className='strong'>Votre demande à été transmise avec succés.</p>
                                </div>
                            </div>
                            <p> Un e-mail de confirmation vous a été envoyé. <br /> Vous serez informé dès que nous aurons examiné votre demande dans les plus brefs délais. <br /> <strong> N'oubliez pas de vérifier dans vos spams *.</strong></p>
                            <span className='horizon'></span>
                            <p> Ne perdez pas vos informations de connexion pour la suite.</p> 
                        </div>
                        :
                        <div className='illu alert'>
                            <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9.5" cy="9.5" r="9.5" fill="#FF5E00" />
                                <path d="M9.88412 4.22228L9.88412 11.899L8.70462 11.899L8.70462 4.22228L9.88412 4.22228ZM9.28438 13.1785C9.51428 13.1785 9.71253 13.2568 9.87913 13.4134C10.0424 13.57 10.124 13.7583 10.124 13.9782C10.124 14.1981 10.0424 14.3863 9.87913 14.5429C9.71253 14.6995 9.51428 14.7778 9.28438 14.7778C9.05447 14.7778 8.85789 14.6995 8.69463 14.5429C8.52803 14.3863 8.44473 14.1981 8.44473 13.9782C8.44473 13.7583 8.52803 13.57 8.69463 13.4134C8.85789 13.2568 9.05447 13.1785 9.28438 13.1785Z" fill="white" />
                            </svg>
                            <div className="txt">


                                <h4>Une erreur s'est produite.</h4>
                                <p className='strong'> Veuillez réessayer plus tard.</p>
                            </div>


                        </div>
                    }

                </div>
            }
            <div className="action">
                {currentStep == 2 ?
                    <button class="btn-base " onClick={retour}><svg
                        viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.36555 0.0634767L6.31252 1.11651L6.31239 1.11637L3.47689 3.95187L3.47685 3.95183L0.276298 7.15238H0.218262L0.24728 7.1814L0.218295 7.21039H0.276266L3.07575 10.0099L3.07572 10.0099L4.5296 11.4638H4.52993L5.91158 12.8454L5.91154 12.8455L7.36541 14.2993L10.9745 14.2993L8.11701 11.4418L8.11705 11.4418L6.66317 9.98791H6.66284L5.28122 8.60629L5.28125 8.60626L3.85639 7.1814L4.7083 6.32949L4.70834 6.32953L7.71597 3.3219L7.7161 3.32203L10.9747 0.0634766L7.36555 0.0634767Z" fill="#006963"></path></svg>
                        <p>Retour</p>
                    </button>
                    :
                     <button class="btn-front spe" onClick={handlesuivant}><span></span>{currentStep == 0 ?<p>Demande d'inscription</p>:<p> Envoyer ma demande</p>}<div class="icon"> {loading ? <Loader type="button" ></Loader> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.8795 15.2253L4.96384 14.1724L4.9639 14.1724L7.88392 11.3369L7.88395 11.3369L11.1798 8.13634L11.2394 8.13634L11.2096 8.10744L11.2396 8.07835L11.1796 8.07835L8.29688 5.27897L8.29701 5.27885L6.79983 3.82497L6.79933 3.82497L5.37661 2.4434L5.37675 2.44328L3.87956 0.989401L0.162947 0.9894L3.10546 3.8468L3.10533 3.84693L4.60252 5.30081L4.60301 5.30081L6.02569 6.68233L6.02556 6.68246L7.49299 8.10744L6.61576 8.9593L6.61573 8.95927L3.51856 11.9669L3.5185 11.9668L0.162881 15.2253L3.8795 15.2253Z" fill="#006963"></path></svg>}</div></button>
                }
            </div>
        </div>
    )
}


export default RegisterPro;