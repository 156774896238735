import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const RoutePro = ({ children }) => {
    const { isAuthenticated, userRole } = useAuth();

    
    // Si l'utilisateur est authentifié et a le rôle 'pro', afficher les enfants
    return isAuthenticated() && userRole && userRole === 'pro' ? children : children;
};

export default RoutePro;