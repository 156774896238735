
import React, { useState, useEffect } from 'react';
import SimpleSwitch from './SimpleSwitch';
import { useAuth } from '../contexts/AuthContext';


const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    const [preferences, setPreferences] = useState({
        fonctionnel: true,
        marketing: false,
        statistique: false
    });


    const { isAuthenticated } = useAuth();

    

    useEffect(() => {
        const savedConsent = localStorage.getItem('cookieConsent');
        if (savedConsent) {
            setPreferences(JSON.parse(savedConsent));
        } else {
            setIsVisible(true);
        } 
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', JSON.stringify(preferences));
        setIsVisible(false);
    };

    const handleCheckboxChange = (name, e) => {
        setPreferences({
            ...preferences,
            [name]: preferences[name] ? false : true
        });
    };

    const handleAcceptAll = () => {
        setPreferences({
            fonctionnel: true,
            marketing: true,
            statistique: true
        });
        // après une seconde, ferme 
        setTimeout(() => {
            handleAccept()
        }, 250); 
    };
    
 

    return (
        isAuthenticated() ? null :
            <div className={isVisible?  'cookieConsent' : "cookieConsent small"}  onClick={ () => isVisible ? null : setIsVisible(true)}  >
                <div className="head" >
                    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.95 9.98988C18.16 9.95988 16.25 8.03988 17.27 5.76988C14.29 6.76988 11.5 4.17988 12.08 1.20988C4.95 -0.290116 0 5.57988 0 10.9999C0 16.5199 4.48 20.9999 10 20.9999C15.89 20.9999 20.54 15.9199 19.95 9.98988ZM6.5 13.9999C5.67 13.9999 5 13.3299 5 12.4999C5 11.6699 5.67 10.9999 6.5 10.9999C7.33 10.9999 8 11.6699 8 12.4999C8 13.3299 7.33 13.9999 6.5 13.9999ZM8.5 8.99988C7.67 8.99988 7 8.32988 7 7.49988C7 6.66988 7.67 5.99988 8.5 5.99988C9.33 5.99988 10 6.66988 10 7.49988C10 8.32988 9.33 8.99988 8.5 8.99988ZM13 14.9999C12.45 14.9999 12 14.5499 12 13.9999C12 13.4499 12.45 12.9999 13 12.9999C13.55 12.9999 14 13.4499 14 13.9999C14 14.5499 13.55 14.9999 13 14.9999Z" fill="black" />
                    </svg> 
                    <h4>Gérer le consentement aux cookies </h4>
                </div>
                
                <p>Notre site utilise des cookies pour améliorer votre expérience et vous fournir des services adaptés à vos intérêts. En choisissant vos préférences, vous nous aidez à optimiser les fonctionnalités et le contenu personnalisé selon vos interactions et besoins.</p>
                <div>
                    <div className='form-group'>
                        <SimpleSwitch checked={preferences.fonctionnel}
                            onChange={(e) => null} />
                        <label htmlFor="fonctionnel">Fonctionnel (nécessaires)</label>
                        <p>Ces cookies sont nécessaires pour le fonctionnement du site et ne peuvent pas être désactivés.</p>
                    </div>
                    <div className='form-group' >
                        <SimpleSwitch checked={preferences.marketing}
                            onChange={(e) => handleCheckboxChange("marketing", e)} />
                        <label htmlFor="marketing">Marketing</label>
                        <p>Ces cookies sont utilisés pour suivre les visiteurs à travers les sites web. L'objectif est d'afficher des publicités pertinentes et engageantes pour l'utilisateur individuel.</p>
                    </div>
                    <div className='form-group' >
                        <SimpleSwitch checked={preferences.statistique}
                            onChange={(e) => handleCheckboxChange("statistique", e)} />

                        <label htmlFor="statistique">Statistique</label>
                        <p>Ces cookies aident à comprendre comment les visiteurs interagissent avec le site web, en collectant et en rapportant des informations de manière anonyme.</p>
                    </div>
                </div>

                <div className="btns">
                    <button className='btn-pro' onClick={handleAcceptAll}><p>Accepter tout</p></button>
                    <button className='btn-pro scnd' onClick={handleAccept}> <p>Enregistrer les préférences</p></button>
                    <button className='btn-pro scnd' onClick={() => setIsVisible(false)}><p>Refuser</p></button>
                </div>

            </div>
        

    );
};

export default CookieConsent;