import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rdvForModal, setRdvForModal] = useState(null);
    const [modalContent, setModalContent] = useState('login');
    const [modalnContent, setModalnContent] = useState(false);
    const [nbNotifs, setNbNotifs] = useState(null);
    const [isNotifOpen, setIsNotifOpen] = useState(false);
    const [notifContent, setNotifContent] = useState('notifs');
    const [data, setData] = useState(null);
    const [nEventData, setNEventData] = useState(null);
    const [nreloadFeed, setNreloadFeed] = useState(null);
    const [selectedRdv, setSelectedRdv] = useState(null);
    const { isAuthenticated, logout, userRole, userInfo } = useAuth();

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const openModalWrdv = (content, rdv) => {
        setModalContent(content);
        setIsModalOpen(true);
        setRdvForModal(rdv);
    };

    const openModalNrdv= ( jour, heureComplete, m ) => {
        setModalContent("nEvent");
        setIsModalOpen(true);
        setNEventData({jour, heureComplete, m});
    };


    useEffect(() => { 
        if(userInfo && userInfo.nb_notif > 0){
            setNbNotifs(userInfo.nb_notif);
        }
    }
    , [userInfo]);

    const openModalData = (content, data) => { 
        setModalnContent(content);
        setIsModalOpen(true);
    }

    const openNotif = (content) => {
        setNotifContent(content);
        setIsNotifOpen(true);
        setNbNotifs(null);
    };
    

    const closeNotif = () => setIsNotifOpen(false);

    const closeModal = () => {
        setIsModalOpen(false);
        setRdvForModal(null);
    
        let urlActuelle = window.location.href;
        // Supprimer "/inscription-pro" de l'URL
        urlActuelle = urlActuelle.replace('inscriptionpro', '');
    
        // Supprimer "/connexion" de l'URL
        urlActuelle = urlActuelle.replace('connexion', '');
        
        // Supprimer "/inscription" de l'URL
        urlActuelle = urlActuelle.replace('inscription', '');
        
        
        // Mettre à jour l'URL sans recharger la page
        window.history.replaceState(null, null, urlActuelle);
    };

    const reload = (id, action) => {setModalnContent({id, action})} ;
    
    const reloadFeed = (reload) => {setNreloadFeed(reload)};

    return (
        <ModalContext.Provider value={{ isModalOpen, modalContent, rdvForModal,modalnContent, notifContent, isNotifOpen, nbNotifs, data,nEventData, nreloadFeed ,reloadFeed, selectedRdv, setNbNotifs, openModal, closeModal, openModalWrdv, reload, openNotif, closeNotif, openModalData,openModalNrdv }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);