import React from 'react';

const SimpleSwitch = ( {checked, onChange}) => { 
    return (
        <div  className={checked ? 'simpleswitch active' :'simpleswitch'} onClick={() => onChange(true) }> 
            <span></span>
        </div>
    );
};

export default SimpleSwitch;