import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../img/logo.png';
import conditionsGeneralesDeVente from '../cgv.pdf';

const FooterPro = () => {
    const currentYear = new Date().getFullYear();


    return (
        <footer className='footer pro mobilenotvisible'>
            <div className="contentfooter">
                <div className="right">

                    <div className='legaux'>
                        
                    <a href="/cgv" target="_blank" rel="noopener noreferrer">Mentions légales et conditions générales d’utilisation </a>
                     <a href="/cgu" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a>
                              

                    </div>
                </div>

            </div>
        </footer>
    );
};

export default FooterPro;