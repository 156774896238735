import React, { useEffect, useState } from 'react';
import Hearderpro from '../../components/Hearderpro';
import Leftmenu from '../../components/LeftMenu';
import RdvList from '../../components/RdvList';
import NotifPage from '../../components/NotifPage';
import SousMenuPro from '../../components/Sousmenupro';
import Modal from "../../components/Modal";
import FooterPro from '../../components/FooterPro';
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../../components/Loader';
import ListFiltre from '../../components/ListFiltre';

const Accueilpro = () => {

    const { proInfo, userInfo } = useAuth();
    const pro = null;
    const [rdvFilter, setRdvFilter] = useState('all'); // 'all' ou 'new'
    const filters = [
        { name: 'all', label: 'Tout', active: rdvFilter === 'all' },
        { name: 'new', label: 'Nouveaux', active: rdvFilter === 'new' },
        // Ajoutez plus de filtres ici selon vos besoins
    ];
;    return (
        <div>
            <div className='page'>
                <NotifPage />
                <Leftmenu />
                {proInfo && proInfo.statut ?
                <div className='content accueilpro '>
                    <Hearderpro titre="Accueil" />
                        <div className={proInfo && proInfo?.statut === "non-valide" ? 'cont non-active' : 'cont'} >
                            <h3>Bonjour <strong>{userInfo?.prenom} ,</strong> </h3>
                            <div className="notifsac">
                                {proInfo && proInfo.rdv ? ( !proInfo.rdv === "0" &&
                                  <div className="itemnotif alert">
                                  <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="9.5" cy="9.5" r="9.5" fill="#FF5E00" />
                                      <path d="M9.88412 4.22228L9.88412 11.899L8.70462 11.899L8.70462 4.22228L9.88412 4.22228ZM9.28438 13.1785C9.51428 13.1785 9.71253 13.2568 9.87913 13.4134C10.0424 13.57 10.124 13.7583 10.124 13.9782C10.124 14.1981 10.0424 14.3863 9.87913 14.5429C9.71253 14.6995 9.51428 14.7778 9.28438 14.7778C9.05447 14.7778 8.85789 14.6995 8.69463 14.5429C8.52803 14.3863 8.44473 14.1981 8.44473 13.9782C8.44473 13.7583 8.52803 13.57 8.69463 13.4134C8.85789 13.2568 9.05447 13.1785 9.28438 13.1785Z" fill="white" />
                                  </svg>
                                  <p>Vous avez {proInfo.rdv } nouvelles réservations à confirmer</p>
                              </div>
                                ) : null}
                                 {proInfo && !proInfo.mediateurNom &&
                                <div className="itemnotif alert">
                                    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9.5" cy="9.5" r="9.5" fill="#FF5E00" />
                                        <path d="M9.88412 4.22228L9.88412 11.899L8.70462 11.899L8.70462 4.22228L9.88412 4.22228ZM9.28438 13.1785C9.51428 13.1785 9.71253 13.2568 9.87913 13.4134C10.0424 13.57 10.124 13.7583 10.124 13.9782C10.124 14.1981 10.0424 14.3863 9.87913 14.5429C9.71253 14.6995 9.51428 14.7778 9.28438 14.7778C9.05447 14.7778 8.85789 14.6995 8.69463 14.5429C8.52803 14.3863 8.44473 14.1981 8.44473 13.9782C8.44473 13.7583 8.52803 13.57 8.69463 13.4134C8.85789 13.2568 9.05447 13.1785 9.28438 13.1785Z" fill="white" />
                                    </svg>
                                    <p>vous n 'avez pas choisi votre médiateur</p>
                                </div> } 
                            </div>

                            <div className='mobilevisible'>
                            <ListFiltre filters={filters} titre="Filtrer par:" direct="horizon" setFilter={setRdvFilter} />

                            </div>
                            <div className='disp'>
                                <div className={rdvFilter == "new" ? 'list act':  'list ' }>
                                    <h3>À confirmer</h3>
                                    <RdvList typeList={"nouveaux"}></RdvList>
                                </div>
                                <div className={rdvFilter == "all" ? 'list act':  'list ' }>
                                    <h3>Aujourd'hui</h3>
                                    <RdvList typeList={""}></RdvList>
                                </div>
                                <div className='list dnone'>
                                    <div className='end'>
                                        <h3>Vos statistiques</h3>
                                        <div className='item'>
                                            <p>Profil vu: <span>360 fois</span> </p>
                                        </div >
                                        <div className='item'>
                                            <p>Membre depuis: <span>Nov. 2022</span> </p>
                                        </div>
                                        <div className='item'>
                                            <p>Total généré: <span>360 €</span> </p>
                                        </div>
                                    </div>
                                    <div className='end'>
                                        <h3>Vos Avis</h3>
                                        <div className="profile-evaluations">
                                            <div className="left">
                                                <p>Moyenne:</p>
                                                <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#C5FFFE" />
                                                            <stop offset="1" stop-color="#25AAA5" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                                <p>{pro?.evaluations.global}/5</p>
                                            </div>
                                            <div className="right ">
                                                <div className="rating">
                                                    <p>Disponibilité:</p>
                                                    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#C5FFFE" />
                                                                <stop offset="1" stop-color="#25AAA5" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p>{pro?.evaluations.disponibilite}/5</p>
                                                </div>
                                                <div className="rating">
                                                    <p>Ponctualité:</p>
                                                    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#C5FFFE" />
                                                                <stop offset="1" stop-color="#25AAA5" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p>{pro?.evaluations.ponctualite}/5</p></div>

                                                <div className="rating">
                                                    <p>Qualité du service:</p>
                                                    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#C5FFFE" />
                                                                <stop offset="1" stop-color="#25AAA5" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p>{pro?.evaluations.qualiteService}/5</p></div>

                                                <div className="rating">
                                                    <p>Relationnel:</p>
                                                    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#C5FFFE" />
                                                                <stop offset="1" stop-color="#25AAA5" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p>{pro?.evaluations.relationnel}/5</p>
                                                </div>

                                                <div className="rating">
                                                    <p>Expérience du client:</p>
                                                    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#C5FFFE" />
                                                                <stop offset="1" stop-color="#25AAA5" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p>{pro?.evaluations.experienceClient}/5</p>
                                                </div>

                                                <div className="rating">
                                                    <p>Prix:</p>
                                                    <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.6478 0.557433C14.0146 -0.185812 15.0745 -0.18581 15.4413 0.557435L19.1611 8.09462C19.3068 8.38976 19.5884 8.59433 19.9141 8.64166L28.2318 9.8503C29.0521 9.96949 29.3796 10.9775 28.786 11.556L22.7673 17.4229C22.5316 17.6526 22.424 17.9836 22.4797 18.308L23.9005 26.5922C24.0406 27.4091 23.1832 28.032 22.4495 27.6463L15.0099 23.7351C14.7186 23.5819 14.3705 23.5819 14.0792 23.7351L6.63958 27.6463C5.90595 28.032 5.04852 27.4091 5.18863 26.5922L6.60948 18.308C6.66511 17.9836 6.55757 17.6526 6.32188 17.4229L0.303081 11.556C-0.290436 10.9775 0.0370746 9.96949 0.857295 9.8503L9.17507 8.64166C9.50078 8.59433 9.78235 8.38976 9.92801 8.09462L13.6478 0.557433Z" fill="url(#paint0_linear_791_3196)" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9818 8.18313L15.262 0.645948C14.9685 0.0513512 14.1206 0.0513507 13.8272 0.645947L10.1074 8.18313C9.93256 8.5373 9.59468 8.78278 9.20383 8.83958L0.886055 10.0482C0.229878 10.1436 -0.0321302 10.9499 0.442683 11.4128L6.46148 17.2797C6.74431 17.5553 6.87336 17.9525 6.8066 18.3418L5.38575 26.626C5.27366 27.2795 5.95961 27.7779 6.54651 27.4693L13.9862 23.5581C14.3357 23.3743 14.7534 23.3743 15.103 23.5581L22.5426 27.4693C23.1295 27.7779 23.8155 27.2795 23.7034 26.626L22.2825 18.3418C22.2158 17.9525 22.3448 17.5553 22.6276 17.2797L28.6464 11.4128C29.1213 10.9499 28.8593 10.1436 28.2031 10.0482L19.8853 8.83958C19.4944 8.78278 19.1566 8.5373 18.9818 8.18313ZM15.4413 0.557435C15.0745 -0.18581 14.0146 -0.185812 13.6478 0.557433L9.92801 8.09462C9.78235 8.38976 9.50078 8.59433 9.17507 8.64166L0.857295 9.8503C0.0370746 9.96949 -0.290436 10.9775 0.303081 11.556L6.32188 17.4229C6.55757 17.6526 6.66511 17.9836 6.60948 18.308L5.18863 26.5922C5.04852 27.4091 5.90595 28.032 6.63958 27.6463L14.0792 23.7351C14.3705 23.5819 14.7186 23.5819 15.0099 23.7351L22.4495 27.6463C23.1832 28.032 24.0406 27.4091 23.9005 26.5922L22.4797 18.308C22.424 17.9836 22.5316 17.6526 22.7673 17.4229L28.786 11.556C29.3796 10.9775 29.0521 9.96949 28.2318 9.8503L19.9141 8.64166C19.5884 8.59433 19.3068 8.38976 19.1611 8.09462L15.4413 0.557435Z" fill="#25AAA5" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_791_3196" x1="14.5446" y1="-1.25952" x2="14.5446" y2="31.7405" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#C5FFFE" />
                                                                <stop offset="1" stop-color="#25AAA5" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    <p>{pro?.evaluations.prix}/5</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    
                    <FooterPro></FooterPro>
                </div>
                :
                <Loader></Loader>
                }
            </div>
        </div>
    );
};

export default Accueilpro;