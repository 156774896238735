import React, { useState, useContext, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';
import MapsApiContext from '../contexts/MapLoadContext';


const AddressAuto = ({ err, onAddressSelect, newSelect, type }) => {
    const [addressQuery, setAddressQuery] = useState(newSelect);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const isLoaded = useContext(MapsApiContext);
    const ntype = type ? type : "address";
   
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyB4cyH10yrG92SZSlOY9vNB3nKZByhvj_Q",
    //     libraries: ["places"] // Ajoutez ceci
    // })

    useEffect(() => {
        newSelect && newSelect.value && setAddressQuery(newSelect.value) && onAddressSelect(newSelect);
    }, [newSelect]);


    const handleSelect = async (value) => {
        setAddressQuery(value);
        try {
            const results = await geocodeByAddress(value);
            const latLng = await getLatLng(results[0]);
            onAddressSelect({ value, latLng }); // Vous pouvez envoyer d'autres informations si nécessaire
        } catch (error) {
            console.error('Error', error);
        }
    };


    // const handleFocus = () => {
    //     setShowSuggestions(true);
    // };

    // useEffect(() => {
    //     const fetchAddresses = async () => {
    //         const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&countrycodes=fr&q=${addressQuery}`);
    //         const data = await response.json();
    //         setSuggestions(data);
    //     };

    //     if (addressQuery.length < 3) {
    //         setSuggestions([]);
    //         return;
    //     }
    //     else {
    //         fetchAddresses();
    //     }
    // }, [addressQuery]);

    // useEffect(() => {
    //     setAddressQuery(newSelect);
    //     setShowSuggestions(false); 
    // }, [newSelect]);

    const handleBlur = async () => {
        if (addressQuery && !suggestions.find(s => s.description === addressQuery)) {
            try {
                const results = await geocodeByAddress(addressQuery);
                const latLng = await getLatLng(results[0]);
                onAddressSelect({ value: addressQuery, latLng }); 
            } catch (error) {
                console.error('Error', error);
                // Gérer l'erreur, par exemple en réinitialisant l'adresse ou en informant l'utilisateur
            }
        }
    };
 
    const searchOptions = {
        componentRestrictions: { country: ['fr'] }, 
      }

    if (ntype === "cities") {
        searchOptions.types = ['(cities)'];
    }


return isLoaded ? (
    <div className={ntype == "cities" ? "form-group line" : "form-group"}>
        <label htmlFor="adresse">{ntype == "cities" ? "Ville" : "Adresse postale"}</label>
        <PlacesAutocomplete
            value={addressQuery}
            onChange={setAddressQuery}
            onSelect={handleSelect}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="form-group">
                    <input {...getInputProps({ placeholder: ntype == "cities" ? "Ville" : "Adresse postale", className: 'inputs' , onBlur: handleBlur})} />

                    {suggestions.length < 1 ? null :
                        <div className="listad">
                            <ul>
                                {suggestions.map(suggestion => (
                                    <li {...getSuggestionItemProps(suggestion, { className: 'suggestion-item' })}>
                                        {suggestion.description}
                                    </li>))}
                            </ul>

                        </div>
                    }

                    {err && <p className="error">{err}</p>}

                </div>
            )}
        </PlacesAutocomplete>
    </div>
) : <></>
};

export default AddressAuto;