import React, { useEffect, useState } from 'react';
import { useModal } from '../contexts/ModalContext';
import useFetch from '../hooks/useFetch'; 
import { useAlert } from '../contexts/AlertContext';
import { useAuth } from '../contexts/AuthContext';


const DeleteCompte = () => {
    const { closeModal } = useModal();
    const { logout } = useAuth();
    const [sendSubmit, setSendSubmit] = useState(false);
    const [form, setForm] = useState({ password: '', details: '' });
    const [bodySend, setBodySend] = useState(null);
    const { data, loading, error } = useFetch('/demande-suppressions', 'POST', bodySend, true, sendSubmit);
    const [errors, setErrors] = useState(null); 
    const {openAlert} = useAlert();


    const handleSubmit = (e) => { 
        e.preventDefault();
        if (form.password === '') {
            setErrors('Veuillez entrer votre mot de passe');
            return;
        }
        // Création de l'objet avec une clé 'data' contenant le formulaire
        const requestBody = { data: form };
        setSendSubmit(true); // Prépare l'envoi de la requête
        setBodySend(requestBody); // Met à jour l'état avec l'objet structuré correctement
    }

    useEffect(() => {
        if (data, error) { 
            if (data) {
                openAlert('Compte supprimé avec succés', true );
            }
            logout();
            closeModal();
        }
    }, [data, error]);

    useEffect(() => {
        if(bodySend){
            setSendSubmit(true);
        }
    }, [bodySend]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    }

    return (
        <div>
            <form action="">
                <h3>Suppression de votre compte</h3>
                <p className=''>
                    Pour des raisons de sécurité, vous devez confirmer <br /> votre mot de passe pour supprimer votre compte. <br /> <br />
                </p>
                <div className="form-group">
                    <label htmlFor="password" >Mot de passe</label>
                    <input className='inputs' 
                        value={form.password}
                        onChange={handleInputChange}  type="password" name="password" placeholder='mot de passe' id="password" />
                </div>
                <div className="form-group">
                    <label htmlFor="raison">Raison de la suppression (facultatif) </label>
                <textarea name="details" 
                        value={form.details}
                        onChange={handleInputChange}  id="raison" className='inputs'   placeholder='Raison de la suppression'></textarea>
                {errors && <p className="error">{errors}</p>}

                </div>
                <p className='left'>
                    Vote compte sera supprimé dans les minutes suivant votre demande <br />
                    Après la suppression, vous ne pourrez pas récupérer votre compte, <br /> vos données et vos informations. <br />
                </p>
 
                <div className="btns">
                    <button className='btn-pro scnd ' onClick={closeModal} type="button"> <p>Annuler</p></button>
                    <button className='btn-pro danger' type="button" onClick={handleSubmit}> <p>Confirmer</p></button>
              
                </div>
            </form>
        </div>
    );
};

export default DeleteCompte;