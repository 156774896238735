import React, { useEffect, useMemo, useState } from 'react';
import Calendrier from '../../components/Calendrier';
import Datepicker from '../../components/Datepicker';
import RdvDisp from '../../components/RdvDisp';
import Leftmenu from '../../components/LeftMenu';
import Hearderpro from '../../components/Hearderpro';
import HorairesForm from '../../components/HorairesForm';
import SousMenuPro from '../../components/Sousmenupro';
import ListFiltre from '../../components/ListFiltre';
import Modal from '../../components/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../../components/Loader';
import NotifPage from '../../components/NotifPage';
import { useModal } from '../../contexts/ModalContext';
import { set } from 'date-fns';
import { el } from 'date-fns/locale';
import AvailableCal from '../../components/AvailableCal';
import ListCal from '../../components/ListCal';

const Agenda = (dataPage) => {
    const { userId, proInfo } = useAuth();
    const [sendSubmit, setSendSubmit] = useState(null);
    const [idDispo, setIdDispo] = useState(null);
    const [sendDate, setSendDate] = useState(false);
    const [dispos, setDispo] = useState(null);
    const [rdvs, setRdvs] = useState([]);
    const [valid, setValid] = useState(null);
    const [newActiveId, setNewActiveId] = useState(null);
    const [horaires, setHoraires] = useState(null);

    const { nreloadFeed, reloadFeed, openModalWrdv,modalnContent } = useModal();


    const { data, loading, error } = useFetch(`/prestataire-pages`, 'GET', null, true, sendSubmit);

    useEffect(() => {
        setDispo([]);
        setNewdatadispo(null);
        if (data && data.horaires.length > 0) { 
            setHoraires(data?.horaires);
            setValid(true);
        }
        else { 
            if (data && data.horaire == null) setHoraires([]);;
            setValid(false);
            setDispo([]);
        }
        setSendSubmit(false);
    }, [data]);

    useEffect(() => {
        if (proInfo && proInfo?.statut != "non-valide") {
            setSendSubmit(true);
        }
        else {
            setSendSubmit(false);
        }
    }, [proInfo]);

    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [activeItem, setActiveItem] = useState('agenda');
    const menuItems = [
        { id: 'agenda', label: 'Agenda' },
        { id: 'disponibilites', label: 'Disponibilités' },
        // Ajoutez d'autres éléments ici si nécessaire
    ];
    const [rdvFilter, setRdvFilter] = useState('all'); // 'all' ou 'new'
    const filters = [
        { name: 'all', label: 'Tout', active: rdvFilter === 'all' },
        { name: 'new', label: 'Nouveaux', active: rdvFilter === 'new' },
        // Ajoutez plus de filtres ici selon vos besoins
    ];
    const [newdatadispo, setNewdatadispo] = useState(null);
    const [chargementCal, setChargementCal] = useState(true);
    const [newActiveItem, setNewActiveItem] = useState(null);
    const [confirmNeed, setConfirmNeed] = useState(null);

    const [selectedRdv, setSelectedRdv] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/')[3];
        if (path) setActiveItem(path);
    }, [location]);

    const handleSetActiveItem = (newItem) => {
        if (confirmNeed) {
            setNewActiveItem(newItem);
        } else {
            setActiveItem(newItem);
            navigate(`/pro/agenda/${newItem}`); // Utilisation de useNavigate pour changer l'URL
        }
    }

    const handleModal = () => {
        handleSetActiveItem(newActiveItem)
        setNewActiveItem(null);
        setConfirmNeed(null);
    }

    // Fonction pour gérer les nouvelles horaires
    const handleNewHoraires = (nouvellesHoraires) => {
        if (nouvellesHoraires && nouvellesHoraires!= true) { 
            setNewdatadispo(nouvellesHoraires);
            setDispo(nouvellesHoraires);
        }
        else {
            setNewdatadispo([{ id: 0, jour: '0', debut: '0', fin: '0', periode: '0' }]);
        }
    };

    const handleDateChange = (start, end) => {

        if (start && end) {
            setDates({ startDate: start, endDate: end, startIsoDate: start.toISOString(), endIsoDate: end.toISOString() });
            setChargementCal(true);
        }
        // Ici, convertissez start et end en tableau de jours si nécessaire
    };

    const { data: rdvsdata, loading: rdvsloading } = useFetch(`/rdvs?startDate=${dates.startIsoDate}&endDate=${dates.endIsoDate}`, 'GET', null, true, sendDate);

    useEffect(() => {
        if (!modalnContent) return;

        const { id, action } = modalnContent;

        if (action === 'cancel' || action === 'confirm'|| action === 'cancel') {
        setSendDate(true);
        }
        
    }, [modalnContent]);

    const handleFinalNewHoraires = (nouvellesHoraires) => {

    };
    useEffect(() => {
        if (nreloadFeed) {
            setSendDate(true);
            reloadFeed(false);
        }
    }, [nreloadFeed]);

    useEffect(() => {
        if (dates.startIsoDate && dates.endIsoDate) {
            // Appel de useFetch ici assure que startIsoDate et endIsoDate sont à jour
            setSendDate(true);
        }
    }, [dates.startIsoDate, dates.endIsoDate]);

    useEffect(() => {
        if (rdvsdata && rdvsdata.length > 0) {
            // Appel de useFetch ici assure que startIsoDate et endIsoDate sont à jour


            const nrdvs = rdvsdata.map((rdv, index) => {
                const dateDebut = new Date(rdv.date);
                const dateFin = new Date(rdv.datefin);

                return {
                    id: rdv.id,
                    prenom: rdv.prenom,
                    confirme: rdv.confirme, // Les IDs commencent à 1 et s'incrémentent
                    date: new Date(dateDebut.getFullYear(), dateDebut.getMonth(), dateDebut.getDate()), // Crée une nouvelle Date sans les heures
                    debut: `${dateDebut.getHours()}:${dateDebut.getMinutes().toString().padStart(2, '0')}`, // Format HH:mm pour l'heure de début en heure locale
                    fin: `${dateFin.getHours()}:${dateFin.getMinutes().toString().padStart(2, '0')}`, // Format HH:mm pour l'heure de fin en heure locale
                };
            });
            setRdvs(nrdvs);

        }
        setSendDate(false);
    }, [rdvsdata]);

    const clickRdv = (id) => {
        const isMobile = window.innerWidth < 768;
        if (isMobile) {
            openModalWrdv('rdv',id)
        } else {
        setSelectedRdv(id);
        }
    }

    

    const arrets = [
        //     { id: 1, date: new Date(2024, 4, 7), debut: '9:00', fin: '20:30' },
        //     { id: 2, date: new Date(2023, 11, 13), debut: '9:00', fin: '10:30' },
        //     { id: 3, date: new Date(2023, 10, 14), debut: '9:00', fin: '9:30' },
    ];

    const filteredRdvs = rdvs.filter(rdv => {
        if (rdvFilter === 'new') {
            return rdv.confirme === 0; // Filtrer pour les RDV non confirmés
        }
        return true; // Afficher tous les RDV
    });

 


    return (
        <div className='page'>
            <Modal active={newActiveItem ? true : false} >
                <div>
                    <h3>Vos modifications n'ont pas été sauvegardées, êtes vous sûre de vouloir abandonner vos changements ?</h3>
                    <div className="btns">
                        <button className="btn-pro scnd" onClick={() => setNewActiveItem(null)} ><p>Rester</p></button>
                        <button className="btn-pro" onClick={handleModal} ><p>Quitter</p></button>
                    </div>
                </div>
            </Modal>

            <NotifPage />
            <Leftmenu />
            <div className='content agenda'>
                <Hearderpro titre="Agenda" />
                <SousMenuPro items={menuItems} activeItem={activeItem} setActiveItem={(e) => confirmNeed ? setNewActiveItem(e) : handleSetActiveItem(e)} />


                {activeItem === 'agenda' && sendSubmit != null && (
                    proInfo && proInfo.statut && dispos && filteredRdvs && rdvs ?
                        <div className={proInfo && proInfo?.statut === "non-valide" ? 'flex-h agen-sec non-active' : 'flex-h agen-sec'} >
                            <div className='left sep mobilenotvisible'>
                                <Datepicker className="calendriercheck" onDateChange={handleDateChange} inline={true} />
                                {/* <span /> */}
                                <RdvDisp type="normal" iopen={true} idrdv={selectedRdv} onLoaded={() => null} onVisible={() => null} />
                            </div>
                            <div className='right'>
                                <div className='datem mobilevisible '>
                                    <Datepicker className="calendriercheck " onDateChange={handleDateChange} inline={false} />
                                </div>
                                {activeItem === 'agenda' && (
                                    <ListFiltre filters={filters} titre="Filtrer par:" direct="horizon" setFilter={setRdvFilter} />
                                )}
                                {dispos != null && !rdvsloading ?

                                    <Calendrier dispos={dispos} rdvs={filteredRdvs} arrets={arrets} chargement={() => setChargementCal(false)} selectedDates={dates} disposdisp={activeItem === 'dispo' ? true : false} selectedRdv={clickRdv} />
                                    : <Loader></Loader>}
                            </div>
                        </div> :
                        <Loader></Loader>

                )}

                {activeItem === 'disponibilites' && sendSubmit != null && (
                    proInfo && proInfo.statut ?
                        <div className={proInfo && proInfo?.statut === "non-valide" ? 'flex-h agen-sec non-active' : 'flex-h agen-sec'}>
                            <div className='left sep horaires'>
                                {valid ? null :
                                    <div className="itemnotif alert">
                                        <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9.5" cy="9.5" r="9.5" fill="#FF5E00" />
                                            <path d="M9.88412 4.22228L9.88412 11.899L8.70462 11.899L8.70462 4.22228L9.88412 4.22228ZM9.28438 13.1785C9.51428 13.1785 9.71253 13.2568 9.87913 13.4134C10.0424 13.57 10.124 13.7583 10.124 13.9782C10.124 14.1981 10.0424 14.3863 9.87913 14.5429C9.71253 14.6995 9.51428 14.7778 9.28438 14.7778C9.05447 14.7778 8.85789 14.6995 8.69463 14.5429C8.52803 14.3863 8.44473 14.1981 8.44473 13.9782C8.44473 13.7583 8.52803 13.57 8.69463 13.4134C8.85789 13.2568 9.05447 13.1785 9.28438 13.1785Z" fill="white" />
                                        </svg>
                                        <p>Vous devez mettre à jour vos horaires pour que votre page soit visible.</p>
                                    </div>
                                }
                               
                                {valid != null &&  horaires !== null && !loading ?
                                         <ListCal horaires = {horaires} idPage={data.id} nActiveId={newActiveId}  newHoraires = {(newHoraires) => newHoraires && setSendSubmit(true)} actualHoraires={(horaire, id)=> {handleNewHoraires(horaire) ; setNewActiveId(id) }}/>
                                 : <Loader></Loader>   }
                            </div>
                            {valid != null &&  horaires !== null && !loading ?
                            <div className='right'>
                                <Calendrier dispos={newdatadispo && activeItem === 'disponibilites' ? newdatadispo : dispos} rdvs={[]} arrets={[]} newHoraires = {(newHoraires) => newHoraires && setSendSubmit(true)} chargement={() => setChargementCal(false)} selectedDates={dates} disposdisp={activeItem === 'disponibilites' ? true : false} />
                            </div>  : <Loader></Loader>   }
                        </div> :
                        <Loader></Loader>

                )}
            </div>


        </div>
    );
};



export default Agenda;