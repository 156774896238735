import React, { useEffect, useState } from 'react';
import { useModal } from '../contexts/ModalContext';
import { expr } from 'jquery';

const PayInfo = ({ paymentInfo }) => {
    const [openPay, setOpenPay] = useState(false);
    const { openModal } = useModal();
    const plans = [{ id: "price_1P7tBEJ332Jo8WXU5hJ4ujNp", nom: 'Bien être (free)', prix: '00.00', type: 'mois' }, { id: "price_1OVgTUJ332Jo8WXUXYP36Cyi", nom: 'Bien être (Mensuel)', prix: '14.90', type: 'mois' }, { id: "price_1OwCGiJ332Jo8WXUaOpUzeus", nom: 'Zen Annuel', prix: '4.50', type: 'ans' }, { id: "price_1P2N3QJ332Jo8WXUKoCM2U6w", nom: 'Bien être (Mensuel)', prix: '14.90', type: 'mois'}];
    const codePromo = [{ code: 'BYKASTARTER', reduction: 5, expiraton: '2022-08-31' }];
    const plan = paymentInfo.plan;
    const actualPromo = paymentInfo?.promocode ? paymentInfo.promocode : null;
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };
 
    const formatAmount = (amount) => {
        amount = String(amount); // Convert amount to a string
        const decimalIndex = amount.indexOf('.');
        if (decimalIndex === -1) {
            return amount.replace('.', ',') + ' €';
        } else if (decimalIndex === amount.length - 2) {
            return amount.replace('.', ',') + '0 €';
        } else {
            return amount.replace('.', ',') + ' €';
        }
    };

    const formatFactureAmount = (amount) => {

        return (amount / 100).toFixed(2).replace('.', ',') + ' €'; // Divise par 100 et remplace le point par une virgule

    };

    const promoAmount = actualPromo ? codePromo.find((p) => p.code === actualPromo)?.reduction : null;
    const prix = plans?.find((p) => p.id === plan)?.prix;
    const basePrice = formatAmount(paymentInfo.invoices[0].amount_due / 100); 

    return (
        <div className='payinfo'>
            {paymentInfo.digit &&
            <div className='details'>
                <h3 className=''>Informations de payment: </h3>
                <div className="card">
                    <div className='cleft'> <p className='strong'> {paymentInfo.brand}</p>
                        <span className='verti'></span>
                        <p>**** **** **** {paymentInfo.digit}</p></div>
                    <button onClick={() => openModal('pay')} className='btn-base'><p>Modifier</p></button>
                </div>
            </div>
        }
            <div className='details'>
                <h3 className=''>Votre abonnement: </h3>
                <div className="plan">
                    <div className="cleft">
                        <p className='strong'>{plans.find((p) => p.id === plan)?.nom}</p>
                        <span className='verti'></span>
                        <p> {basePrice}/ {plans.find((p) => p.id === plan)?.type} (ttc)</p> 
                    </div>

                </div>

                {actualPromo && (
                    <div className='flex-h sgap'>
                        <p className='promo'>Code promo: {actualPromo}</p>
                        {/* <p className='base-price'>

                            Prix de base: <del> {formatAmount(plans.find((p) => p.id === plan)?.prix)}</del>
                        </p>
                        <p>
                            Valide jusqu'au: {formatDate(codePromo.find((p) => p.code === actualPromo)?.expiraton)}
                        </p> */}
                    </div>
                )}
            </div>


            <h3>Factures:</h3>

            <div className='clientlist'>
                {paymentInfo.invoices.map((facture) => (
                    <a className='client' key={facture.id} href={facture.link} target="_blank" >
                        <p className=''>{facture.numn} </p> <p className='nom'>{formatFactureAmount(facture.amount_due)}</p>  <p> {formatDate(facture.date)}</p> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 5 8" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83885 7.14795L2.30487 6.68192L2.30528 6.68233L3.81268 5.17493L3.81215 5.1744L4.97351 4.01304L5 4.01304L4.98675 3.9998L4.99915 3.9874L4.97436 3.9874L3.746 2.75904L3.73641 2.74945L3.73652 2.74934L3.09355 2.10638L3.09334 2.10638L2.30501 1.31806L2.30482 1.31825L1.839 0.852443L0.242937 0.852443L1.68393 2.29344L1.68413 2.29324L2.14993 2.75904L2.15015 2.75904L2.76109 3.36999L2.76098 3.37009L3.39069 3.9998L2.76007 4.63042L2.7606 4.63095L1.68422 5.70732L1.68382 5.70691L0.24278 7.14795L1.83885 7.14795Z" fill="#006963" />
                        </svg>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default PayInfo;