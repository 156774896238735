import React, { createContext, useContext, useEffect, useState } from 'react';
import { makeRequest } from '../makeRequest';
import { set } from 'date-fns';
import { get } from 'jquery';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
    const [userRole, setUserRole] = useState(null); // Initialisation à partir de localStorage
    const [userId, setUserId] = useState(null); // Initialisation à partir de localStorage
    const [userLoc, setUserLoc] = useState(null); // Initialisation à partir de localStorage
    const [userInfo, setUserInfo] = useState(null); 
    const [proInfo, setProInfo] = useState(null);
    const [connectErr, setConnectErr] = useState(null); 
   
    const getRole = async () => {
        try {
            const response = await makeRequest({
                method: 'GET',
                url: `/pro-infos`,
                authToken: localStorage.getItem('authToken')
            }); 
            if ( response && response.data.data.id){
                setUserRole("pro");
                setProInfo(response.data.data);
             }
            else setUserRole("user");
        } catch (error) {
            if (error.response) {
                setUserRole("user");
            }
        }
    };


    const login = async (email, password) => {
        try {
            const response = await makeRequest({
                method: 'POST',
                url: '/auth/local',
                data: {
                    identifier: email,
                    password: password,
                },
            });
            const data = response.data;
            if (data.jwt) {

                setConnectErr(null); 
                localStorage.setItem('authToken', data.jwt);
                localStorage.setItem('userId', data.user.id); 
                setAuthToken(data.jwt);
                setUserId(data.user.id);
                setUserLoc(data.adresse);
                setUserInfo(data.user);
                getRole(data.user.id);
            } else {
                throw new Error('Échec de la connexion');
            }
        } catch (error) {
            console.error('Erreur de connexion:', error);
            setConnectErr("L'adresse email ou le mot de passe est incorrect");
            throw error;
        }
    };



    const register = async (email, password, nom, prenom, adresse, telephone) => {
        try {
            const response = await makeRequest({
                method: 'POST',
                url: '/auth/local/register',
                data: {
                    username: email,
                    email: email,
                    password: password,
                    nom: nom,
                    prenom: prenom,
                    adresse: adresse,
                    telephone: telephone,
                },
            });
            const data = response.data;
            
            if (data.jwt) {
                setConnectErr(null);
                localStorage.setItem('authToken', data.jwt);
                setUserId(data.id);
                setUserLoc(data.adresse);
                setUserInfo(data);
                setAuthToken(data.jwt);
                setUserRole("user");// Défini en tant qu'utilisateur si erreur 401
            } else {
                throw new Error("Échec de l'inscription");
            }
        } catch (error) {
            console.error("Erreur lors de l'inscription:", error);
            throw error;
        }
    };

    const verifyToken = async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const response = await makeRequest({
                    method: 'GET',
                    url: '/users/me', // Remplacer par l'URL appropriée pour la vérification du token
                    authToken: token,
                });
                const data = response.data;
                setUserId(data.id);
                setUserLoc(data.adresse);
                setUserInfo(data);
                getRole(data.id);
            } catch (error) {
                localStorage.removeItem('authToken');
                setAuthToken(null);
                setUserRole(null);
                setUserId(null);
            }
        }
    };

    useEffect(() => {
        verifyToken();
    }, []);

    const logout = () => {
        localStorage.removeItem('authToken');
        setAuthToken(null);
        setUserRole(null);
        setUserId(null);
        setUserLoc(null);
        setUserInfo(null);
    };


    const isAuthenticated = () => {
        return authToken != null;
    };

    const verifyPro = () => { 
       getRole();
    };

    const rafraichir = () => {
        verifyToken();
    };

    const isRole = () => {
        return userRole != null;
    };

    const getUserLoc = () => {
        return userLoc;
    };

    
    const getUserInfo = () => {
        return userInfo;
    };

    return (
        <AuthContext.Provider value={{ authToken, isAuthenticated, login, logout, register, isRole, userRole, userId, verifyToken, getUserLoc, userInfo, getUserInfo, connectErr, proInfo, verifyPro }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);