import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import  useFetch from '../hooks/useFetch';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { set } from 'date-fns';

const Searchbar = () => {

    const [searchInput, setSearchInput] = useState("");
 
     
    const [selectedVille, setSelectedVille] = useState('all');
    const [isFocused, setIsFocused] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate(); // Créez l'instance de navigate
    const location = useLocation();
    // Fonction pour gérer la soumission
    const handleSearchSubmit = (e) => {
        e.preventDefault(); // Empêche le comportement par défaut du formulaire
        navigate(`/recherche/${selectedVille}/${searchInput}`); // Utilisez navigate pour rediriger
    };
    const [option, setOption] = useState(null);

    useEffect(() => {
         const npath = location.pathname;
          if (npath.includes('/recherche/')) {
             setSearchInput(searchQuery);
             setSelectedVille(ville);
          }
      }, [location]);

    const { searchQuery, ville } = useParams(); // Accédez à la query de recherche
    
    const { data: dataOptions, loading: searchLoading } = useFetch(`/suggestions`, 'GET', null, false, true);
    const { data: villeData, loading: villeLoading } = useFetch(`/villes`, 'GET', null, false, true);
    useEffect(() => {
        if(dataOptions && dataOptions.length > 1) { 
            setOption(dataOptions);
        }
    }, [dataOptions]);

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
    setTimeout(() => setIsFocused(false), 100);
    };


    const handleVilleChange = (event) => {
        setSelectedVille(event.target.value);
    };

    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        
    };

    const filteredOptions = option && option.length > 1 &&  option.attributes?.nom ? option.filter(option =>
        option.attributes.nom.toLowerCase().includes(searchInput.toLowerCase())
    ) : [];

    const handleSuggestionClick = (nom) => { 
        setSearchInput(nom); // Mettre à jour searchInput avec la valeur 
        setIsFocused(false); // Fermer les suggestions après la sélection
    };


    return ( 
        <div className='searchbar'>
            <input
                type="text"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Cherchez une préstation..."
                onChange={handleSearchChange}
                value={searchInput} />
            <div className="suggest">
                <ul>
                    { searchInput && isFocused && filteredOptions.map((option, index) => (
                        <li key={index}  onClick={() => handleSuggestionClick(option.attributes.nom) }>{option.attributes.nom}</li>
                    ))}
                </ul>
            </div>
                <span className='verti'></span>
            {!villeLoading && villeData &&
            <select value={selectedVille} onChange={handleVilleChange}>
                <option value="all">Sélectionnez une ville</option>
                {villeData?.map(ville => (
                    <option key={ville.nom} value={ville.nom}>{ville.nom}</option>
                ))}
            </select>}
            
            <button type="submit"className="btn-front" onClick={handleSearchSubmit} >
                <p>Rechercher</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3329 0.613077C6.34349 0.208068 7.42555 0 8.51757 0C9.60959 0 10.6916 0.208068 11.7022 0.613077C12.7129 1.01811 13.6333 1.61259 14.41 2.36415C15.1869 3.11577 15.8049 4.00988 16.2272 4.99631C16.6496 5.98282 16.8674 7.04137 16.8674 8.11121C16.8674 9.18106 16.6496 10.2396 16.2272 11.2261C15.9401 11.8968 15.5625 12.5248 15.106 13.0943L18.4 16.2813C18.7969 16.6653 18.8073 17.2984 18.4233 17.6953C18.0393 18.0923 17.4062 18.1027 17.0093 17.7187L13.6737 14.4914C12.208 15.6092 10.3932 16.2224 8.51757 16.2224C6.31351 16.2224 4.19334 15.3756 2.6251 13.8583C1.05574 12.3399 0.167725 10.2733 0.167725 8.11121C0.167725 5.94914 1.05574 3.88256 2.6251 2.36415C3.40188 1.61259 4.32226 1.01811 5.3329 0.613077ZM8.51757 2C7.6792 2 6.84977 2.15981 6.07691 2.46954C5.3041 2.77926 4.604 3.23238 4.01579 3.8015C2.82843 4.95031 2.16772 6.50127 2.16772 8.11121C2.16772 9.72116 2.82843 11.2721 4.01579 12.4209C5.20427 13.5708 6.82303 14.2224 8.51757 14.2224C10.2121 14.2224 11.8309 13.5708 13.0194 12.4209C13.6075 11.8519 14.0722 11.1781 14.3886 10.439C14.705 9.69996 14.8674 8.90908 14.8674 8.11121C14.8674 7.31334 14.705 6.52247 14.3886 5.78343C14.0722 5.04432 13.6075 4.37056 13.0194 3.8015C12.4311 3.23238 11.731 2.77926 10.9582 2.46954C10.1854 2.15981 9.35594 2 8.51757 2Z" fill="white" />
                </svg>
            </button >

        </div>
    );
};

export default Searchbar;