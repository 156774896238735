import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { startOfWeek, addDays } from "date-fns";
import ListFiltre from "./ListFiltre";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { format } from "date-fns";
import { useAuth } from "../contexts/AuthContext";
import RdvList from "./RdvList";
import useFetch from "../hooks/useFetch";
import Loader from "./Loader";
import SimpleSwitch from "./SimpleSwitch";
registerLocale("fr", fr);

const Factures = ({ statut }) => {
  const { userRole } = useAuth();
  const [selectedFacture, setSelectedFacture] = useState(1);
  const [clientFilter, setClientFilter] = useState("all"); // 'all' ou 'new'
  const [clientSort, setClientSort] = useState("date"); // 'all' ou 'new'
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [url, setUrl] = useState(`factures?page=${page}&pageSize=${pageSize}`);
  const { data, loading, error } = useFetch(
    `factures?page=${page}&pageSize=${pageSize}`,
    "GET",
    null,
    true,
    true
  ); 
  const filters = [
    { name: "all", label: "Tous", active: clientFilter === "all" },
    { name: "new", label: "Nouveaux", active: clientFilter === "new" },
    { name: "blocked", label: "Bloqués", active: clientFilter === "blocked" },
    // Ajoutez plus de filtres ici selon vos besoins
  ];

  const sort = [
    { name: "date", label: "Date", active: clientSort === "date" },
    {
      name: "inscription",
      label: "Inscription",
      active: clientSort === "inscription",
    },
    // Ajoutez plus de filtres ici selon vos besoins
  ];
  return statut ? (
    <div
      className={
        statut === "non-valide" ? "flex-h clients non-active" : "flex-h clients"
      }
    >
      <div className="left  ">
        <h3>Factures</h3>
        <div className="triseclect form mid dnone">
          <div class="form-group">
            <p>Du</p>
            <DatePicker
              className="inputs mid"
              placeholderText="Selectionnez une date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={endDate}
            />
          </div>
          <div class="form-group ">
            <p>Au</p>
            <DatePicker
              className="inputs mid"
              placeholderText="Selectionnez une date"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        {/* <ListFiltre filters={sort} titre="Trier par:" direct="verti" setFilter={setClientSort} /> */}
      </div>
      <div className="right ">
        
        <div className="clientlist">
          {data?.attributes?.data.map((facture) => (
            <a
              className="client"
              target="_blank"
              key={facture.ID}
              rel="noreferrer"
              href={"/facture/" + facture.numeroFacture}
            >
              <p className="">{facture.numeroFacture} </p>{" "}
              {userRole == "pro" ? (
                <p className="nom">
                  {facture.nomParticulier} {facture.prenomParticulier}
                </p>
              ) : (
                <p className="nom">{facture.nomPro}</p>
              )}{" "}
              <p> {facture.dateEmission}</p>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="15"
                viewBox="0 0 5 8"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.83885 7.14795L2.30487 6.68192L2.30528 6.68233L3.81268 5.17493L3.81215 5.1744L4.97351 4.01304L5 4.01304L4.98675 3.9998L4.99915 3.9874L4.97436 3.9874L3.746 2.75904L3.73641 2.74945L3.73652 2.74934L3.09355 2.10638L3.09334 2.10638L2.30501 1.31806L2.30482 1.31825L1.839 0.852443L0.242937 0.852443L1.68393 2.29344L1.68413 2.29324L2.14993 2.75904L2.15015 2.75904L2.76109 3.36999L2.76098 3.37009L3.39069 3.9998L2.76007 4.63042L2.7606 4.63095L1.68422 5.70732L1.68382 5.70691L0.24278 7.14795L1.83885 7.14795Z"
                  fill="#006963"
                />
              </svg>
            </a>
          ))}
        </div>
        <div className="details dnone">
          <div className="ca">
            <h3>Total généré: </h3>
            {startDate && endDate && (
              <div className="grp">
                {" "}
                <p>Du </p> <p>{format(startDate, "dd/MM/yyyy")}</p> <p> au </p>{" "}
                <p>{format(endDate, "dd/MM/yyyy")}</p>{" "}
              </div>
            )}
            <p className="can">360 €</p>
          </div>

          <h4>Selectionné</h4>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Factures;
