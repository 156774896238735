import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import useFetch from '../hooks/useFetch';
import Loader from './Loader';

const Facture = () => {
  const { idFacture } = useParams();
  const { data, loading, error } = useFetch(`factures?type=pro&num=${idFacture}`, 'GET', null, true, true);
  const [detailsArray, setDetailsArray] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [factureAct, setFactureAct] = useState(null);

  // Fonction de décodage des entités HTML
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    if (data) { 
      const rawData = data?.attributes?.data[0];

      // Décodage des champs
      const decodedData = {
        ...rawData,
        nomPro: decodeHtml(rawData.nomPro),
        adressePro: decodeHtml(rawData.adressePro),
        adresseParticulier: decodeHtml(rawData.adresseParticulier),
      }; 

      setFactureAct(decodedData);
      const detailsInitiaux = JSON.parse(decodedData.details);

      // Regrouper les détails par id_prestation
      const detailsRegroupes = detailsInitiaux.reduce((acc, detail) => {
        if (acc[detail.id_prestation]) {
          acc[detail.id_prestation].quantite += detail.quantite;
          acc[detail.id_prestation].prixTotal += detail.prix * detail.quantite;
        } else {
          acc[detail.id_prestation] = { ...detail, prixTotal: detail.prix * detail.quantite };
        }
        return acc;
      }, {});
      // Transformer l'objet en tableau pour le setState
      const detailsArray = Object.values(detailsRegroupes); 
      setDetailsArray(detailsArray);
    }
  }, [data]);

  // Fonction pour calculer le total hors taxes
  const calculerTotalTTC = () => {
    let total = 0;
    detailsArray.forEach((item) => {
      total += item.prix * item.quantite;
    });
    return parseFloat(total.toFixed(2)); // Arrondir au centime
  };
  
  // Fonction pour extraire la TVA du montant TTC
  const calculerTVA = (totalTTC) => {
    if (factureAct && factureAct.taxPercentage) {
      const tva = totalTTC - (totalTTC / (1 + factureAct.taxPercentage / 100));
      return parseFloat(tva.toFixed(2)); // Arrondir au centime
    }
    return 0;
  };
  
  // Fonction pour calculer le total HT
  const calculerTotalHT = (totalTTC) => {
    if (factureAct && factureAct.taxPercentage) {
      const totalHT = totalTTC / (1 + factureAct.taxPercentage / 100);
      return parseFloat(totalHT.toFixed(2)); // Arrondir au centime
    }
    return parseFloat(totalTTC.toFixed(2)); // Arrondir au centime
  };
  

  // Téléchargement de la facture en PDF
  const telechargerPDF = () => {
    const element = document.getElementById('factureid');
    html2canvas(element, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('facture.pdf');
    });
  };

  return (
    <section className='facture'>
      {loading ? <Loader /> : (
        data && (
          <div className='content'>
            <header>
              <h1>Facture</h1>
              <p className='adv mobilevisible'><br />Connectez-vous sur ordinateur pour télécharger la facture...</p>
              <button className={loadingButton ? "btn-front svg mobilenotvisible " : "btn-front mobilenotvisible"} onClick={telechargerPDF}>
                {loadingButton ? <Loader /> : <p>Télécharger la facture</p>}
              </button>
            </header>
            
            {factureAct && (
              <div className='invoice' id='factureid'>
                <div className="invoice-logo">
                  <h3>{ decodeHtml(factureAct.nomPro)}</h3>
                </div>

                <div className="invoice-header">
                  <div className="invoice-professional-info">
                    <p>{decodeHtml(factureAct.adressePro)}</p>
                    <p>{factureAct.emailPro}</p>
                    <div className='line'><p className='desc'>Téléphone: </p>{factureAct.telephonePro}</div>
                    <div className='line'><p className='desc'>SIRET:</p>{factureAct.SIRET}</div>
                    <div className='line'><p className='desc'>Statut:</p> Entrepreneur Individuel (EI)</div>
                  </div>
                </div>

                <div className="invoice-client-info">
                  <div><p className='desc'>Destinataire:</p> <p>{factureAct.prenomParticulier} {factureAct.nomParticulier}</p></div>
                  <p><p className='desc'>Adresse: </p>{decodeHtml(factureAct.adresseParticulier)}</p>
                </div>

                <div className="invoice-details">
                  <p><p className='desc'>Facture:</p> {factureAct.numeroFacture}</p>
                  <p><p className='desc'>Date de facture:</p> {factureAct.dateEmission}</p>
                  <p><p className='desc'>Date d'échéance:</p> {factureAct.dateDue}</p>
                </div>
                <h3>Détails:</h3>
                <ul className='prestas'>
                  <li className='presta head'>
                    <p className='nom'>Description</p>
                    <p>Quantité</p>
                    <p>Prix</p>
                    <p>Montant</p>
                  </li>
                  {detailsArray.map((item) => (
                    <li className='presta' key={item.id_prestation}>
                      <p className='nom'>{item.titre}</p>
                      <p>*{item.quantite}</p>
                      <p>{item.prix}€</p>
                      <p>{item.prix * item.quantite}€</p>
                    </li>
                  ))}
                </ul>

                <div className="droite">
                
                {factureAct.tax && (
                  <>
                    <div className='tot'>
                      <p className='desc'>TVA ({factureAct.taxPercentage}%):</p>
                      <h5>{calculerTVA(calculerTotalTTC())}€</h5>
                    </div>
                    <div className='tot'>
                      <p className='desc'>Sous-total à payer (HT):</p>
                      <h5>{calculerTotalHT(calculerTotalTTC())}€</h5>
                    </div>
                  </>
                )}
                <div className='tot'>
                  <p className='desc'>Total à payer (TTC):</p>
                  <h4>{calculerTotalTTC()}€</h4>
                </div>
              </div>

                <div>
                  <p className='strong'>Prestations de services. <br /></p>
                  <p>--</p>
                  <p className='strong'>Conditions générales:</p>
                  {!factureAct.tax ? (
                    <p>TVA non applicable, article 293B du code général des impôts.</p>
                  ) : (
                    <p>La TVA est incluse dans le montant total de la facture.</p>
                  )}
                </div>

                <h4>Facture payée</h4>

                <div className='reglement'>
                  Le règlement se fait en espèces, en carte bancaire ou en chèque, selon les modalités de paiement du professionnel, à la fin de la prestation.
                  <p>--</p>
                  <p className='desc'>Médiateur:</p> {factureAct.mediateur ? factureAct.mediateur : 'non renseigné'}
                </div> 
                <div className="bottom"><p>Facture par bykahomes.com</p></div>
              </div>
            )}
          </div>
        )
      )}
    </section>
  );
};

export default Facture;
