import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import { set } from 'date-fns';
import Card from '../../components/Card';
import ListFiltre from '../../components/ListFiltre';
import useFetch from '../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import { da } from 'date-fns/locale';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';


const Marketplace = () => {
 
    const navigate = useNavigate();
    const location = useLocation();
    const [path, setPath] = useState(null);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('Marketplace');
    const [clientSort, setClientSort] = useState('date'); // 'all' ou 'new'
    const { searchQuery, ville } = useParams(); // Accédez à la query de recherche 
    const [url, setUrl] = useState('');
    const [sendData, setSendData] = useState(false);

    useEffect(() => {
        let baseURL = `/prestataire-pages`;

        if (path && path.includes('/recherche/')) {
            if (!searchQuery) {
                setSendData(true);
                setTitle('');
            }
            else {
                setTitle('Résultats de recherche : ' + searchQuery);
                baseURL += `?search=${searchQuery}&ville=${ville}`;
            }
            setUrl(baseURL);
            setSendData(true);
        } else if (path && path.includes('/categorie/')) {


            if (path && path === '/categorie/coiffure') {
                setTitle('Coiffure');
                baseURL += `?competence=1`;

                setUrl(baseURL);
                setSendData(true);
            }
            else if (path && path === '/categorie/soins-esthetiques') {
                setTitle('Soins esthétiques');              
                baseURL += `?competence=4`;

                setUrl(baseURL);
                setSendData(true);
            }
            else if (path === '/categorie/onglerie') {
                setTitle('Onglerie');
                baseURL += `?competence=2`;

                setUrl(baseURL);
                setSendData(true);
            }
        }
    }, [path]);

    const { data: dataSearch, loading: searchLoading } = useFetch(url, 'GET', null, false, sendData);


    const sort = [
        { name: 'date', label: 'Date', active: clientSort === 'date' },
        { name: 'inscription', label: 'Inscription', active: clientSort === 'inscription' },
        // Ajoutez plus de filtres ici selon vos besoins
    ];

    useEffect(() => {
        const npath = location.pathname;
        setPath(npath);
    }, [location]);

    useEffect(() => {
        setSendData(false);
    }, [dataSearch]);



    useEffect(() => {
        if (dataSearch) {
            const getImageUrl = (photo) => {
                if (photo?.formats?.medium?.url) {
                    return process.env.REACT_APP_UPLOAD_URL + photo.formats.medium.url;
                } else if (photo?.formats?.small?.url) {
                    return process.env.REACT_APP_UPLOAD_URL + photo.formats.small.url;
                } else if (photo?.formats?.thumbnail?.url) {
                    return process.env.REACT_APP_UPLOAD_URL + photo.formats.thumbnail.url;
                } else {
                    return process.env.REACT_APP_UPLOAD_URL + photo?.url;
                }
            };
    
            const formattedData = dataSearch.map(item => {
                // Créer l'objet de base
                const baseObject = {
                    id: item.id,
                    nom: item.nom,
                    note: item?.notes?.global, // Note fixe pour l'exemple, à remplacer par la vraie note si disponible
                    competences: item.competences.map(competence => competence.nom),
                    ville: item.ville,
                    prestations: item.prestatrouve?.map(presta => [presta.titre, presta.prix]),
                    images: [
                        getImageUrl(item.photo1),
                        getImageUrl(item.photo2)
                    ],
                };
    
                // Ajouter la propriété distance si item.distanceCalculée est disponible
                if (item.distanceCalculée) {
                    baseObject.distance = Math.round((item.distanceCalculée / 1000) * 10) / 10; // Conversion en km avec un chiffre après la virgule
                }
    
                return baseObject;
            });
    
            setSearchResults(formattedData);
            setSendData(false);
        }
    }, [dataSearch]);
    



    return (
        <div className=''>
            <Header></Header>
            <section className="marketplace">

                <h2>{title}</h2>
                <div className="contentfront">
                    <div className="left">
                        <ListFiltre filters={sort} titre="Trier par:" direct="verti" setFilter={setClientSort} />
                    </div>
                    <div className="right">

                        {searchLoading ? <Loader></Loader> :
                            <div className="pro-grid">
                                {searchResults?.map((pro, index) => (
                                    <Card key={index} pro={pro} index={index} ></Card>
                                ))}
                            </div>}
                    </div>


                </div>
            </section>
            <Footer></Footer>
        </div>
    );
};

export default Marketplace;