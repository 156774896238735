import React from 'react';

const FourOFour = () => {
    return (
        <section className='fourofour'>
            <div className='contentFront'>
                <h1>404 - Page introuvable</h1>
                <p>La page que vous cherchez n'existe pas.</p>
                <a href="/">Retour à l'accueil</a>
            </div>
        </section>
    );
};

export default FourOFour;