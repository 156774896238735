import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';


const Card = ({index, pro}) => {

    
    
    const navigate = useNavigate();
    
    const settingsCaroussel = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const navigatecardlink = (id) => {
        navigate(`/prestataire/${id}`);
    }


    return (
        <div key={index} className="professionnel-card" onClick={() => navigatecardlink(pro.id)}>
            <Slider {...settingsCaroussel}>
                {pro.images.map((image, idx) => (
                    <div key={idx} className="image-slide">
                        <img src={image} alt={`Travail de ${pro.nom}`} />
                    </div>
                ))}
            </Slider>
            {pro.prestations && pro.prestations.length > 0 &&  
                <div className="prestations">
                    {pro.prestations.map((presta, idx) => ( 
                        <div className="prestaap">
                            <p key={idx}> {presta[0]} </p>
                            <p> {presta[1]} € </p>
                        </div> 
                    ))}
                </div>
            }
            <div className='txt'> <h3>{pro.nom}</h3>
                <div className='justify'>
                    <div className="fonctions">
                        {pro.competences.map((fonction, idx) => (
                            <p key={idx}> {fonction}</p>
                        ))}
                    </div>
                    <div className="note iconl">
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.19294 0.861242C8.52307 0.192321 9.47693 0.192321 9.80706 0.861242L11.6166 4.52773C11.7768 4.85239 12.0865 5.07741 12.4448 5.12948L16.491 5.71742C17.2292 5.82469 17.524 6.73187 16.9898 7.25255L14.0619 10.1065C13.8027 10.3592 13.6844 10.7233 13.7456 11.0802L14.4368 15.11C14.5629 15.8452 13.7912 16.4059 13.1309 16.0588L9.51188 14.1561C9.19142 13.9877 8.80858 13.9877 8.48812 14.1561L4.86909 16.0588C4.20882 16.4059 3.43713 15.8452 3.56323 15.11L4.25441 11.0802C4.31561 10.7233 4.1973 10.3592 3.93805 10.1065L1.01019 7.25255C0.47602 6.73187 0.770779 5.82469 1.50898 5.71742L5.55519 5.12948C5.91347 5.07741 6.22319 4.85239 6.38342 4.52773L8.19294 0.861242Z" fill="url(#paint0_linear_25_1299)" stroke="#25AAA5" strokeWidth="0.2" />
                            <defs>
                                <linearGradient id="paint0_linear_25_1299" x1="9" y1="-1" x2="9" y2="19" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#C5FFFE" />
                                    <stop offset="1" stopColor="#25AAA5" />
                                </linearGradient>
                            </defs>
                        </svg>
                        {pro.note? 
                        <p className='note'> {pro.note} / 5</p>
                        :
                        <p className=''> Nouveau </p>
                        }
                    </div>
                </div>
                <div className='justify'>
                    <div className='loc'>
                        <div className="ville iconl">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                                <path d="M3.6649 6.64479C4.01812 7.01567 4.49826 7.225 5 7.225C5.24833 7.225 5.49411 7.17364 5.72325 7.07398C5.95237 6.97432 6.16024 6.8284 6.3351 6.64479C6.50996 6.46119 6.64842 6.24349 6.74281 6.00423C6.83719 5.76498 6.88571 5.5087 6.88571 5.25C6.88571 4.72773 6.68817 4.22593 6.3351 3.85521C5.98188 3.48433 5.50174 3.275 5 3.275C4.49826 3.275 4.01812 3.48433 3.6649 3.85521C3.31183 4.22593 3.11429 4.72773 3.11429 5.25C3.11429 5.77227 3.31183 6.27407 3.6649 6.64479ZM5 14.8451C4.96509 14.8034 4.91878 14.7478 4.86246 14.6791C4.72629 14.5132 4.53158 14.2715 4.29787 13.9691C3.83036 13.3643 3.20715 12.5171 2.58413 11.5475C1.96093 10.5777 1.33904 9.48708 0.873228 8.39533C0.406894 7.30236 0.1 6.2151 0.1 5.25C0.1 3.8826 0.617375 2.57213 1.53688 1.60665C2.45623 0.641341 3.70206 0.1 5 0.1C6.29794 0.1 7.54377 0.641341 8.46312 1.60665C9.38262 2.57213 9.9 3.8826 9.9 5.25C9.9 6.2151 9.59311 7.30236 9.12677 8.39533C8.66096 9.48708 8.03907 10.5777 7.41587 11.5475C6.79285 12.5171 6.16964 13.3643 5.70213 13.9691C5.46842 14.2715 5.27371 14.5132 5.13754 14.6791C5.08122 14.7478 5.03491 14.8034 5 14.8451Z" fill="url(#paint0_linear_25_1303)" stroke="#25AAA5" strokeWidth="0.2" />
                                <defs>
                                    <linearGradient id="paint0_linear_25_1303" x1="5" y1="0" x2="5" y2="15" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#C5FFFE" />
                                        <stop offset="0.9999" stopColor="#25A6A4" />
                                        <stop offset="1" stopColor="#25A6A4" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <p>{pro.ville}</p>
                        </div>
                        {pro.distance && pro.distance !== "no" &&
                            <p className='dist'>À environ <strong>{pro.distance}</strong> km</p>
                        }
                    </div>

                    <button className='btn-front spe '>
                        <span />
                        <p>Voir plus</p>
                        <div className="icon">
                            <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79233 15.1934L4.85419 14.1404L4.85429 14.1405L8.29174 10.7319L8.29173 10.7319L10.9414 8.10442L10.9998 8.10442L10.9706 8.07549L11 8.04635L10.9412 8.04635L8.14002 5.26864L8.14009 5.26864L8.11839 5.24713L8.11849 5.24704L6.65219 3.79305L6.652 3.79305L5.25842 2.41116L5.25848 2.4111L3.79243 0.957352L0.152779 0.957353L3.0342 3.81459L3.03414 3.81465L4.50044 5.26864L4.50062 5.26864L5.89397 6.6503L5.89384 6.65043L7.33096 8.07549L5.89405 9.50034L5.89406 9.50036L3.4389 11.9349L3.4388 11.9348L0.152678 15.1934L3.79233 15.1934Z" fill="#006963" />
                            </svg>

                        </div>
                    </button>

                </div>

            </div>
        </div>
    );
};

export default Card;