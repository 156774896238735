import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useAuth } from '../contexts/AuthContext';
import useFetch from '../hooks/useFetch';
import { useModal } from '../contexts/ModalContext';
import { useAlert } from '../contexts/AlertContext';
import Loader from './Loader';
import { set } from 'date-fns';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        },
    }
};

const FormulairePaiement = ({ pro, reload, type }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [bodyPay, setBodyPay] = useState([]);
    const [errors, setErrors] = useState({});
    const [clientSecretNew, setClientSecretNew] = useState(null);
    const { userInfo, proInfo, verifyPro } = useAuth();
    const [sendPayment, setSendPayment] = useState(false);
    const [nerror, setNerror] = useState(false);
    const [codePromo, setCodePromo] = useState('');
    const [url, setUrl] = useState(type == "update" ? `/stripe/update-subscription/${proInfo?.pay?.id}` : `/stripe/create-subscription`);
    const { closeModal, modalContent } = useModal();
    const [is3dSecurewait, setIs3dSecurewait] = useState(false);    
    const [sendConfirm, setSendConfirm] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(null); 
    const plans = [{ id: "price_1OVgTUJ332Jo8WXUXYP36Cyi", nom: 'Bien être (Mensuel)', prix: '19.90', type: 'mois' }];
    const [selectedPlan, setSelectedPlan] = useState('price_1PoOX5J332Jo8WXUgqK5857k');  // plan pour prod
    //const [selectedPlan, setSelectedPlan] = useState('price_1P2N3QJ332Jo8WXUKoCM2U6w');  // plan pour dev
    
    const handlePlanClick = (planId) => {
        setSelectedPlan(planId);
    };
    const { openAlert } = useAlert();

    const { data: payResult, loading, error } = useFetch(url, 'Post', bodyPay, true, sendPayment);
    const { data: checkpay, loading: loadingCheck } = useFetch(`/stripe/check-payment-status`, 'Post',  { clientSecret: clientSecretNew, planId: selectedPlan, subscriptionId: subscriptionId }, true, sendConfirm);
    const validate = () => {
        let errors = {};
        if (!stripe || !elements) {
            errors.stripe = 'Une erreur est survenue';
        }
        if (!elements.getElement(CardNumberElement)) {
            errors.numcard = 'Veuillez renseigner un numéro de carte valide';
        }
        if (!elements.getElement(CardExpiryElement)) {
            errors.expDate = 'Veuillez renseigner une date d\'expiration valide';
        }
        if (!elements.getElement(CardCvcElement)) {
            errors.cvc = 'Veuillez renseigner un code CVC valide';
        }
        return errors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = validate();
        setErrors(errors);
        if (Object.keys(errors).length === 0) {

            if (!stripe || !elements) {
                return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
                return;
            }

            try {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        name: pro?.nomentreprise,
                        email: pro?.mail,
                        address: {
                            line1: pro?.rue,
                            city: pro?.ville.value,
                            postal_code: pro?.codepostal,
                            country: 'FR',
                        },
                    },
                });

                if (error) {
                    openAlert('Une erreur est surevenue', false);
                    throw error;
                } else {
                    let body = {
                        paymentMethodId: paymentMethod.id,
                        stripeEmail: pro?.mail,
                        planId: selectedPlan,
                    };

                    if (codePromo && codePromo.length > 0) {
                        body.promoCode = codePromo;
                    }

                    setBodyPay(body);
                    setSendPayment(true);
                }
            } catch (error) {
                openAlert('Une erreur est surevenue', false);
            }
        }
    };

    useEffect(() => {
        const handlePayment = async () => { 
            if (payResult) {
                const { status, clientSecret, subscriptionId } = payResult; 
                setSendPayment(false);
                if (status === 'requires_action' && clientSecret) { 
                    setIs3dSecurewait(true);
                    
                    const { error: confirmationError } = await stripe.confirmCardPayment(clientSecret);

                    if (confirmationError) {
                        setIs3dSecurewait(false);
                        openAlert('Erreur lors de l\'authentification 3D Secure', false);
                        setNerror('Erreur lors de l\'authentification 3D Secure');
                    } else {
                        setSendConfirm(true);
                        setClientSecretNew(clientSecret);
                        setSubscriptionId(subscriptionId);
                    }
                } else if (status === 'active') {
                    openAlert('Abonnement créé ou modifié avec succès', true);
                    verifyPro();
                    closeModal();
                    setNerror(null);
                    reload(true);
                } else {
                    openAlert('Erreur lors de l\'abonnement', false);
                    setNerror('Erreur lors de l\'abonnement');
                }
            } else if (error) {
                setNerror(error?.response?.data?.error?.message);
                setSendPayment(false);
            }
        };

        handlePayment();
    }, [payResult, error]);


    useEffect(() => { 
        if (checkpay) {
            const { status } = checkpay; 
            if (status === 'active') {
                setIs3dSecurewait(false); 
                openAlert('Abonnement créé ou modifié avec succès', true);
                verifyPro();
                closeModal();
                setNerror(null);
                reload(true);
            } else {
                openAlert('Erreur lors de l\'abonnement', false);
                setNerror('Erreur lors de l\'abonnement');
            }
        }
    }
    , [checkpay]);

    return (
        <form onSubmit={handleSubmit} >
            {modalContent != "pay" &&
                <div className="plans">
                    {plans.map(plan => (
                        <div key={plan.id} onClick={() => handlePlanClick(plan.id)} className={selectedPlan === plan.id ? 'it selected' : 'it'}>
                            <h3>{plan.nom}</h3>
                            <p>(ttc)</p>
                            <p>{plan.prix} € / {plan.type}</p>
                        </div>
                    ))}
                </div>}
            <h3>Informations de paiement</h3>
            <div className="form-group inputs">
                <label>Numéro de carte</label>
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                {errors.numcard && <p className="error">{errors.numcard}</p>}
            </div>
            <div className="form fs line ">
                <div className="form-group line inputs">
                    <label>Date d'expiration</label>
                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                    {errors.expDate && <p className="error">{errors.expDate}</p>}
                </div>
                <div className="form-group line inputs">
                    <label>CVC</label>
                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                    {errors.cvc && <p className="error">{errors.cvc}</p>}
                </div>
            </div>
            <div className="form-group line inputs">
                <label>Code promo (facultatif)</label>
                <input className='inputs small' placeholder='Votre code promo' type="text" value={codePromo} onChange={(e) => setCodePromo(e.target.value)} />
            </div>
            {loading || is3dSecurewait ? <Loader type="button plein"></Loader> :
                <div className="btns">
                    {nerror && (nerror === "Code promo invalide." ? <p className="bigerror">Code promo invalide</p> : <p className="bigerror">Erreur lors de l'abonnement</p>)}
                    {modalContent === "pay" && <button className='btn-pro scnd' onClick={closeModal} type="button"> <p>Annuler</p></button>}
                    <button className={modalContent === "pay" ? 'btn-pro ' : 'btn-pro full'} type="submit" disabled={!stripe}>
                        <p>Souscrire</p>
                    </button>
                   
                </div>}
                {is3dSecurewait && <p className="info">
                Une authentification 3D Secure est requise pour finaliser votre abonnement. Veuillez vérifier votre identité via l'application mobile de votre banque, le site web de votre banque, ou en répondant à un SMS que vous recevrez pour valider le paiement.</p>}
        </form>
    );
};

export default FormulairePaiement;
