import React, { useState, useEffect } from 'react';
import { useModal } from '../contexts/ModalContext';
import { useAlert } from '../contexts/AlertContext';
import useFetch from '../hooks/useFetch';
import { set } from 'date-fns';
import DOMPurify from 'dompurify';
import validator from 'validator';
import axios from "axios";
import { fr } from 'date-fns/locale';

const LostPassword = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [semdEmail, setSendEmail] = useState(false);
    const [dataSend, setDataSend] = useState(false);
    const { closeModal, openModal } = useModal();
    const { openAlert } = useAlert();
    const { data: mailResponse, loading: mailLoading, error: mailError } = useFetch(`/api/auth/forgot-password`, 'POST', dataSend, "no", semdEmail);
    const [errors, setErrors] = useState({});



    const params = new URLSearchParams(window.location.search);
    const codePass = params.get('codePass');

    const validateFields = () => {
        let newErrors = {};
        let isValid = true;

        if (!codePass) {
            // Email validation et sanitation
            if (!email) {
                newErrors.email = "L'email est requis.";
                isValid = false;
            } else if (!validator.isEmail(email)) {
                newErrors.email = "L'adresse email est invalide.";
                isValid = false;
            } 
        }

        if (codePass) {
            if (!newPassword) {
                newErrors.newPassword = "Le mot de passe est requis.";
                isValid = false;
            } else {
                let passwordError = "";
                if (newPassword.length < 8) {
                    passwordError += "Le mot de passe doit contenir au moins 8 caractères. ";
                }
                if (!/\d/.test(newPassword)) {
                    passwordError += "Le mot de passe doit contenir au moins un chiffre. ";
                }
                if (!/[!@#$%^&*]/.test(newPassword)) {
                    passwordError += "Le mot de passe doit contenir au moins un caractère spécial (!@#$%^&*). ";
                }
                if (newPassword !== confirmPassword) {
                    newErrors.confirmPassword = "Les mots de passe ne correspondent pas.";
                    isValid = false;
                }
                if (passwordError) {
                    newErrors.newPassword = passwordError;
                    isValid = false;
                }
            }
        }


        setErrors(newErrors);
        return isValid;
    };


    const handleEmailSubmit = () => {
        if (validateFields()) {
            axios.post('http://localhost:1337/api/auth/forgot-password', {
                email: email, // user's email
            })
                .then(response => {
                    setStep(2);
                })
                .catch(error => {
                    openAlert("Une erreur est survenue, veillez rééssayer", false);
                });
        }
    };

    const handleChangePass = () => {
        if (validateFields()) {
            axios
            .post('http://localhost:1337/api/auth/reset-password', {
              code: codePass, // code contained in the reset link of step 3.
              password: newPassword,
              passwordConfirmation: confirmPassword,
            })
            .then(response => {
                openAlert("Votre mot de passe à été modifié avec succés", true);
                close();
            })
            .catch(error => {
                openAlert("Une erreur est survenue, veillez rééssayer", false);
            });
        }
    };

    const handlePasswordSubmit = () => {
        // Logic to update password
        setStep(3);
    };

    //fonction qui suprime le codePass de l'url et close la modal 
    const close = () => {
        window.history.replaceState({}, document.title, "/");
        closeModal();
    }

    return (
        <div className='nouvMdp'>
            {step === 2 && (
                <div className=' '>
                    <div class="confirmation">
                        <div class="illu">
                            <svg viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27.5" cy="28" r="27.5" fill="url(#paint0_linear_200_1731)"></circle><path d="M21.791 34.7176L13.9947 27.4885L11.3398 29.9329L21.791 39.6236L44.2264 18.8206L41.5903 16.3762L21.791 34.7176Z" fill="white"></path><defs><linearGradient id="paint0_linear_200_1731" x1="27.5" y1="0.5" x2="27.5" y2="55.5" gradientUnits="userSpaceOnUse"><stop stop-color="#25AAA5"></stop><stop offset="1" stop-color="#006963"></stop></linearGradient></defs></svg>
                            <div class="txt">
                                <h4>Félicitation!</h4>
                                <p class="strong">Si l'email est lié a un utilisateur, un lien de réinitialisation lui à été envoyé.</p><p classname="desc">À l'adresse: {email}</p></div></div></div>



                </div>

            )}
            {step === 2 && (

                <button className="btn-pro scnd full" type='button' onClick={close}><p>Retour</p></button>

            )}
            <div className='form'>
                {!codePass && step === 1 && (

                    <h3>Entrez votre adresse email</h3>
                )}

                {codePass && (

                    <h3>Modifiez votre mot de passe</h3>
                )}

                {!codePass && step === 1 && (
                    <div className="form-group">
                        <label htmlFor="email">Votre adresse email</label>
                        <input className='inputs' type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                )}

                {!codePass && step === 1 && (
                    <div className="btns">
                        <button className="btn-pro scnd " type='button' onClick={closeModal}><p>Retour</p></button>
                        <button className='btn-pro' onClick={handleEmailSubmit}><p>Continuer</p></button>
                    </div>
                )}
                {codePass && (
                    <div className="form-group">
                        <label htmlFor="newPassword">Confirmer votre nouveau mot de passe</label>
                        <input className='inputs' type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        {errors.newPassword && <p className="error">{errors.newPassword}</p>}

                    </div>
                )}
                {codePass && (
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Nouveau mot de passe</label>
                        <input className='inputs' type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}

                    </div>
                )}


                {codePass && (
                    <div className="btns">
                        <button className="btn-pro scnd " type='button' onClick={close}><p>Retour</p></button>
                        <button className='btn-pro' onClick={handleChangePass}><p>Modifier</p></button>
                    </div>
                )}


            </div>

            {step === 3 && (
                <div>
                    <h2>Password Updated Successfully!</h2>
                </div>
            )}
        </div>
    );
};

export default LostPassword;
