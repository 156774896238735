import React, { useState, useEffect } from 'react';
import { makeRequest } from '../makeRequest';
import { useAlert } from '../contexts/AlertContext';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';

const useFetch = (url, method, body, auth, sendData) => {
    const [data, setData] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const { openModal } = useModal();
    const [error, setError] = useState(null);
    const { openAlert } = useAlert();
    const { isAuthenticated, logout } = useAuth();
    useEffect(() => {     
        const fetchData = async () => {
            if(sendData){
                if(auth === true){ 
                    try {
                        setIsLoading(true);
                        const response = await makeRequest({
                            method: method,
                            url: url,
                            data: body,
                            authToken: localStorage.getItem('authToken')
                        }); 
                        setData(response.data.data);
                    } catch (err) {
                        console.error("Erreur lors de la requête:", err);
                        //si erreur 401, on déconnecte l'utilisateur
                        if(err.response?.status === 401){
                            logout();
                            openModal('login');
                            openAlert("Votre session a expiré, veuillez vous reconnecter", false);
                        }
                        else{
                            setError(err);
                            openAlert("Une erreur est survenue, veillez rééssayer", false);
                        }
                    } finally {
                        setIsLoading(false);
                    }
                }
                else{     
                    try {
                        setIsLoading(true);
                        const response = await makeRequest({
                            method: method,
                            url: url,
                            data: body
                        }); 
                        setData(response.data.data);
                    } catch (err) {
                        console.error("Erreur lors de la requête:", err);
                        setError(err);
                        openAlert("Une erreur est survenue, veillez rééssayer", false);
                    } finally {
                        setIsLoading(false);
                    }
                }
            }
        };
        fetchData();
    }, [auth, sendData]);



    return { data, loading, error };
};

export default useFetch;