import React, { useState, useEffect } from 'react';
import useFetch from '../hooks/useFetch';
import { useAuth } from '../contexts/AuthContext';
import { useDropzone } from 'react-dropzone';
import { useModal } from '../contexts/ModalContext';
import  {useAlert} from '../contexts/AlertContext';
import { set } from 'date-fns';


const AddComp = () => {
    const { data: dataComp, loading: compLoading } = useFetch(`/competences?populate=img`, 'GET', null, false, true);

    const [selectedCompetences, setSelectedCompetences] = useState([]);
    const [tacceptedFiles, setTacceptedFiles] = useState([]);
    const [competences, setCompetences] = useState([]);
    const [errors, setErrors] = useState({});
    const [submitData, setSubmitData] = useState(null);
    const [sendPage, setSendPage] = useState(false);
    const [proFormstate, setProFormstate] = useState({
        competences: '',
    }); 
    const {  openAlert } = useAlert();
    useEffect(() => {
        if (dataComp) {
            setCompetences(dataComp);
        }
    }, [dataComp]);
    const { data: pageinfo, loading, error } = useFetch('/demande-ajouts', 'POST', submitData, true, sendPage);

    const handleFileDrop = (nacceptedFiles) => {
        const filteredFiles = nacceptedFiles.filter(file => {
            const fileType = file.type;
            return (
                fileType === 'image/jpeg' ||
                fileType === 'image/png' ||
                fileType === 'application/pdf'
            );
        }).map(file => ({
            ...file,
            id: `${Date.now()}-${file.name}`,
            data: file
        }));
        setTacceptedFiles(prevFiles => [...prevFiles, ...filteredFiles]);
    };

    const handleFileDelete = (fileToDelete, event) => {
        event.stopPropagation();
        // Supposons que chaque fichier a une propriété unique, par exemple 'id'
        const updatedFiles = tacceptedFiles.filter(file => file.id !== fileToDelete.id);
        setTacceptedFiles(updatedFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        onDrop: handleFileDrop
    });

    const { closeModal, data } = useModal();

    useEffect(() => {

        setSendPage(false);

        if (pageinfo) { 
            openAlert( 'Votre demande a été soumise avec succès, vous recevrez une réponse dans les 48h', true);
            closeModal();
        }
    }, [pageinfo, error]);



    const handleCompetenceClick = (competence) => {
        let updatedSelectedCompetences;
        if (selectedCompetences.includes(competence)) {
            // Retirer la compétence de la sélection
            updatedSelectedCompetences = selectedCompetences.filter(comp => comp !== competence);
        } else {
            // Ajouter la compétence à la sélection
            updatedSelectedCompetences = [...selectedCompetences, competence];
        }

        // Mettre à jour l'état des compétences sélectionnées
        setSelectedCompetences(updatedSelectedCompetences);

        // Mettre à jour proFormstate avec les compétences sélectionnées
        // Assumons que vous stockez les ID des compétences dans un tableau
        const competencesIds = updatedSelectedCompetences.map(comp => comp.id);

        setProFormstate(prevState => ({
            ...prevState,
            competences: competencesIds // ou tout autre format adapté à votre backend
        }));
    };

    const handleProFormSubmit = async (event) => {

        event.preventDefault();
        const formData = new FormData();
        const sanitizedFormState = {};
        // Valider les données du formulaire
        const errors = {};
        if (selectedCompetences.length === 0) {
            errors.competence = 'Veuillez sélectionner au moins une compétence';
        }
        if (tacceptedFiles.length === 0) {
            errors.documents = 'Veuillez télécharger au moins un document';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        // Préparer les données à envoyer
        for (const key in proFormstate) {
            if (key === 'competences') {
                sanitizedFormState['competences'] = proFormstate[key];
            }
            else if (key === 'ville') {
                sanitizedFormState['ville'] = proFormstate[key];
            } 
        }

        tacceptedFiles.forEach((file, index) => {

            formData.append(`files.preuves`, file.data, file.name);// "file1", "file2", etc.
        });

        formData.append('data', JSON.stringify(sanitizedFormState));
        setSubmitData(formData);
        setSendPage(true);

    }

    const getImageSrc = (url) => {
        return process.env.REACT_APP_UPLOAD_URL + url;

    };
    return (  
        <div className='registerPro'>
            <form onSubmit={handleProFormSubmit}>
                <div className="form-group">

                    <h3>Soumettre une competence</h3>


                    <label htmlFor="">Sélectionnez au moins une compétence</label>
                    <div className="profe">
                        {competences?.map((competence, index) => (
                            <div key={index} className={`item ${selectedCompetences.includes(competence) ? 'active' : ''}`} onClick={() => handleCompetenceClick(competence)}>
                                {competence?.attributes?.img?.data?.attributes &&
                                    <img src={getImageSrc(competence?.attributes?.img?.data?.attributes.url)} alt={competence.attributes.nom} />
                                }
                                <p>{competence.attributes.nom}</p>
                            </div>
                        ))}
                    </div>
                    {errors.competence && <p className="error">{errors.competence}</p>}

                </div>
                <div className="form-group">
                    <label htmlFor="">Documents qui confiment votre expertise : diplôme, ou autres...  </label>

                    <div className='drop' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <ul>
                            {tacceptedFiles.map((file, index) => (
                                <li className='' key={index}>

                                    {file.path}
                                    <button className='btn-base danger' onClick={(event) => handleFileDelete(file, event)} type='button'><p>Supprimer</p></button>
                                </li>
                            ))}
                        </ul>
                        <h4>Faites glisser/déposez des fichiers ici, ou <a onClick={(e) => e.preventDefault}>cliquez pour sélectionner</a> des fichiers. (image ou pdf) </h4>



                    </div>
                    {errors.documents && <p className="error">{errors.documents}</p>}

                </div>
                <div className="btns">
                    <button className='btn-pro scnd danger' onClick={closeModal} type='button'><p>Annuler</p></button>
                    <button className='btn-pro ' type='submit'><p>Soumettre</p></button>
                </div>
            </form>

        </div>
    );
};

export default AddComp;