import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO, isAfter, isBefore, isEqual } from 'date-fns';
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import HorairesForm from './HorairesForm';
import { useAlert } from '../contexts/AlertContext';
import { useAuth } from '../contexts/AuthContext';
import useFetch from '../hooks/useFetch';

const AvailableCal = ({ id, open, active, horaires, toggleOpen, type, toggleActive, idPage, action, onDelete, actualData }) => {
    const horaire = horaires.find(h => h.id === id);
    const [startDate, setStartDate] = useState(horaire?.start ? parseISO(horaire.start) : null);
    const [endDate, setEndDate] = useState(horaire?.end ? parseISO(horaire.end) : null);
    const [newdatadispo, setNewdatadispo] = useState(null);
    const [dispos, setDispo] = useState(type === "new" ? [{ id: 0, jour: '0', debut: '0', fin: '0', periode: '0' }] : horaire?.details || []);
    const [ndate, setNdate] = useState(false);
    const { openAlert } = useAlert();
    const { proInfo } = useAuth();
    const currentDate = new Date();
    const isEnCours = isAfter(currentDate, startDate) && (isBefore(currentDate, endDate) || isEqual(currentDate, endDate));
    const [deleteData, setDeleteData] = useState(null);
    const { data: deleteResponse, loading: deleteLoading, error: deleteError } = useFetch(`/horaires/${id}`, 'DELETE', null, true, deleteData !== null);
    
    const excludeDates = horaires
        .filter(h => h.id !== id)
        .reduce((dates, h) => {
            const start = parseISO(h.start);
            const end = parseISO(h.end);
            for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
                dates.push(new Date(d));
            }
            return dates;
        }, []);

    const isValidDateRange = (start, end) => {
        for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
            if (excludeDates.some(excludedDate => excludedDate.getTime() === date.getTime())) {
                return false;
            }
        }
        return true;
    };

    const handleStartDateChange = (date) => {
        if (isValidDateRange(date, endDate)) {
            setStartDate(date);
            setNdate(true);
        } else {
            openAlert('Selection de dates non autorisée, car des dates exclues sont présentes entre les deux dates sélectionnées.', false);
        }
    };

    const handleEndDateChange = (date) => {
        if (isValidDateRange(startDate, date)) {
            setEndDate(date);
            setNdate(true);
        } else {
            openAlert('Selection de dates non autorisée, car des dates exclues sont présentes entre les deux dates sélectionnées.', false);
        }
    };

    const handleNewHoraires = (nouvellesHoraires) => {
        if (nouvellesHoraires && nouvellesHoraires !== true) {
            setNewdatadispo(nouvellesHoraires);
             setDispo(nouvellesHoraires); 
             actualData(nouvellesHoraires); 
        } else {
            setNewdatadispo([{ id: 0, jour: '0', debut: '0', fin: '0', periode: '0' }]);
        }
    };

    useEffect(() => {
        if (isEnCours && type !== "new") { 
            toggleActive(id);
        }
    }, [isEnCours]);
 

    const handleBack = () => {
        setDispo(horaire.details);
        setNewdatadispo(horaire.details);
    };

    const handleFinalNewHoraires = (nouvellesHoraires) => {
        // Implement this function as needed
    };

    const handleStopPropagation = (e) => {
        e.stopPropagation(); 
    };

    const handleSelect = (e) => {
        e.stopPropagation();  
        toggleActive(id)
    };

    const handleDelete = () => {
        setDeleteData({});
    };

    useEffect(() => {
        if (deleteResponse) {
            openAlert('Horaires supprimés avec succès', true);
            onDelete(id);
        } else if (deleteError) {
            openAlert('Erreur lors de la suppression des horaires', false);
        }
    }, [deleteResponse, deleteError]);

    useEffect(() => {
        if (horaires) {
            const nhoraire = horaires.find(h => h.id === id);
            if(nhoraire) {
            setStartDate(nhoraire.start ? parseISO(nhoraire.start) : null);
            setEndDate(nhoraire.end ? parseISO(nhoraire.end) : null);
            setDispo(nhoraire.details || []);
            setNewdatadispo(nhoraire.details || []);
            }
        }
    }, [horaires]);
    
    return (
        <div className={`availavlecal ${open ? 'open' : active ? 'active' : ''} ${isEnCours ? 'isEnCours' : ''}`} onClick={() => toggleActive(id)}>
            <div className="top" onClick={!open ? handleSelect  : handleStopPropagation}>
                {!open ? (
                    <div className="title">
                        <p>Du <strong>{format(startDate, 'dd/MM/yyyy')}</strong> au <strong>{format(endDate, 'dd/MM/yyyy')}</strong></p>
                        {isEnCours && <p className="en-cours-banner">En cours</p>}
                    </div>
                ) : (
                    <div className='setdate'>
                        <div>
                            <p>Du</p>
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                locale="fr"
                                customInput={<CustomInput />}
                                maxDate={endDate}
                                minDate={new Date()}
                                excludeDates={excludeDates}
                            />
                            <button onClick={()=>setStartDate(null)} className='btn-base '>
                        <p>Reset</p>
                    </button>
                        </div>
                        <span className='spdat'></span>
                        <div>
                            <p>Au </p>
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                locale="fr"
                                minDate={startDate || new Date()}
                                customInput={<CustomInput />}
                                excludeDates={excludeDates}
                            />
                             <button onClick={()=>setEndDate(null)} className='btn-base '>
                        <p>Reset</p>
                    </button>
                        </div>
                        
                    </div>
                )}
                {!open ? <button onClick={() => toggleOpen(id)} className='btn-pro'>
                    <p>Modifier</p>
                    <svg viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.776855 4.42333L1.97662 5.65883L1.97548 5.66L5.85987 9.6601L5.86018 9.65977L8.85452 12.7433L8.85452 12.8125L8.88812 12.7779L8.92108 12.8119L8.92108 12.744L12.1103 9.45974L12.1111 9.46053L13.7679 7.75437L13.7679 7.7516L15.3423 6.13031L15.3424 6.13042L17.0002 4.42322L17.0002 0.187822L13.7433 3.54178L13.7431 3.54167L12.087 5.24713L12.087 5.2499L10.5121 6.87174L10.5113 6.87095L8.88812 8.54251L7.26327 6.86926L7.26295 6.86958L4.48998 4.014L4.49111 4.01283L0.776855 0.187935L0.776855 4.42333Z" fill="white" />
                    </svg>
                </button> : <button onClick={() => toggleOpen(id)} className='btn-pro scnd'>
                    <svg viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M17 8.57667L15.8002 7.34117L15.8014 7.34L11.917 3.3399L11.9167 3.34023L8.92233 0.256693L8.92233 0.1875L8.88874 0.222097L8.85577 0.188149L8.85577 0.256045L5.66655 3.54026L5.66578 3.53947L4.00898 5.24563L4.00898 5.2484L2.43458 6.86969L2.43447 6.86958L0.776655 8.57678L0.776655 12.8122L4.0336 9.45822L4.03371 9.45833L5.68984 7.75287L5.68984 7.7501L7.26476 6.12826L7.26553 6.12905L8.88874 4.45749L10.5136 6.13074L10.5139 6.13042L13.2869 8.986L13.2857 8.98717L17 12.8121L17 8.57667Z" fill="black" />
                    </svg>
                </button>}

            </div>

            {open && (
                <div className="content" onClick={handleStopPropagation}>
                    <HorairesForm
                        datadispos={newdatadispo && newdatadispo != [{ id: 0, jour: '0', debut: '0', fin: '0', periode: '0' }] ? newdatadispo : dispos}
                        newdispo={handleNewHoraires}
                        idDispo={id}
                        newFinal={handleFinalNewHoraires}
                        action={action}
                        start={startDate}
                        end={endDate}
                        idPage={idPage}
                        handleBack={handleBack}
                        handleNew={handleNewHoraires}
                        nDate={ndate}
                        handleClose={toggleOpen}
                    />
                    {type !== "new" && (
                        <button onClick={handleDelete} className='btn-base danger'>
                        <p>Supprimer</p>
                    </button>
                    )}
                   
                </div>
            )}
        </div>
    );
};

const CustomInput = ({ value, onClick, onChange }) => (
    <div className="date-picker-input" onClick={onClick}>
        <input
            type="text"
            value={value}
            onChange={onChange}
            style={{ border: 'none', outline: 'none', flex: 1 }}
        />
        <svg viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.776855 4.42333L1.97662 5.65883L1.97548 5.66L5.85987 9.6601L5.86018 9.65977L8.85452 12.7433L8.85452 12.8125L8.88812 12.7779L8.92108 12.8119L8.92108 12.744L12.1103 9.45974L12.1111 9.46053L13.7679 7.75437L13.7679 7.7516L15.3423 6.13031L15.3424 6.13042L17.0002 4.42322L17.0002 0.187822L13.7433 3.54178L13.7431 3.54167L12.087 5.24713L12.087 5.2499L10.5121 6.87174L10.5113 6.87095L8.88812 8.54251L7.26327 6.86926L7.26295 6.86958L4.48998 4.014L4.49111 4.01283L0.776855 0.187935L0.776855 4.42333Z" fill="black" />
        </svg>
    </div>
);

export default AvailableCal;
