import React, { useEffect, useMemo, useState } from 'react';
import DateSelect from './DateSelect';
import { useAuth } from '../contexts/AuthContext';
import AddressAuto from './AddressAuto';
import useFetch from '../hooks/useFetch';
import DOMPurify from 'dompurify';
import validator from 'validator';
import Loader from './Loader';
import  {useAlert} from '../contexts/AlertContext';
import { useModal } from '../contexts/ModalContext';
import { set } from 'date-fns';



const ProEvent = ({ jour, heureComplete, m, trajet }) => {
    const [sendSubmit, setSendSubmit] = useState(true);
    const { proInfo } = useAuth();
    const [sendSubmitRdv, setSendSubmitRdv] = useState(false);
    const { data, loading, error } = useFetch(`/prestataire-pages`, 'GET', null, true, sendSubmit);
    const [dispos, setDispo] = useState(null);
    const [rdvs, setRdvs] = useState([]);
    const [valid, setValid] = useState(null);
    const [idDispo, setIdDispo] = useState(null);
    const [submitData, setSubmitData] = useState(null);
    const [interval, setInterval] = useState(40);
    const [dureeRdv, setDureeRdv] = useState(10);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentStep, setCurrentStep] = useState(0)
    const [addPresta, setAddPresta] = useState(true);
    const [prestationsSelectionnees, setPrestationsSelectionnees] = useState([]); // État pour les prestations sélectionnées
    const [prestas, setPrestas] = useState([]); // État pour les prestations disponibles
    const [errors, setErrors] = useState({});
    const { data: nrdv , loading: nloading, error: nerror } = useFetch(`/rdvs`, 'POST', submitData, true, sendSubmitRdv);

    const {  openAlert } = useAlert();
    const {  closeModal, nreloadFeed, reloadFeed} = useModal();

    const [formState, setFormState] = useState({
        date: '',
        datefin: '', 
        user: '',
        prestataire_page: '',
        presta_choisies: [],
        email: '',
        nom: '',
        prenom: '',
        adresse: '',
        idPagePro: 0,
        nomPro: '',
        telephone: '',
        accepteConditions: false
    });


    const validateFields = () => {
        let newErrors = {};
        let isValid = true;

        // Email validation et sanitation
        if (!formState.email) {
            newErrors.email = "L'email est requis.";
            isValid = false;
        } else if (!validator.isEmail(formState.email)) {
            newErrors.email = "L'adresse email est invalide.";
            isValid = false;
        } else {
            formState.email = validator.normalizeEmail(formState.email);
        }


        // Nom et Prénom sanitation (simple exemple, peut être étendu/adapté)
        ['nom', 'prenom'].forEach(field => {
            if (!formState[field]) {
                newErrors[field] = `Le ${field} est requis.`;
                isValid = false;
            } else {
                formState[field] = validator.escape(formState[field]);
            }
        });

        // Adresse sanitation
        if (!formState.adresse) {
            newErrors.adresse = "L'adresse est requise.";
            isValid = false;
        }

        // Téléphone validation
        if (!formState.telephone) {
            newErrors.telephone = "Le numéro de téléphone est requis.";
            isValid = false;
        } else if (!validator.isMobilePhone(formState.telephone, 'any', { strictMode: false })) {
            newErrors.telephone = "Le numéro de téléphone est invalide.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    const handleSubmit = () => {
        if (validateFields()) {
            const formData = new FormData();
            const sanitizedFormState = {};




            for (const key in formState) {
                sanitizedFormState[key] = sanitizeInput(formState[key]);
            }


            // Submit the form
            formData.append('data', JSON.stringify(sanitizedFormState));



            setSubmitData(formData);
            setSendSubmitRdv(true);
        }
    };

    const sanitizeInput = (input) => {
        const sanitizedInput = DOMPurify.sanitize(input);
        return sanitizedInput;
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        const sanitizedValue = sanitizeInput(value);
        setFormState({
            ...formState,
            [name]: sanitizedValue
        });
    };

    useEffect(() => {
        if (data && data.horaires) { 
            setIdDispo(data.horaire?.id);
            setDispo(data?.horaires);
            setValid(true);
            setPrestas(data.prestations);
            setFormState({ ...formState, idPagePro: data.id, nomPro: data.nom });
        }
        else {
            if (data && data.horaire == null) setValid(false);
            setDispo([
                {
                    "id": 1,
                    "jour": "lun",
                    "debut": "07:00",
                    "fin": "12:00",
                    "periode": "am"
                },
                {
                    "id": 2,
                    "jour": "lun",
                    "debut": "14:00",
                    "fin": "18:30",
                    "periode": "pm"
                },
                {
                    "id": 3,
                    "jour": "lun",
                    "debut": "19:15",
                    "fin": "21:00",
                    "periode": "night"
                }
            ]);
        }
        setSendSubmit(false);
    }, [data]);

    useEffect(() => {
        if (prestationsSelectionnees && prestationsSelectionnees.length > 0) {
            let totalTemps = 0;

            // Parcourir chaque prestation et additionner son temps au total
            prestationsSelectionnees.forEach(presta => {
                totalTemps += presta.temps * presta.quantite; // Assurez-vous que `presta.temps` est un nombre
            });

            // Mise à jour du temps total des prestations
            setDureeRdv(totalTemps);
            const newIdPresta = {};
            prestationsSelectionnees.forEach(presta => {
                newIdPresta[presta.id_prestation] = true; // ou toute autre valeur pertinente
            });
        }
    }, [prestationsSelectionnees]);

    useEffect(() => {
        if(selectedDate){
        formState.date = selectedDate.date;
        formState.datefin = selectedDate.datefin;
        formState.prestataire_page = data.id;
        formState.confirme=true;
        formState.etape="pro";
        formState.presta_choisies = JSON.stringify(prestationsSelectionnees); 
        }
        
    }, [selectedDate]);


    useEffect(() => {
        if (nrdv) {
            openAlert("Le rendez-vous a été enregistré avec succès", true);
            reloadFeed(true);
            closeModal();
        }
        else if (nerror) {
            openAlert("Une erreur s'est produite lors de l'enregistrement du rendez-vous. Veuillez réessayer plus tard.", false);
        }
    }, [nrdv, nerror]);

    const reduirPrestationSelectionnee = (idPrestationASupprimer) => {

        const index = prestationsSelectionnees.findIndex(prestation => prestation.id_prestation === idPrestationASupprimer);

        if (index !== -1) {
            // Copie de l'état actuel pour éviter la mutation directe
            const nouvelleListe = [...prestationsSelectionnees];


            nouvelleListe.splice(index, 1);
            setPrestationsSelectionnees(nouvelleListe);
            if (nouvelleListe.length < 1) {
                setAddPresta(false);
            }

        }


    };


    const ajouterPrestationSelectionnee = (prestationAjoutee) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        // Créer un nouveau tableau à partir de l'état précédent
        const nouvelleListe = [...prestationsSelectionnees];

        // Si la prestation n'existe pas, l'ajouter avec une quantité initialisée à 1
        nouvelleListe.push({ ...prestationAjoutee, quantite: 1 });


        setAddPresta(false);
        setPrestationsSelectionnees(nouvelleListe);
    };

    return (
        <div className='proEvent'>
            <div className='formheader'>
                <button class="btn-base " onClick={() => closeModal()}><svg
                    viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.36555 0.0634767L6.31252 1.11651L6.31239 1.11637L3.47689 3.95187L3.47685 3.95183L0.276298 7.15238H0.218262L0.24728 7.1814L0.218295 7.21039H0.276266L3.07575 10.0099L3.07572 10.0099L4.5296 11.4638H4.52993L5.91158 12.8454L5.91154 12.8455L7.36541 14.2993L10.9745 14.2993L8.11701 11.4418L8.11705 11.4418L6.66317 9.98791H6.66284L5.28122 8.60629L5.28125 8.60626L3.85639 7.1814L4.7083 6.32949L4.70834 6.32953L7.71597 3.3219L7.7161 3.32203L10.9747 0.0634766L7.36555 0.0634767Z" fill="#006963"></path></svg>
                    <p>Retour</p>
                </button>
            </div>
            <div className="prestations">
                <h4>Prestations Choisies</h4>
                {prestationsSelectionnees.length < 1 && <p className="desc">Aucune prestation sélectionnée, veuillez choisir au moins une prestation.</p>}
                <ul>
                    {prestationsSelectionnees?.map((item) => (
                        <div>

                            <div className="presta select borderpro" key={item.id_prestation} >
                                <div className="nom"> <p>{item.titre}</p></div>
                                <p className="temps">{item.temps + " min"}</p>
                                <span className="verti" />
                                <p className="prix">{(item.prix * item.quantite) + "€"}</p>
                                {currentStep === 0 &&
                                    <button class="btn-base danger" onClick={() => reduirPrestationSelectionnee(item.id_prestation)}><p>Supprimer</p>
                                    </button>}
                            </div>
                        </div>
                    ))}
                </ul>
                <div className='addd'>
                    <button className={addPresta ? "btn-base open" : "btn-base"} onClick={() => addPresta ? setAddPresta(false) : setAddPresta(true)}>
                        <p>Ajouter un service</p>
                        <svg viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3497e-07 3.99334L1.05303 5.04637L1.0529 5.0465L3.8884 7.882L3.88836 7.88204L7.08891 11.0826L7.08891 11.1406L7.11793 11.1116L7.14691 11.1406L7.14691 11.0826L9.94639 8.28314L9.94642 8.28317L11.4003 6.82929L11.4003 6.82896L12.782 5.44731L12.782 5.44735L14.2359 3.99347L14.2359 0.384364L11.3784 3.24188L11.3783 3.24184L9.92444 4.69571L9.92444 4.69605L8.54281 6.07767L8.54279 6.07764L7.11793 7.5025L6.26602 6.65059L6.26605 6.65055L3.25842 3.64292L3.25855 3.64278L1.28269e-07 0.384229L2.3497e-07 3.99334Z" fill="#006963" />
                        </svg>
                    </button>
                    <ul className={addPresta ? "add open" : "add"}>
                        { !loading && !nloading ? prestas.map((item) => (
                            <div>
                                {item.type === "titre" ?
                                    <div className="titre" key={item.id_prestation}><p className='desc'>{item.titre}</p>
                                    </div>
                                    :
                                    <div className="presta borderpro" key={item.id_prestation} onClick={() => ajouterPrestationSelectionnee(item)}>
                                        <p className="nom">{item.titre}</p>
                                        <p className="temps">{item.temps + " min"}</p>
                                        <span className="verti" />
                                        <p className="prix">{item.prix + "€"}</p>
                                        <button class="btn-front"><p>Reserver</p>
                                            <svg viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.57217 11.4584L4.34017 10.6273L4.34019 10.6273L5.64057 9.22001L5.64063 9.22001L6.70122 8.07226L6.70119 8.07224L6.7171 8.05502L6.71705 8.05502L8.74258 5.86304L8.78719 5.86128L8.764 5.83986L8.78577 5.8163L8.74044 5.81809L6.54896 3.79303L6.54901 3.79303L4.29305 1.70839L4.29321 1.70822L3.14557 0.647732L0.404774 0.755908L2.64338 2.82451L2.64297 2.82453L5.2151 5.20133L5.21512 5.20131L6.02321 5.94803L4.98423 7.07239L4.98429 7.07243L3.97636 8.1632L3.9763 8.1632L3.20814 8.99449L3.20812 8.99448L0.831372 11.5665L3.57217 11.4584Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                } </div>
                        )) :
                        <Loader></Loader>}
                    </ul>
                </div>
            </div>
            <div className={prestationsSelectionnees.length > 0 ? "" : "inactive"}>
            {data && <DateSelect prestatairepage={data.id} horaires={dispos} interval={interval} dureeRdv={dureeRdv} result={(e) => setSelectedDate(e)} edit={currentStep === 0 ? true : false} trajet={interval}>
            </DateSelect>}

            </div>
            
            <span></span>
            <h4>Informations du client </h4>
            {selectedDate && prestationsSelectionnees.length > 0 ?
                <div className='form'>


                    <AddressAuto err={errors.adresse ? errors.adresse : false} onAddressSelect={(address) => setFormState({ ...formState, adresse: address.value })} newSelect={formState.adresse} />
                    <div className="form-group line">
                        <label className='desc' htmlFor="prenom">Prénom</label>
                        <input
                            type="text"
                            className="inputs"
                            name="prenom"
                            value={formState.prenom}
                            onChange={handleInputChange}
                            placeholder="Prénom"
                        />
                        {errors.prenom && <p className="error">{errors.prenom}</p>}
                    </div>
                    <div className="form-group line">
                        <label className='desc' htmlFor="nom">Nom</label>
                        <input
                            type="text"
                            className="inputs"
                            name="nom"
                            value={formState.nom}
                            onChange={handleInputChange}
                            placeholder="Nom"
                        />
                        {errors.nom && <p className="error">{errors.nom}</p>}
                    </div>
                    <div className="form-group line">
                        <label className='desc' htmlFor="email">Adresse email</label>
                        <input
                            type="email"
                            className="inputs"
                            name="email"
                            value={formState.email}
                            onChange={handleInputChange}
                            placeholder="Email"
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                    <div className="form-group line">
                        <label className='desc' htmlFor="telephone">Numéro de téléphone</label>
                        <input
                            type="tel"
                            className="inputs"
                            name="telephone"
                            value={formState.telephone}
                            onChange={handleInputChange}
                            placeholder="Numéro de téléphone"
                        />
                        {errors.telephone && <p className="error">{errors.telephone}</p>}
                    </div>
                   
                </div> : null}
                <span></span>
                <div className="form-group">
                        <btn class={selectedDate && prestationsSelectionnees.length > 0 ? "btn-front spe line" : "btn-front line spe inactive"} onClick={handleSubmit}><span></span><p> {currentStep === 1 ? "Confirmer le rendez-vous" : "Continuer"}</p><div class="icon"> {loading ? <Loader type="button" ></Loader> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.8795 15.2253L4.96384 14.1724L4.9639 14.1724L7.88392 11.3369L7.88395 11.3369L11.1798 8.13634L11.2394 8.13634L11.2096 8.10744L11.2396 8.07835L11.1796 8.07835L8.29688 5.27897L8.29701 5.27885L6.79983 3.82497L6.79933 3.82497L5.37661 2.4434L5.37675 2.44328L3.87956 0.989401L0.162947 0.9894L3.10546 3.8468L3.10533 3.84693L4.60252 5.30081L4.60301 5.30081L6.02569 6.68233L6.02556 6.68246L7.49299 8.10744L6.61576 8.9593L6.61573 8.95927L3.51856 11.9669L3.5185 11.9668L0.162881 15.2253L3.8795 15.2253Z" fill="#006963"></path></svg>}</div></btn>
                    </div>
        </div>
    );
};

export default ProEvent;