import React, { useState, useEffect } from 'react';
import fr from 'date-fns/locale/fr';
import { registerLocale } from 'react-datepicker';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { format, parseISO, set } from 'date-fns';
import Facture from './Facture';
import VisibilitySensor from 'react-visibility-sensor';
import { useModal } from '../contexts/ModalContext';
import Loader from './Loader';
import { id } from 'date-fns/locale';

registerLocale('fr', fr);


const RdvDisp = ({ type, iopen, idrdv, onLoaded, onVisible, bRdv }) => {
    const [sendSubmitRdv, setSendSubmitRdv] = useState(false);
    const confirmbody = { data: { confirme: 'true' } };
    const terminbody = { data: { etape: 'termine' } };
    const [sendConfirm, setSendConfirm] = useState(false);

    const [sendTermine, setSendTermine] = useState(false);
    const { data: rdvsdata, loading: rdvsloading, error } = useFetch(`/rdvs/${idrdv}`, 'GET', null, true, sendSubmitRdv);
    const { data: nConfirm, loading: lConfirm, error: eComfirm } = useFetch(`/rdvs/${idrdv}`, 'PUT', confirmbody, true, sendConfirm);
    const { data: nTermine, loading: lTermine, error: eTermine } = useFetch(`/rdvs/${idrdv}`, 'PUT', terminbody, true, sendTermine);
    let className = "rdvdisp " + type;
    const [open, setOpen] = useState(iopen);

    const [rdv, setRdv] = useState(null);
    const [statut, setStatut] = useState(true);
    const [prestationsDuRdv, setPrestationsDuRdv] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeVisible, setActiveVisible] = useState(false);
    const { openModalWrdv, reload, modalnContent } = useModal();
    const [year, setYear] = useState(null);
    // Créer une nouvelle instance de Date pour la date actuelle
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Réinitialiser l'heure pour la comparaison de date uniquement

    useEffect(() => {
        if (idrdv) { 
            setSendSubmitRdv(true);
        }
        else if (bRdv) { 
            setRdv(bRdv);
        }
    }, [idrdv, bRdv]);



    useEffect(() => {
        if (rdvsdata && rdvsdata && rdvsdata.attributes) {
            // Adaptez cette partie en fonction de la structure de vos données
            const rdvData = {
                ...rdvsdata.attributes,
                id: rdvsdata.id,
                debut: format(parseISO(rdvsdata.attributes.date), 'HH:mm', { locale: fr }),
                fin: format(parseISO(rdvsdata.attributes.datefin), 'HH:mm', { locale: fr }),
                dateForm: format(parseISO(rdvsdata.attributes.date), 'dd/MM/yyyy', { locale: fr }),
            }; 
            const date = new Date(rdvsdata.attributes.date).getFullYear();
            setYear(date);
            setStatut(rdvsdata.attributes.etape);
            setRdv(rdvData);
            onLoaded(rdvsdata.id); // Vous pouvez passer des informations supplémentaires si nécessaire
            setSendSubmitRdv(false);
        }

    }, [rdvsdata]);

    useEffect(() => {
        if (nConfirm && nConfirm.date) {
            // Adaptez cette partie en fonction de la structure de vos données
            const rdvData = {
                ...nConfirm.attributes,
                id: nConfirm.id,
                debut: format(parseISO(nConfirm.date), 'HH:mm', { locale: fr }),
                fin: format(parseISO(nConfirm.datefin), 'HH:mm', { locale: fr }),
                dateForm: format(parseISO(nConfirm.date), 'dd/MM/yyyy', { locale: fr }),
            };
            setRdv(rdvData);
            onLoaded(rdvsdata.id); // Vous pouvez passer des informations supplémentaires si nécessaire
            setSendSubmitRdv(false);
            setSendConfirm(false);
            reload(nConfirm.id, 'confirm');
        }
        if (nTermine && rdvsdata) reload(rdvsdata.id, 'termine');

    }, [nConfirm, nTermine]);

    useEffect(() => {
        // Assurez-vous que rdvData.presta_choisies est une chaîne de caractères valide
        if (rdv && rdv.presta_choisies) {
          
            try {
                const prestationsParsed = JSON.parse(rdv.presta_choisies);
                setPrestationsDuRdv(prestationsParsed);
            } catch (error) {
                console.error("Erreur lors de la conversion des prestations choisies :", error);
                setPrestationsDuRdv([]); // Réinitialiser les prestations en cas d'erreur
            }
        }

    }, [rdv]);



    const onChange = (isVisible) => {

        if (!activeVisible) {
            setActiveVisible(true)
        }
        else {
            if (isVisible) {
                onVisible(rdv.id);
            }
        }
    };

    const handlConfirm = () => {
        setSendConfirm(true);
    }

    const handlTermine = () => {
        setSendTermine(true);
    }

    useEffect(() => {
        if (!modalnContent) return;

        const { id, action } = modalnContent;
        if (action === 'confirm' && rdv && id === rdv.id) {
            const updatedRdv = {
                ...rdv,
                confirme: true, // Mettre à jour le champ `confirme` comme vrai
                // Vous pouvez ajouter ici d'autres champs de `nConfirm` si nécessaire
            };
            setRdv(updatedRdv);
        }
        else if (action === 'termine' && rdv && id === rdv.id) {
            setStatut('termine')
        }
    }, [modalnContent]);

    if (rdvsloading) return <div>Chargement...</div>;
    if (!rdv) return <div>Aucun rendez-vous trouvé</div>;


    return (
        <div className={rdv && rdv.confirme ? className : className + " aconfirm"}>
            {rdv && idrdv && rdv.id !== idrdv ?
                <div>
                    <p className='nouv'></p>
                    <div className={open ? "contentr open" : "contentr"}>
                        <div className='justify'>
                            <div className='loading'>
                                <span />
                            </div>
                            <button className='btn-base details' type="button" onClick={() => open ? setOpen(false) : setOpen(true)} ><p>Détails</p>
                                <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.704102 2.21739L1.17013 2.68341L1.16972 2.68382L2.67712 4.19122L2.67753 4.19081L3.83901 5.35229L3.83901 5.37854L3.85213 5.36542L3.86467 5.37796L3.86467 5.35288L5.09301 4.12454L5.1026 4.11495L5.10271 4.11506L5.74567 3.47209L5.74567 3.47188L6.53399 2.68355L6.53379 2.68336L6.99961 2.21754L6.99961 0.621478L5.55861 2.06247L5.55881 2.06267L5.09301 2.52847L5.09301 2.52869L4.48206 3.13963L4.48196 3.13952L3.85213 3.76935L3.22165 3.13887L3.22125 3.13928L2.14473 2.06276L2.14514 2.06236L0.704102 0.621321L0.704102 2.21739Z" fill="#006963" />
                                </svg>

                            </button>
                        </div>
                        <div className='infos'>
                            <div className='name'>
                                <div className='grp clientn'>
                                    <p className='desc'>Prénom:</p>
                                    <div className='loading'>
                                        <span />
                                    </div>
                                </div>
                                <span className='verti'></span>
                                <div className='grp clientn '>
                                    <p className='desc'>Nom:</p>
                                    <div className='loading'>
                                        <span />
                                    </div>
                                </div>
                                <div className='grp presta'>
                                    <p className='desc'>Prestataire:</p>
                                    <div className='loading'>
                                        <span />
                                    </div>
                                </div>
                            </div>
                            <div className='loading'>
                                <span />
                            </div>
                            <span className='op' />
                            <div className='grp op'>
                                <p className='desc'>Adresse:</p>
                                <div className='loading'>
                                    <span />
                                </div>
                            </div>
                            <span className='op' />
                            <div className='grp op'>
                                <p className='desc'>Email:</p>
                                <div className='loading'>
                                    <span />
                                </div>
                            </div>
                            <div className='grp op'>
                                <p className='desc'>Téléphone:</p>
                                <div className='loading'>
                                    <span />
                                </div>
                            </div>
                        </div>
                        <div className="actions op clientn">
                            <div className='loading'>
                                <span />
                            </div>
                        </div>
                    </div>
                </div>

                :
                rdv.debut && (
                    <VisibilitySensor onChange={onChange}>
                        <div>

                            <p className='nouv'>{type === 'client' ? 'En attente de confirmation' : 'Nouveau'}</p>
                            <div className={open ? "contentr open" : "contentr"}>
                                <div className='justify'>
                                    <p>  {rdv.debut} - {rdv.fin} , {rdv.dateForm} </p>
                                    <button className='btn-base detailsbtn' onClick={() => open ? setOpen(false) : setOpen(true)} ><p>Détails</p>
                                        <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.704102 2.21739L1.17013 2.68341L1.16972 2.68382L2.67712 4.19122L2.67753 4.19081L3.83901 5.35229L3.83901 5.37854L3.85213 5.36542L3.86467 5.37796L3.86467 5.35288L5.09301 4.12454L5.1026 4.11495L5.10271 4.11506L5.74567 3.47209L5.74567 3.47188L6.53399 2.68355L6.53379 2.68336L6.99961 2.21754L6.99961 0.621478L5.55861 2.06247L5.55881 2.06267L5.09301 2.52847L5.09301 2.52869L4.48206 3.13963L4.48196 3.13952L3.85213 3.76935L3.22165 3.13887L3.22125 3.13928L2.14473 2.06276L2.14514 2.06236L0.704102 0.621321L0.704102 2.21739Z" fill="#006963" />
                                        </svg>

                                    </button>
                                </div>
                                <div className='infos'>
                                    <div className='grp presta'>
                                        <p className='desc'>Prestataire:</p>
                                        <a className='btn-base' href={`/prestataire/${rdv.idPagePro}`}><p>{rdv.nomPro}</p></a>
                                    </div>
                                    <div className='name'>
                                        <div className='grp clientn'>
                                            <p className='desc'>Prénom:</p>
                                            <p>{rdv.prenom}</p>
                                        </div>
                                        <span className='verti'></span>
                                        <div className='grp clientn '>
                                            <p className='desc'>Nom:</p>
                                            <p>{rdv.nom}</p>
                                        </div>
                                    </div>
                                    {prestationsDuRdv && prestationsDuRdv.map(prestation => (

                                        <div key={prestation.id_prestation} className="prestar">
                                            <p>{prestation.titre}</p>
                                            <p className='strong'>{prestation.prix} €</p>

                                        </div>
                                    ))}
                                    <span className='op clientn' />
                                    <div className='grp op clientn'>
                                        <p className='desc'>Adresse:</p>
                                        <p className='strong'>{rdv.adresse}</p>
                                    </div>
                                    <span className='op clientn' />
                                    <div className='grp op clientn'>
                                        <p className='desc'>Email:</p>
                                        <p className='strong'>{rdv.email}</p>
                                    </div>
                                    <div className='grp op clientn'>
                                        <p className='desc'>Téléphone:</p>
                                        <p className='strong'>{rdv.telephone}</p>
                                    </div>
                                </div>
                                {rdvsloading || lConfirm || lTermine ?
                                    <Loader></Loader>
                                    :


                                    statut == 'planifie' || statut == "pro" ?
                                        <div className="actions">
                                          {type !== 'client' &&  <button onClick={() => openModalWrdv("cancelrdv", rdv)} className='btn-base danger'> <p>Annuler</p> </button> }
                                            {rdv.confirme ?
                                                null
                                                : type != 'client' && <a onClick={handlConfirm} className='btn-simple'> <p>Confirmer</p> </a>
                                            }
                                        </div>
                                        :
                                        statut == 'termine' || statut == "note" ?
                                            <div className="actions">
                                                {rdv.id && type !== 'note' ?

                                                    <Link className='btn-base' to={`/facture/${rdv.id}-${year}`} target="_blank"><p>Voir la Facture</p></Link>
                                                    : null
                                                }
                                                {type === 'client' && statut != "note" &&
                                                    <button className='btn-front' type='button' onClick={() => openModalWrdv("rate", rdv.id)} ><p>Noter la prestation</p></button>

                                                }
                                            </div>
                                            :
                                            <div className="actions">

                                                <div>
                                                    {rdv.id && type !== 'client' && statut != "pro" ?
                                                        <a className='btn-pro' onClick={handlTermine} ><p className='tac'>Rendez-vous terminé, <br></br> envoyer la facture.</p></a>
                                                        : null } 
                                                </div>
                                            </div> 
                                }
                            </div>
                        </div>
                    </VisibilitySensor>
                )
            }
        </div>

    );
};

export default RdvDisp;