import React, { useEffect } from 'react';
import { useModal } from '../contexts/ModalContext';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import RegisterPro from './RegisterPro';
import RdvCancel from './RdvCancel';
import Rate from './Rate';
import LostPassword from './LostPassword';
import PayStripe from './PayStripe';
import DeleteCompte from './DeleteCompte';
import AddComp from './AddComp';
import ProEvent from './ProEvent';
import RdvDisp from './RdvDisp';

const Modal = ({ active, children }) => {



    const { isModalOpen, modalContent, closeModal, rdvForModal,nEventData, } = useModal();

    useEffect
    (() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isModalOpen]);
 
    
    return (

        <div className={active || isModalOpen ? "modal open" : "modal closed"}>
            {active || isModalOpen ?
                <div className='modal-content'>
                    {isModalOpen && (
                        <>
                            {modalContent === 'login' && <LoginForm />}
                            {modalContent === 'cancelrdv' && rdvForModal && <RdvCancel rdv={rdvForModal} />}
                            {modalContent === 'register' && <RegisterForm />}
                            {modalContent === 'registerpro' && < RegisterPro />}
                            {modalContent === 'rate' && < Rate/>}
                            {modalContent === 'lost' && <LostPassword/>}
                            {modalContent === 'pay' && <PayStripe type={"update"} reload={()=>null}  />}
                            {modalContent === 'deletecompte' && <DeleteCompte/>}
                            {modalContent === 'addcomp' && <AddComp/>} 
                            {modalContent === 'rdv' &&( <div className='rdvmod'> <RdvDisp type="normal" iopen={true} idrdv={rdvForModal} onLoaded={() => null} onVisible={() => null} />           <button className="btn-pro scnd " type='button' onClick={closeModal}><p>Retour</p></button> </div> )}
                            {modalContent === 'nEvent' && <ProEvent jour={nEventData.jour} heureComplete={nEventData.heureComplete} m={nEventData.m} trajet={10} />}
                        </>
                    )}

                    {active && children}
                </div>
                : null}

        </div>
    );
};

export default Modal;

