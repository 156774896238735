import logo from './logo.svg';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import LeftMenu from './components/LeftMenu';
import Accueil from './pages/Front/Accueil';
import Agenda from './pages/Saas/Agenda';
import NotifPage from './components/NotifPage';
import Parametres from './pages/Saas/Agenda';
import Accueilpro from './pages/Saas/Accueilpro';
import './App.css';
import { useJsApiLoader } from '@react-google-maps/api';
import Activite from './pages/Saas/Activite';
import Professionnel from './pages/Front/Professionnel';
import Facture from './components/Facture';
import { AuthProvider } from './contexts/AuthContext';
import { AlertProvider } from './contexts/AlertContext';
import { MapsLoadProvider } from './contexts/MapLoadContext';
import { ModalProvider } from './contexts/ModalContext';
import Marketplace from './pages/Front/Marketplace';
import Modal from './components/Modal';
import RoutePro from './components/RoutePro';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import Settings from './pages/Saas/Settings';
import Compte from './pages/Front/Compte';
import Alert from './components/Alert';
import {Elements} from '@stripe/react-stripe-js';
import CookieConsent from './components/CookieConsent';
import {loadStripe} from '@stripe/stripe-js'; // Assurez-vous que le chemin d'importation est correct
import PdfView from './pages/Front/PdfView';
import FourOFour from './pages/Saas/FourOFour';



function HeaderSelector() {
  const location = useLocation();


  // Choisir l'en-tête en fonction de l'URL
  if (location.pathname === '/dashboard') {
    return <LeftMenu />;
  } else {
    return <Header />;
  }
}
 
function App() {
  const stripePromise = loadStripe( process.env.REACT_APP_STRIPE );

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "VOTRE_CLÉ_API",
  //   libraries: ["places", "maps"] // Assurez-vous d'inclure toutes les bibliothèques nécessaires ici
  // });

  // useEffect(() => {
  //   // Fonction de callback appelée lorsque la bibliothèque Google Maps est chargée
  //   window.initMap = () => {
  //     // La bibliothèque Google Maps est chargée et prête à être utilisée
  //   };

  //   // Création de la balise script pour charger Google Maps
  //   const script = document.createElement('script');
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB4cyH10yrG92SZSlOY9vNB3nKZByhvj_Q&libraries=places&callback=initMap`;
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     // Nettoyage en supprimant la balise script et la fonction de callback
  //     window.initMap = null;
  //     document.body.removeChild(script);
  //   };
  // }, []);


  return (

    <AuthProvider>
      
    <MapsLoadProvider >
    <AlertProvider>
      <ModalProvider>
      <Elements stripe={stripePromise}>
        <Router>
        <Alert />
        <Modal>


        <NotifPage />
        </Modal>
        <CookieConsent />
          <Routes>

            {/* Routes for the front */}
            <Route exact path="/" element={<Accueil />} />
            <Route exact path="/accueil" element={<Accueil />} />
            <Route exact path="/connexion" element={<Accueil />} />
            <Route exact path="/inscription" element={<Accueil />} />
            <Route exact path="/inscriptionpro" element={<Accueil />} />
            <Route exact path="prestataire/:id" element={<Professionnel />} />
            <Route exact path="/categorie/:element" element={<Marketplace />} />
            
            <Route exact path="/compte" element={<Compte />} />
            <Route exact path="/compte/evaluations/:id" element={<Compte />} />

            <Route path="/recherche/:ville/:searchQuery"  element={<Marketplace />}/>  

            <Route path="/recherche/:ville/"  element={<Marketplace />}/>  
            <Route path="/cgu" element={<PdfView />} />
            <Route path="/cgv" element={<PdfView />} />
            <Route path="/*" element={<FourOFour/>} />
            {/* Routes for the SaaS */}
              <Route path="/pro/accueil" element={ 
            <RoutePro> <Accueilpro /> </RoutePro>} />
              <Route path="/pro/agenda/:sousMenu" element={ 
            <RoutePro> <Agenda /> </RoutePro>} />
              <Route path="/pro/activite/:sousMenu" element={ 
            <RoutePro> <Activite /> </RoutePro>} />
            
            <Route path="/pro/professionnel/:id" element={ 
            <RoutePro> <Professionnel /> </RoutePro>} />
              <Route path="/pro/parametres/:sousMenu" element={ 
            <RoutePro> <Settings/> </RoutePro>} />
              <Route path="/facture/:idFacture" element={ 
            <RoutePro> <Facture /> </RoutePro>} />
            
          </Routes >

        </Router>
        </Elements>
      </ModalProvider>
      
      </AlertProvider>
      </MapsLoadProvider>
    </AuthProvider>
  );
}

export default App;
