import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const MapsApiContext = React.createContext();


export const MapsLoadProvider = ({ children }) =>{
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_TOKEN ,
        libraries: ["places", "maps"]// Ajoutez ceci
    })  

  return (
    <MapsApiContext.Provider value={isLoaded}>
      {children}
    </MapsApiContext.Provider>
    );
}

export default MapsApiContext;
