import React, { useState, useEffect } from 'react';
import { isValid, startOfWeek, addDays, format, getDay, parseISO, isBefore, isAfter, addMinutes, formatISO, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toZonedTime, format as formatTz } from 'date-fns-tz';
import useFetch from '../hooks/useFetch';
import Loader from './Loader';
import { useModal } from '../contexts/ModalContext';

const daysMapping = {
    '0': 'dim',
    '1': 'lun',
    '2': 'mar',
    '3': 'mer',
    '4': 'jeu',
    '5': 'ven',
    '6': 'sam',
};

const periodMapping = {
    am: 'matin',
    pm: 'après-midi',
    night: 'soir',
};

const timeZone = 'Europe/Paris'; // Fuseau horaire du client

function convertToClientTimezone(dateISO, timeZone) {
    const date = new Date(dateISO);
    const zonedDate = toZonedTime(date, timeZone);
    return zonedDate;
}

function isDateInRange(date, start, end) {
    const dateObj = new Date(date);
    const startDate = new Date(start);
    const endDate = new Date(end);
    return dateObj >= startDate && dateObj <= endDate;
}

function formatForClient(date, timeZone) {
    return formatTz(convertToClientTimezone(date, timeZone), 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
}

function ajusterDatesRendezVous(rendezVous) {
    const aujourdHuiDateISO = new Date().toISOString().split('T')[0]; // Obtient la date d'aujourd'hui en ISO et extrait la partie YYYY-MM-DD

    return rendezVous.map(rdv => {
        const heureDebut = new Date(rdv.debut);
        const heureFin = new Date(rdv.fin);

        // Combine la date d'aujourd'hui avec l'heure et les minutes des rendez-vous originaux
        const nouveauDebut = new Date(`${aujourdHuiDateISO}T${heureDebut.toISOString().split('T')[1]}`);
        const nouveauFin = new Date(`${aujourdHuiDateISO}T${heureFin.toISOString().split('T')[1]}`);

        // Convertir en fuseau horaire du client
        const debutClient = formatForClient(nouveauDebut.toISOString(), timeZone);
        const finClient = formatForClient(nouveauFin.toISOString(), timeZone);

        // Retourne le rendez-vous mis à jour avec les nouvelles dates en format ISO
        return {
            ...rdv,
            debut: debutClient,
            fin: finClient
        };
    });
}

function generateTimeSlots(debut, fin, rendezVousData, interval, dureee, trajet, jour) {
    const ntrajet = Math.ceil(trajet / 5) * 5;
    let slots = [];
    let startTime;
    let endTime;



    // Convert rendezVousData times to the client's timezone
    rendezVousData = rendezVousData.map(rdv => {
        const debutTime = convertToClientTimezone(rdv.debut, timeZone);
        const finTime = convertToClientTimezone(rdv.fin, timeZone);

        return {
            ...rdv,
            debut: debutTime,
            fin: finTime
        };
    });

    const aujourdHuiDateISO = rendezVousData.length > 0 ? rendezVousData[0].debut.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    startTime = new Date(`${aujourdHuiDateISO}T${debut}:00`);
    endTime = new Date(`${aujourdHuiDateISO}T${fin}:00`);



    while (isBefore(startTime, endTime)) {
        let endSlotTime = addMinutes(startTime, dureee);
        let slotIsFree = true;
        if (isAfter(endSlotTime, endTime)) {
            break; // Sortir de la boucle while si le rendez-vous dépasse endTime
        }
        for (let rdv of rendezVousData) {
            if (isBefore(startTime, rdv.fin) && isBefore(rdv.debut, endSlotTime)) {
                slotIsFree = false;
                if (isAfter(rdv.fin, startTime)) {

                    startTime = addMinutes(rdv.fin, ntrajet); // Mettre à jour startTime pour reprendre après la fin du rdv
                }
                break; // Sortir de la boucle car le créneau actuel n'est pas libre
            }
        }

        if (slotIsFree) {
            slots.push(format(startTime, 'HH:mm')); // Ajouter le créneau libre formaté
            startTime = addMinutes(startTime, interval);; // Passer au prochain créneau
        }
    }

    return slots;
}

function formatDateToLocalISO(date) {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toISOString().split('T')[0];
}

const groupScheduleByDay = (data, rendezVous, specificDay, selectedDayShortFormat, interval, dureeRdv, trajet) => {
    const specificDayFormatted = formatDateToLocalISO(specificDay);

    const filteredRendezVous = rendezVous.filter(rdv => {
        const rdvDate = new Date(rdv.debut);
        const specificDate = new Date(`${specificDayFormatted}T00:00`);

        return rdvDate.getFullYear() === specificDate.getFullYear() &&
            rdvDate.getMonth() === specificDate.getMonth() &&
            rdvDate.getDate() === specificDate.getDate();
    });

    const filteredData = data.filter(item =>
        isDateInRange(specificDayFormatted, item.start, item.end)
    );

    const groupedData = filteredData.reduce((acc, current) => {
        const detailsForDay = current.details.filter(detail => detail.jour === selectedDayShortFormat);
        if (!detailsForDay || detailsForDay.length === 0) return acc;

        detailsForDay.forEach(detail => {
            const { jour, debut, fin, periode } = detail;

            if (!jour) return;

            acc[jour] = acc[jour] || { am: [], pm: [], night: [] };
            acc[jour][periode] = acc[jour][periode].concat(generateTimeSlots(debut, fin, filteredRendezVous, interval, dureeRdv, trajet, jour));
        });

        return acc;
    }, {});

    return groupedData;
};



function convertDateWithTime(dateInfo) {
    if (!dateInfo) {
        return new Date().toISOString();
    }

    const { jour, heureComplete, m } = dateInfo;
    if (!jour || !heureComplete || !m) {
        return new Date().toISOString();
    }

    const parts = jour.split(' ').slice(1);
    const day = parseInt(parts[0], 10);
    const year = parseInt(parts[1], 10);
    const timeParts = heureComplete.split(':');
    const hour = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const month = parseInt(m, 10) - 1;

    const date = new Date(Date.UTC(year, month, day, hour, minutes));
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);

    return date.toISOString();
}

function checkAvailabilityAfterDate(data, lastDate) {
    return data.some(item => {
        const endDate = new Date(item.end);
        return isAfter(endDate, lastDate);
    });
}

const DateSelect = ({ horaires, interval, dureeRdv, result, edit, prestatairepage, trajet }) => {
    const { nEventData } = useModal();
    const today = new Date();
    const [dataSend, setDataSend] = useState(true);
    const [rendezVous, setRendezVous] = useState([]);
    const [loading, setLoading] = useState(true);
    const yesterday = addDays(today, -1);
    const [selectedDate, setSelectedDate] = useState(today);
    const [selectedTime, setSelectedTime] = useState(null);
    const [weekDaysInfo, setWeekDaysInfo] = useState([]);
    const [isCurrentOrFutureWeek, setIsCurrentOrFutureWeek] = useState(true);
    const [dateDisp, setDateDisp] = useState("");
    const [firstDate, setFirstDate] = useState(null);
    const [actualfirstDate, setActualfirstDate] = useState(null);
    const [nextAllowed, setNextAllowed] = useState(true);
    const [startDay, setStartDay] = useState(nEventData && nEventData.jour && nEventData.heureComplete && nEventData.m ? convertDateWithTime(nEventData) : today.toISOString());
    const endDayCalc = new Date();
    endDayCalc.setDate(today.getDate() + 15);
    const [endDay, setEndDay] = useState(endDayCalc.toISOString());
    const { data: rdvsdata, loading: rdvsloading } = useFetch(`/rdvs?startDate=${startDay}&endDate=${endDay}&idPage=${prestatairepage}`, 'GET', null, false, dataSend);


    const updateWeekDaysInfo = (date) => {
        let currentDate = date; // Limite le nombre de tentatives pour éviter une boucle infinie


        const startOfSelectedWeek = startOfWeek(currentDate, { weekStartsOn: 1 });

        const newWeekDaysInfo = Array.from({ length: 7 }).map((_, index) => {
            const day = addDays(startOfSelectedWeek, index);
            if (!isValid(day)) {
                return null;
            }

            const dayShortFormat = format(day, 'EEE', { locale: fr }).toLowerCase().replace('.', '');
            const groupedSchedule = groupScheduleByDay(horaires, rendezVous, day, dayShortFormat, interval, dureeRdv, trajet);
            const hasAvailability = groupedSchedule[dayShortFormat] &&
                Object.values(groupedSchedule[dayShortFormat]).some(period => period.length > 0) &&
                isAfter(day, today);

            return {
                formatted: format(day, 'dd MMM', { locale: fr }).replace('.', ''),
                sday: format(day, 'eee', { locale: fr }),
                short: dayShortFormat,
                date: day,
                groupSchedule: groupedSchedule,
                hasAvailability: hasAvailability
            };
        }).filter(dayInfo => dayInfo !== null);

        const availableDays = newWeekDaysInfo.filter(dayInfo => dayInfo.hasAvailability); 

        setNextAllowed(checkAvailabilityAfterDate(horaires, addDays(startOfSelectedWeek, 7)));
        if (availableDays.length > 0) {
        

            setWeekDaysInfo(newWeekDaysInfo);
            const firstAvailableDay = availableDays[0].date;
            setActualfirstDate(firstAvailableDay);
            if (firstAvailableDay) {
                setSelectedDate(null);
                result(null);
                if (!firstDate) {
                    setFirstDate(firstAvailableDay);
                } else {
                    isSameDay(firstDate, firstAvailableDay) ? setIsCurrentOrFutureWeek(true) : setIsCurrentOrFutureWeek(false);
                }
            }
            return; // Termine la fonction si des disponibilités sont trouvées
        }
        setActualfirstDate(startOfSelectedWeek);

        // Remplir avec des jours vides si aucune disponibilité n'est trouvée
        const emptyWeekDaysInfo = Array.from({ length: 7 }).map((_, index) => {
            const day = addDays(startOfSelectedWeek, index);
            return {
                formatted: format(day, 'dd MMM', { locale: fr }).replace('.', ''),
                sday: format(day, 'eee', { locale: fr }),
                short: format(day, 'EEE', { locale: fr }).toLowerCase().replace('.', ''),
                date: day,
                groupSchedule: {},
                hasAvailability: false
            };
        });

        setWeekDaysInfo(emptyWeekDaysInfo);
        currentDate = addDays(startOfSelectedWeek, 7);


    };

    useEffect(() => {
        if (rdvsdata) {
            updateWeekDaysInfo(selectedDate ? selectedDate : actualfirstDate);
        }
    }, [dureeRdv, rendezVous]);

    useEffect(() => {
        if (rdvsdata) {
            const formattedRdvs = rdvsdata.map(rdv => ({
                id: rdv.id,
                debut: formatForClient(rdv.date, timeZone),
                fin: formatForClient(rdv.datefin, timeZone),
            }));

            setRendezVous(formattedRdvs);
            setDataSend(false);
        }
    }, [rdvsdata]);

    const changeWeek = (offset) => {
        const weekDaysDiv = document.querySelector('.week-days');
        if (offset > 0) {
            weekDaysDiv.scrollTo({ left: 0, behavior: 'smooth' });
        } else {
            weekDaysDiv.scrollTo({ left: weekDaysDiv.scrollWidth, behavior: 'smooth' });
        }

        const newSelectedDate = addDays(actualfirstDate, 7 * offset);
        const startOfNewWeek = startOfWeek(newSelectedDate, { weekStartsOn: 1 });
        const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

        if (startOfNewWeek >= startOfCurrentWeek) {
            setSelectedDate(newSelectedDate); setSelectedTime(null); result(null);
            if (rdvsdata) updateWeekDaysInfo(newSelectedDate);
        }
    };

    const dayInfo = selectedDate && isValid(selectedDate) ? weekDaysInfo.find(day => isValid(day.date) && format(day.date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')) : null;
    const groupedScheduleForSpecificDay = dayInfo ? dayInfo.groupSchedule : null;

    const handleDateSelection = (day) => {
        setSelectedDate(day.date);
        result(null);
        setSelectedTime(null);
        setDateDisp(` `);
    };

    const handleTimeSelection = (day, time) => {
        setSelectedTime({ day: day.formatted, time });

        const formattedDate = format(selectedDate, "EEEE dd MMMM", { locale: fr });
        const startTime = parseISO(`${format(selectedDate, 'yyyy-MM-dd')}T${time}:00`);
        const endTime = addMinutes(startTime, dureeRdv);
        const formattedStartTime = format(startTime, "HH'h'mm", { locale: fr });
        const formattedEndTime = format(endTime, "HH'h'mm", { locale: fr });

        setDateDisp(` ${formattedDate} - ${formattedStartTime} - ${formattedEndTime}`);
        const formattedStartDate = formatISO(startTime);
        const formattedEndDate = formatISO(endTime);

        result({
            date: formattedStartDate,
            datefin: formattedEndDate,
        });
    };

    return (
        <div className="dateSelect">
            <h4>Date et heure</h4>
            {!rdvsdata ? <Loader /> :
                <div className="dateSelect">
                    <div className="title">
                        <span className='verti'></span>
                        <p className='desc'>Sélectionné: </p>
                        {selectedDate &&
                            <p>{dateDisp}</p>}
                    </div>
                    {edit &&
                        <div className="week-days">
                            <button className={`btn-base ${isCurrentOrFutureWeek ? 'disabled' : ''}`} onClick={() => !isCurrentOrFutureWeek && changeWeek(-1)}>
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.36555 0.0634767L6.31252 1.11651L6.31239 1.11637L3.47689 3.95187L3.47685 3.95183L0.276298 7.15238H0.218262L0.24728 7.1814L0.218295 7.21039H0.276266L3.07575 10.0099L3.07572 10.0099L4.5296 11.4638H4.52993L5.91158 12.8454L5.91154 12.8455L7.36541 14.2993L10.9745 14.2993L8.11701 11.4418L8.11705 11.4418L6.66317 9.98791H6.66284L5.28122 8.60629L5.28125 8.60626L3.85639 7.1814L4.7083 6.32949L4.70834 6.32953L7.71597 3.3219L7.7161 3.32203L10.9747 0.0634766L7.36555 0.0634767Z" fill="#006963" />
                                </svg>
                            </button>
                            {weekDaysInfo.map((day) => (
                                <div key={day.formatted}
                                    className={`${selectedDate && isValid(day.date) && format(selectedDate, 'yyyy-MM-dd') === format(day.date, 'yyyy-MM-dd') ? 'day active' : 'day'} 
                                        ${!day.hasAvailability || isBefore(day.date, today) ? 'ferme' : ''}`}
                                    onClick={() => day.hasAvailability && !isBefore(day.date, today) && handleDateSelection(day)}>
                                    <p>{day.sday}</p>
                                    <h5>{day.formatted}</h5>
                                </div>
                            ))}
                            <button className={`btn-base ${!nextAllowed ? 'disabled' : ''}`} onClick={() => nextAllowed && changeWeek(1)}>
                                <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.82732 14.2993L4.88035 13.2463L4.88049 13.2464L7.71598 10.4109L7.71602 10.411L10.9166 7.21041L10.9746 7.21041L10.9456 7.18139L10.9746 7.1524L10.9166 7.1524L8.11712 4.35292L8.11715 4.35289L6.66327 2.89902L6.66294 2.89902L5.28129 1.51736L5.28133 1.51732L3.82746 0.0634448L0.218347 0.063446L3.07586 2.92096L3.07582 2.921L4.5297 4.37488L4.53003 4.37488L5.91165 5.7565L5.91162 5.75653L7.33648 7.18139L6.48457 8.0333L6.48453 8.03326L3.4769 11.0409L3.47677 11.0408L0.218213 14.2993L3.82732 14.2993Z" fill="#006963" />
                                </svg>
                            </button>
                        </div>}
                    {edit && selectedDate && groupedScheduleForSpecificDay && groupedScheduleForSpecificDay[daysMapping[getDay(selectedDate)]] && rdvsdata && (
                        <div className="time-slots">
                            {Object.entries(groupedScheduleForSpecificDay[daysMapping[getDay(selectedDate)]]).map(([periode, times]) => (
                                times.length > 0 && (
                                    <React.Fragment key={periode} >
                                        <div className="plages">
                                            <h5>{periodMapping[periode] ? periodMapping[periode].charAt(0).toUpperCase() + periodMapping[periode].slice(1) : periode} : </h5>
                                            <div className='listh'>
                                                {times.map((time, index) => (
                                                    <button className={selectedTime?.time === time ? 'btn-h active' : 'btn-h'} key={index} onClick={() => handleTimeSelection(selectedDate, time)}><p>{time}</p> </button>
                                                ))}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            ))}
                        </div>
                    )}
                </div>}
        </div>
    );
};

export default DateSelect;
