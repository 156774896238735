import React from 'react';

const Pricing = () => {
    return (
        <div className="offer">
            <div class="offer-header">

                <h3>Offre Bien-être</h3> 
                <p className="price">19,90€ /mois (ttc)<span className="per-month"> <br /></span></p>

            </div>

            <ul class="offer-details">
                <li>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1032 1.81696C11.47 1.07371 12.5298 1.07371 12.8967 1.81696L15.294 6.67443C15.4396 6.96957 15.7212 7.17414 16.0469 7.22147L21.4074 8.0004C22.2276 8.11958 22.5552 9.12755 21.9616 9.70609L18.0827 13.4871C17.847 13.7168 17.7395 14.0478 17.7951 14.3722L18.7108 19.7111C18.8509 20.528 17.9935 21.151 17.2599 20.7653L12.4653 18.2446C12.1739 18.0915 11.8259 18.0915 11.5346 18.2446L6.73997 20.7653C6.00634 21.151 5.14891 20.528 5.28902 19.7111L6.2047 14.3722C6.26034 14.0478 6.15279 13.7168 5.91711 13.4871L2.03819 9.70609C1.44467 9.12756 1.77218 8.11958 2.5924 8.0004L7.95294 7.22147C8.27865 7.17414 8.56021 6.96957 8.70588 6.67443L11.1032 1.81696Z" fill="url(#paint0_linear_757_3174)" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1146 6.76294L12.7173 1.90547C12.4239 1.31087 11.576 1.31087 11.2825 1.90547L8.88522 6.76294C8.71043 7.11711 8.37255 7.36259 7.9817 7.41939L2.62116 8.19832C1.96499 8.29367 1.70298 9.10005 2.17779 9.56288L6.05671 13.3439C6.33953 13.6196 6.46859 14.0168 6.40183 14.406L5.48614 19.7449C5.37405 20.3984 6.06 20.8968 6.6469 20.5883L11.4415 18.0676C11.7911 17.8838 12.2087 17.8838 12.5583 18.0676L17.3529 20.5883C17.9398 20.8968 18.6258 20.3984 18.5137 19.7449L17.598 14.406C17.5312 14.0168 17.6603 13.6196 17.9431 13.3439L21.822 9.56287C22.2969 9.10005 22.0348 8.29367 21.3787 8.19832L16.0181 7.41939C15.6273 7.36259 15.2894 7.11711 15.1146 6.76294ZM12.8967 1.81696C12.5298 1.07371 11.47 1.07371 11.1032 1.81696L8.70588 6.67443C8.56021 6.96957 8.27865 7.17414 7.95294 7.22147L2.5924 8.0004C1.77218 8.11958 1.44467 9.12756 2.03819 9.70609L5.91711 13.4871C6.15279 13.7168 6.26034 14.0478 6.2047 14.3722L5.28902 19.7111C5.14891 20.528 6.00634 21.151 6.73997 20.7653L11.5346 18.2446C11.8259 18.0915 12.1739 18.0915 12.4653 18.2446L17.2599 20.7653C17.9935 21.151 18.8509 20.528 18.7108 19.7111L17.7951 14.3722C17.7395 14.0478 17.847 13.7168 18.0827 13.4871L21.9616 9.70609C22.5552 9.12755 22.2276 8.11958 21.4074 8.0004L16.0469 7.22147C15.7212 7.17414 15.4396 6.96957 15.294 6.67443L12.8967 1.81696Z" fill="#25AAA5" />
                        <defs>
                            <linearGradient id="paint0_linear_757_3174" x1="11.9999" y1="-3.05168e-05" x2="11.9999" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C5FFFE" />
                                <stop offset="1" stopColor="#25AAA5" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        <strong>Agenda Personnalisable :</strong> Gérez votre emploi du temps avec un agenda en ligne et configurez vos disponibilités. 
                    </p>
                </li>
                <li>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1032 1.81696C11.47 1.07371 12.5298 1.07371 12.8967 1.81696L15.294 6.67443C15.4396 6.96957 15.7212 7.17414 16.0469 7.22147L21.4074 8.0004C22.2276 8.11958 22.5552 9.12755 21.9616 9.70609L18.0827 13.4871C17.847 13.7168 17.7395 14.0478 17.7951 14.3722L18.7108 19.7111C18.8509 20.528 17.9935 21.151 17.2599 20.7653L12.4653 18.2446C12.1739 18.0915 11.8259 18.0915 11.5346 18.2446L6.73997 20.7653C6.00634 21.151 5.14891 20.528 5.28902 19.7111L6.2047 14.3722C6.26034 14.0478 6.15279 13.7168 5.91711 13.4871L2.03819 9.70609C1.44467 9.12756 1.77218 8.11958 2.5924 8.0004L7.95294 7.22147C8.27865 7.17414 8.56021 6.96957 8.70588 6.67443L11.1032 1.81696Z" fill="url(#paint0_linear_757_3174)" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1146 6.76294L12.7173 1.90547C12.4239 1.31087 11.576 1.31087 11.2825 1.90547L8.88522 6.76294C8.71043 7.11711 8.37255 7.36259 7.9817 7.41939L2.62116 8.19832C1.96499 8.29367 1.70298 9.10005 2.17779 9.56288L6.05671 13.3439C6.33953 13.6196 6.46859 14.0168 6.40183 14.406L5.48614 19.7449C5.37405 20.3984 6.06 20.8968 6.6469 20.5883L11.4415 18.0676C11.7911 17.8838 12.2087 17.8838 12.5583 18.0676L17.3529 20.5883C17.9398 20.8968 18.6258 20.3984 18.5137 19.7449L17.598 14.406C17.5312 14.0168 17.6603 13.6196 17.9431 13.3439L21.822 9.56287C22.2969 9.10005 22.0348 8.29367 21.3787 8.19832L16.0181 7.41939C15.6273 7.36259 15.2894 7.11711 15.1146 6.76294ZM12.8967 1.81696C12.5298 1.07371 11.47 1.07371 11.1032 1.81696L8.70588 6.67443C8.56021 6.96957 8.27865 7.17414 7.95294 7.22147L2.5924 8.0004C1.77218 8.11958 1.44467 9.12756 2.03819 9.70609L5.91711 13.4871C6.15279 13.7168 6.26034 14.0478 6.2047 14.3722L5.28902 19.7111C5.14891 20.528 6.00634 21.151 6.73997 20.7653L11.5346 18.2446C11.8259 18.0915 12.1739 18.0915 12.4653 18.2446L17.2599 20.7653C17.9935 21.151 18.8509 20.528 18.7108 19.7111L17.7951 14.3722C17.7395 14.0478 17.847 13.7168 18.0827 13.4871L21.9616 9.70609C22.5552 9.12755 22.2276 8.11958 21.4074 8.0004L16.0469 7.22147C15.7212 7.17414 15.4396 6.96957 15.294 6.67443L12.8967 1.81696Z" fill="#25AAA5" />
                        <defs>
                            <linearGradient id="paint0_linear_757_3174" x1="11.9999" y1="-3.05168e-05" x2="11.9999" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C5FFFE" />
                                <stop offset="1" stopColor="#25AAA5" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        <strong>Réservations Simplifiées :</strong> Proposez à vos clients de réserver des rendez-vous en fonction de vos prestations et de vos disponibilités, facilement et rapidement.
                    </p>
                </li>
                <li>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1032 1.81696C11.47 1.07371 12.5298 1.07371 12.8967 1.81696L15.294 6.67443C15.4396 6.96957 15.7212 7.17414 16.0469 7.22147L21.4074 8.0004C22.2276 8.11958 22.5552 9.12755 21.9616 9.70609L18.0827 13.4871C17.847 13.7168 17.7395 14.0478 17.7951 14.3722L18.7108 19.7111C18.8509 20.528 17.9935 21.151 17.2599 20.7653L12.4653 18.2446C12.1739 18.0915 11.8259 18.0915 11.5346 18.2446L6.73997 20.7653C6.00634 21.151 5.14891 20.528 5.28902 19.7111L6.2047 14.3722C6.26034 14.0478 6.15279 13.7168 5.91711 13.4871L2.03819 9.70609C1.44467 9.12756 1.77218 8.11958 2.5924 8.0004L7.95294 7.22147C8.27865 7.17414 8.56021 6.96957 8.70588 6.67443L11.1032 1.81696Z" fill="url(#paint0_linear_757_3174)" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1146 6.76294L12.7173 1.90547C12.4239 1.31087 11.576 1.31087 11.2825 1.90547L8.88522 6.76294C8.71043 7.11711 8.37255 7.36259 7.9817 7.41939L2.62116 8.19832C1.96499 8.29367 1.70298 9.10005 2.17779 9.56288L6.05671 13.3439C6.33953 13.6196 6.46859 14.0168 6.40183 14.406L5.48614 19.7449C5.37405 20.3984 6.06 20.8968 6.6469 20.5883L11.4415 18.0676C11.7911 17.8838 12.2087 17.8838 12.5583 18.0676L17.3529 20.5883C17.9398 20.8968 18.6258 20.3984 18.5137 19.7449L17.598 14.406C17.5312 14.0168 17.6603 13.6196 17.9431 13.3439L21.822 9.56287C22.2969 9.10005 22.0348 8.29367 21.3787 8.19832L16.0181 7.41939C15.6273 7.36259 15.2894 7.11711 15.1146 6.76294ZM12.8967 1.81696C12.5298 1.07371 11.47 1.07371 11.1032 1.81696L8.70588 6.67443C8.56021 6.96957 8.27865 7.17414 7.95294 7.22147L2.5924 8.0004C1.77218 8.11958 1.44467 9.12756 2.03819 9.70609L5.91711 13.4871C6.15279 13.7168 6.26034 14.0478 6.2047 14.3722L5.28902 19.7111C5.14891 20.528 6.00634 21.151 6.73997 20.7653L11.5346 18.2446C11.8259 18.0915 12.1739 18.0915 12.4653 18.2446L17.2599 20.7653C17.9935 21.151 18.8509 20.528 18.7108 19.7111L17.7951 14.3722C17.7395 14.0478 17.847 13.7168 18.0827 13.4871L21.9616 9.70609C22.5552 9.12755 22.2276 8.11958 21.4074 8.0004L16.0469 7.22147C15.7212 7.17414 15.4396 6.96957 15.294 6.67443L12.8967 1.81696Z" fill="#25AAA5" />
                        <defs>
                            <linearGradient id="paint0_linear_757_3174" x1="11.9999" y1="-3.05168e-05" x2="11.9999" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C5FFFE" />
                                <stop offset="1" stopColor="#25AAA5" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        <strong>Couverture Géographique :</strong> Définissez votre zone de service pour rencontrer des clients  et gérer votre zone de prospection.
                    </p>
                </li>

                <li>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1032 1.81696C11.47 1.07371 12.5298 1.07371 12.8967 1.81696L15.294 6.67443C15.4396 6.96957 15.7212 7.17414 16.0469 7.22147L21.4074 8.0004C22.2276 8.11958 22.5552 9.12755 21.9616 9.70609L18.0827 13.4871C17.847 13.7168 17.7395 14.0478 17.7951 14.3722L18.7108 19.7111C18.8509 20.528 17.9935 21.151 17.2599 20.7653L12.4653 18.2446C12.1739 18.0915 11.8259 18.0915 11.5346 18.2446L6.73997 20.7653C6.00634 21.151 5.14891 20.528 5.28902 19.7111L6.2047 14.3722C6.26034 14.0478 6.15279 13.7168 5.91711 13.4871L2.03819 9.70609C1.44467 9.12756 1.77218 8.11958 2.5924 8.0004L7.95294 7.22147C8.27865 7.17414 8.56021 6.96957 8.70588 6.67443L11.1032 1.81696Z" fill="url(#paint0_linear_757_3174)" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1146 6.76294L12.7173 1.90547C12.4239 1.31087 11.576 1.31087 11.2825 1.90547L8.88522 6.76294C8.71043 7.11711 8.37255 7.36259 7.9817 7.41939L2.62116 8.19832C1.96499 8.29367 1.70298 9.10005 2.17779 9.56288L6.05671 13.3439C6.33953 13.6196 6.46859 14.0168 6.40183 14.406L5.48614 19.7449C5.37405 20.3984 6.06 20.8968 6.6469 20.5883L11.4415 18.0676C11.7911 17.8838 12.2087 17.8838 12.5583 18.0676L17.3529 20.5883C17.9398 20.8968 18.6258 20.3984 18.5137 19.7449L17.598 14.406C17.5312 14.0168 17.6603 13.6196 17.9431 13.3439L21.822 9.56287C22.2969 9.10005 22.0348 8.29367 21.3787 8.19832L16.0181 7.41939C15.6273 7.36259 15.2894 7.11711 15.1146 6.76294ZM12.8967 1.81696C12.5298 1.07371 11.47 1.07371 11.1032 1.81696L8.70588 6.67443C8.56021 6.96957 8.27865 7.17414 7.95294 7.22147L2.5924 8.0004C1.77218 8.11958 1.44467 9.12756 2.03819 9.70609L5.91711 13.4871C6.15279 13.7168 6.26034 14.0478 6.2047 14.3722L5.28902 19.7111C5.14891 20.528 6.00634 21.151 6.73997 20.7653L11.5346 18.2446C11.8259 18.0915 12.1739 18.0915 12.4653 18.2446L17.2599 20.7653C17.9935 21.151 18.8509 20.528 18.7108 19.7111L17.7951 14.3722C17.7395 14.0478 17.847 13.7168 18.0827 13.4871L21.9616 9.70609C22.5552 9.12755 22.2276 8.11958 21.4074 8.0004L16.0469 7.22147C15.7212 7.17414 15.4396 6.96957 15.294 6.67443L12.8967 1.81696Z" fill="#25AAA5" />
                        <defs>
                            <linearGradient id="paint0_linear_757_3174" x1="11.9999" y1="-3.05168e-05" x2="11.9999" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C5FFFE" />
                                <stop offset="1" stopColor="#25AAA5" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        <strong>Gestion des Rendez-Vous :</strong> Confirmez, annulez, et suivez facilement vos rendez-vous à travers notre interface intuitive.
                    </p>
                </li>
                <li>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1032 1.81696C11.47 1.07371 12.5298 1.07371 12.8967 1.81696L15.294 6.67443C15.4396 6.96957 15.7212 7.17414 16.0469 7.22147L21.4074 8.0004C22.2276 8.11958 22.5552 9.12755 21.9616 9.70609L18.0827 13.4871C17.847 13.7168 17.7395 14.0478 17.7951 14.3722L18.7108 19.7111C18.8509 20.528 17.9935 21.151 17.2599 20.7653L12.4653 18.2446C12.1739 18.0915 11.8259 18.0915 11.5346 18.2446L6.73997 20.7653C6.00634 21.151 5.14891 20.528 5.28902 19.7111L6.2047 14.3722C6.26034 14.0478 6.15279 13.7168 5.91711 13.4871L2.03819 9.70609C1.44467 9.12756 1.77218 8.11958 2.5924 8.0004L7.95294 7.22147C8.27865 7.17414 8.56021 6.96957 8.70588 6.67443L11.1032 1.81696Z" fill="url(#paint0_linear_757_3174)" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1146 6.76294L12.7173 1.90547C12.4239 1.31087 11.576 1.31087 11.2825 1.90547L8.88522 6.76294C8.71043 7.11711 8.37255 7.36259 7.9817 7.41939L2.62116 8.19832C1.96499 8.29367 1.70298 9.10005 2.17779 9.56288L6.05671 13.3439C6.33953 13.6196 6.46859 14.0168 6.40183 14.406L5.48614 19.7449C5.37405 20.3984 6.06 20.8968 6.6469 20.5883L11.4415 18.0676C11.7911 17.8838 12.2087 17.8838 12.5583 18.0676L17.3529 20.5883C17.9398 20.8968 18.6258 20.3984 18.5137 19.7449L17.598 14.406C17.5312 14.0168 17.6603 13.6196 17.9431 13.3439L21.822 9.56287C22.2969 9.10005 22.0348 8.29367 21.3787 8.19832L16.0181 7.41939C15.6273 7.36259 15.2894 7.11711 15.1146 6.76294ZM12.8967 1.81696C12.5298 1.07371 11.47 1.07371 11.1032 1.81696L8.70588 6.67443C8.56021 6.96957 8.27865 7.17414 7.95294 7.22147L2.5924 8.0004C1.77218 8.11958 1.44467 9.12756 2.03819 9.70609L5.91711 13.4871C6.15279 13.7168 6.26034 14.0478 6.2047 14.3722L5.28902 19.7111C5.14891 20.528 6.00634 21.151 6.73997 20.7653L11.5346 18.2446C11.8259 18.0915 12.1739 18.0915 12.4653 18.2446L17.2599 20.7653C17.9935 21.151 18.8509 20.528 18.7108 19.7111L17.7951 14.3722C17.7395 14.0478 17.847 13.7168 18.0827 13.4871L21.9616 9.70609C22.5552 9.12755 22.2276 8.11958 21.4074 8.0004L16.0469 7.22147C15.7212 7.17414 15.4396 6.96957 15.294 6.67443L12.8967 1.81696Z" fill="#25AAA5" />
                        <defs>
                            <linearGradient id="paint0_linear_757_3174" x1="11.9999" y1="-3.05168e-05" x2="11.9999" y2="24" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#C5FFFE" />
                                <stop offset="1" stopColor="#25AAA5" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        <strong>Facturation :</strong> Générez et retrouvez des factures en toute simplicité pour une gestion financière sans tracas.
                    </p>
                </li>
            </ul>

            <div class="offer-footer">
                <p>Rejoignez notre réseau professionnel sans engagement initial : vous ne payerez votre abonnement qu'une fois votre demande rigoureusement évaluée et approuvée.</p>
            </div>
        </div>
    );
};

export default Pricing;