import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import RdvList from '../../components/RdvList';
import { useAuth } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import useFetch from '../../hooks/useFetch';
import AddressAuto from '../../components/AddressAuto';
import DOMPurify from 'dompurify';
import validator from 'validator';
import ListFiltre from '../../components/ListFiltre';
import { is } from 'date-fns/locale';
import { useLocation } from 'react-router-dom';
import { useAlert } from '../../contexts/AlertContext';
import Footer from '../../components/Footer';
import NotifPage from '../../components/NotifPage';
import Factures from '../../components/Factures';

const Compte = () => {
    const { openModal, openModalWrdv } = useModal();

    // recupère l'url  
    const location = useLocation();
    // Récupérer la partie de l'URL après /compte
    const subpath = location.pathname.replace('/compte', '');

    const { userInfo, verifyToken, logout, isAuthenticated } = useAuth();
    const [errors, setErrors] = useState({});
    const [dataSend, setDataSend] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const { data: compteUpdate, loading: compteUpdateloading } = useFetch(`/demande-suppressions/${userInfo?.id}`, 'PUT', submitData, true, dataSend);
    const [formState, setFormState] = useState({
        email: '',
        nom: '',
        prenom: '',
        adresse: '',
        telephone: ''
    });
    const [menuPage, setMenuPage] = useState('votre compte');
    const [btnSave, setBtnSave] = useState(false);
    const items = [
        { name: 'votre compte', label: 'Votre compte', active: menuPage === 'votre compte' },
        { name: 'factures', label: 'Factures', active: menuPage === 'factures' },
        { name: 'deconnexion', label: 'Deconnexion', active: menuPage === 'deconnexion' && logout() },
        // Ajoutez plus de filtres ici selon vos besoins
    ];
    const { openAlert } = useAlert();


    useEffect(() => {
        if (subpath.startsWith('/evaluations/')) {
            const rdv = parseInt(subpath.split('/')[2]);

            openModalWrdv("rate", rdv)
        }
        else if (subpath.startsWith('/rdv/')) {

        }


    }, [subpath]);

    useEffect(() => {
        if (!isAuthenticated()) {
            window.location.href = '/';
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (compteUpdate) {
            setDataSend(false);
            verifyToken();
            openAlert('Votre compte a été mis à jour avec succès.', true);
        }
    }, [compteUpdate]);

    useEffect(() => {
        if (userInfo) {
            const updatedFormState = {
                ...formState, // Conserve les valeurs existantes de formState
                email: userInfo.email || '',
                user: userInfo.id || '',
                nom: userInfo.nom || '',
                prenom: userInfo.prenom || '',
                adresse: userInfo.adresse ? userInfo.adresse : '',
                telephone: userInfo.telephone || '',
            }
            setFormState(updatedFormState);
        }
    }, [userInfo]);

    const validateFields = () => {
        let newErrors = {};
        let isValid = true;

        // Email validation et sanitation
        if (!formState.email) {
            newErrors.email = "L'email est requis.";
            isValid = false;
        } else if (!validator.isEmail(formState.email)) {
            newErrors.email = "L'adresse email est invalide.";
            isValid = false;
        } else {
            formState.email = validator.normalizeEmail(formState.email);
        }

        // Nom et Prénom sanitation (simple exemple, peut être étendu/adapté)
        ['nom', 'prenom'].forEach(field => {
            if (!formState[field]) {
                newErrors[field] = `Le ${field} est requis.`;
                isValid = false;
            } else {
                formState[field] = validator.escape(formState[field]);
            }
        });

        // Adresse sanitation
        if (!formState.adresse) {
            newErrors.adresse = "L'adresse est requise.";
            isValid = false;
        }

        // Téléphone validation
        if (!formState.telephone) {
            newErrors.telephone = "Le numéro de téléphone est requis.";
            isValid = false;
        } else if (!validator.isMobilePhone(formState.telephone, 'any', { strictMode: false })) {
            newErrors.telephone = "Le numéro de téléphone est invalide.";
            isValid = false;
        }

        // Password validation
        if (formState.password && formState.password.length > 0) {
            let passwordError = "";
            let currentPasswordError = "";
            if (formState.password.length < 8) {
                passwordError += "Le mot de passe doit contenir au moins 8 caractères. ";
            }
            if (!/\d/.test(formState.password)) {
                passwordError += "Le mot de passe doit contenir au moins un chiffre. ";
            }
            if (!/[!@#$%^&*]/.test(formState.password)) {
                passwordError += "Le mot de passe doit contenir au moins un caractère spécial (!@#$%^&*). ";
            }
            if (passwordError) {
                newErrors.password = passwordError;
                isValid = false;
            }
            if (!formState.currentPassword) {
                newErrors.currentPassword = "Le mot de passe actuel est necessaire por changer le mot de passe.";
                isValid = false;
            }
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const sanitizedValue = sanitizeInput(value);
        setFormState({
            ...formState,
            [name]: type === 'checkbox' ? checked : sanitizedValue
        });
    };


    const sanitizeInput = (input) => {
        const sanitizedInput = DOMPurify.sanitize(input);
        return sanitizedInput;
    };

    useEffect(() => {
        if (userInfo) {
            // Création d'un nouvel objet avec les données mises à jour
            const updatedFormState = {
                ...formState, // Conserve les valeurs existantes de formState
                email: userInfo.email || '',
                user: userInfo.id || '',
                nom: userInfo.nom || '',
                prenom: userInfo.prenom || '',
                adresse: userInfo.adresse ? userInfo.adresse : '',
                telephone: userInfo.telephone || '',
                // Ajoutez ici d'autres champs si nécessaire
            };
            // Mise à jour de l'état formState avec le nouvel objet
            setFormState(updatedFormState);
        }
    }, [userInfo]);

    const handleSubmit = () => {
        if (validateFields()) {
            const formData = new FormData();
            const sanitizedFormState = {};

            for (const key in formState) {
                // verifie si c'est password et si c'est vide on ne l'envoie pas
                if (key === 'password' && formState[key] === '') {
                    continue;
                }
                if (key === 'currentPassword' && formState[key] === '') {
                    continue;
                }
                if (key === 'adresse') {
                    sanitizedFormState[key] = formState[key];
                    continue;
                }
                sanitizedFormState[key] = sanitizeInput(formState[key]);
            }

            formData.append('data', JSON.stringify(sanitizedFormState));
            setSubmitData(formData);
            setDataSend(true);
        }
    };

    return (
        <div className=''>
            <Header />
            <section className='moncompte'>

            <div className='contentfront'>
                <ListFiltre filters={items} titre="" direct="verti" setFilter={setMenuPage} />

                {menuPage === 'factures' &&
                    <Factures statut={"valide"} />
            }

                {menuPage === 'votre compte' && 
                        <div className="left">
                            <h3>Prochains</h3>
                            <RdvList typeList="client" />
                        </div>}
                        {menuPage === 'votre compte' && 
                        <div className="right">
                            <h3>Vos informations</h3>
                            <form>
                                <AddressAuto err={errors.adresse ? errors.adresse : false} onAddressSelect={(address) => setFormState({ ...formState, adresse: address })} newSelect={formState.adresse} />
                                <div className="form-group line">
                                    <label className='desc' htmlFor="prenom">Prénom</label>
                                    <input
                                        type="text"
                                        className="inputs"
                                        name="prenom"
                                        value={formState.prenom}
                                        onChange={handleInputChange}
                                        placeholder="Prénom"
                                    />
                                    {errors.prenom && <p className="error">{errors.prenom}</p>}
                                </div>
                                <div className="form-group line">
                                    <label className='desc' htmlFor="nom">Nom</label>
                                    <input
                                        type="text"
                                        className="inputs"
                                        name="nom"
                                        value={formState.nom}
                                        onChange={handleInputChange}
                                        placeholder="Nom"
                                    />
                                    {errors.nom && <p className="error">{errors.nom}</p>}
                                </div>
                                <div className="form-group line">
                                    <label className='desc' htmlFor="email">Adresse email</label>
                                    <input
                                        type="email"
                                        className="inputs"
                                        name="email"
                                        value={formState.email}
                                        onChange={handleInputChange}
                                        placeholder="Email"
                                    />
                                    {errors.email && <p className="error">{errors.email}</p>}
                                </div>
                                <div className="form-group line">
                                    <label className='desc' htmlFor="telephone">Numéro de téléphone</label>
                                    <input
                                        type="tel"
                                        className="inputs"
                                        name="telephone"
                                        value={formState.telephone}
                                        onChange={handleInputChange}
                                        placeholder="Numéro de téléphone"
                                    />
                                    {errors.telephone && <p className="error">{errors.telephone}</p>}
                                </div>
                                <div className="form-group line">
                                    <label htmlFor="password">Mot de passe</label>
                                    <input
                                        type="password"
                                        className="inputs"
                                        name="currentPassword"
                                        value={formState.currentPassword}
                                        onChange={handleInputChange}
                                        placeholder="Mot de passe"
                                    />

                                    {errors.currentPassword && <p className="error">{errors.currentPassword}</p>}
                                </div>
                                <div className="form-group line">
                                    <label htmlFor="confirmPassword">Nouveau mot de passe</label>
                                    <input
                                        type="password"
                                        className="inputs"
                                        name="password"
                                        value={formState.password}
                                        onChange={handleInputChange}
                                        placeholder="Votre nouveau mot de passe"
                                    />
                                    <p className='desc'> ( Au moins 8 caractères, un chiffre et un caractère special )
                                    </p>
                                    {errors.password && <p className="error">{errors.password}</p>}
                                </div>
                                <div className="form-group">
                                    <btn class={btnSave ? "btn-front spe" : "btn-front spe inactive"} onClick={handleSubmit}><span></span><p> Mettre à jour</p><div class="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.8795 15.2253L4.96384 14.1724L4.9639 14.1724L7.88392 11.3369L7.88395 11.3369L11.1798 8.13634L11.2394 8.13634L11.2096 8.10744L11.2396 8.07835L11.1796 8.07835L8.29688 5.27897L8.29701 5.27885L6.79983 3.82497L6.79933 3.82497L5.37661 2.4434L5.37675 2.44328L3.87956 0.989401L0.162947 0.9894L3.10546 3.8468L3.10533 3.84693L4.60252 5.30081L4.60301 5.30081L6.02569 6.68233L6.02556 6.68246L7.49299 8.10744L6.61576 8.9593L6.61573 8.95927L3.51856 11.9669L3.5185 11.9668L0.162881 15.2253L3.8795 15.2253Z" fill="#006963"></path></svg></div></btn>

                                </div>
                                <div className="btns">
                                    <button className='btn-base danger full' type='button' onClick={() => openModal('deletecompte')}><p>Supprimer mon compte</p></button>

                                </div>

                            </form>

                        </div> 
                        
                    }
                    </div>
            </section>
            <Footer></Footer>
        </div>
    );
};

export default Compte;