import React, { useEffect, useState } from "react";
import Hearderpro from "../../components/Hearderpro";
import Leftmenu from "../../components/LeftMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import DOMPurify from "dompurify";
import NotifPage from "../../components/NotifPage";
import SousMenuPro from "../../components/Sousmenupro";
import validator from "validator";
import useFetch from "../../hooks/useFetch";
import AddressAuto from "../../components/AddressAuto";
import { useAuth } from "../../contexts/AuthContext";
import { useAlert } from "../../contexts/AlertContext";
import { set } from "date-fns";
import { useModal } from "../../contexts/ModalContext";
import Loader from "../../components/Loader";
import PayStripe from "../../components/PayStripe";
import PayInfo from "../../components/PayInfo";
import SimpleSwitch from "../../components/SimpleSwitch";
import DatePicker from "react-datepicker";

const Settings = () => {
  const [activeItem, setActiveItem] = useState("informations");
  const [submitData, setSubmitData] = useState(null);
  const [sendSubmit, setSendSubmit] = useState(false);
  const [sendPage, setSendPage] = useState(false);
  const [newActiveItem, setNewActiveItem] = useState(null);
  const [confirmNeed, setConfirmNeed] = useState(null);
  const {
    data: proInfo,
    loading: userLoading,
    error: userError,
  } = useFetch("/pro-infos", "GET", null, true, true || reloadPay);
  const { userInfo, verifyToken } = useAuth();
  const [btnSave, setBtnSave] = useState(false);
  const [sendPay, setSendPay] = useState(false);
  const [reloadPay, setReloadPay] = useState(false); // Nouvel état pour gérer le rechargement
  const { openModal } = useModal();
  const [diffDays, setDiffDays] = useState(0);
  const {
    data: soummission,
    loading,
    error,
  } = useFetch(
    `/pro-infos/${proInfo?.id}`,
    "Put",
    submitData,
    true,
    sendSubmit
  );
  const {
    data: paymentInfo,
    loading: payLoading,
    error: payError,
  } = useFetch(
    `/stripe/getCurrentPaymentMethod/${proInfo?.pay?.id}`,
    "GET",
    null,
    true,
    sendPay
  );
  const { openAlert } = useAlert();
  const menuItems = [
    { id: "informations", label: "Informations" },
    { id: "facturation", label: "Facturation" },
  ];

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };
  const [proFormstate, setProFormstate] = useState({
    prenom: "",
    nom: "",
    mail: "",
    siret: "",
    telephone: "",
    rue: "",
    ville: "",
    codepostal: "",
    nomentreprise: "",
    newPassword: "",
    currentPassword: "",
    mediateurNom: "",
    isTaxActive: false,
    taxUpdate: null,
  });
  useEffect(() => { 
    if (userInfo && userInfo.prenom) {
      const newfoFormstate = {
        prenom: userInfo.prenom,
        nom: userInfo.nom,
      };
      // ajoute les éléments en gardant les valeurs existantes
      setProFormstate((prevState) => ({
        ...prevState,
        ...newfoFormstate,
      }));
    }
  }, [userInfo]);

  useEffect(() => {
    if (proInfo && proInfo.nomentreprise) {
      const newfoFormstate = {
        nomentreprise: proInfo.nomentreprise,
        siret: proInfo.siret,
        telephone: proInfo.telephone,
        rue: decodeHtml(proInfo.rue),
        ville: proInfo.ville,
        codepostal: proInfo.codepostal,
        mail: proInfo.mail,
        mediateurNom: proInfo.mediateurNom,
        isTaxActive: proInfo.isTaxActive,
        taxUpdate: proInfo.taxUpdate
          ? format(new Date(proInfo.taxUpdate), "dd/MM/yyyy")
          : format(parseISO(proInfo.createdAt), "dd/MM/yyyy"),
        taxPercentage: proInfo.taxPercentage,
      }; 
      // ajoute les éléments en gardant les valeurs existantes
      setProFormstate((prevState) => ({
        ...prevState,
        ...newfoFormstate,
      }));
    }
    if (proInfo && proInfo.pay && proInfo.pay.id) {
      setSendPay(true);
    }
  }, [proInfo]);

  useEffect(() => {
    if (soummission) {
      setSendPage(false);
      setSendSubmit(false);
      verifyToken();
      setBtnSave(false);
      openAlert("Votre compte a été mis à jour avec succès.", true);
      const newfoFormstate = {
        nomentreprise: soummission.proinfos.nomentreprise,
        siret: soummission.proinfos.siret,
        telephone: soummission.proinfos.telephone,
        rue: decodeHtml(soummission.proinfos.rue),
        ville: soummission.proinfos.ville,
        codepostal: soummission.proinfos.codepostal,
        mail: soummission.proinfos.mail,
        mediateurNom: soummission.proinfos.mediateurNom,
        isTaxActive: soummission.proinfos.isTaxActive,
        taxUpdate: soummission.proinfos.taxUpdate
          ? format(new Date(proInfo.taxUpdate), "dd/MM/yyyy")
          : format(parseISO(soummission.proinfos.createdAt), "dd/MM/yyyy"),
        taxPercentage: soummission.proinfos.taxPercentage,
      };
      // ajoute les éléments en gardant les valeurs existantes
      setProFormstate((prevState) => ({
        ...prevState,
        ...newfoFormstate,
      }));
    }
  }, [soummission]);

  const validateFields = () => {
    let newErrors = {};
    let isValid = true;

    if (!proFormstate.siret) {
      newErrors.siret = "Le numéro SIRET est requis.";
      isValid = false;
    } else if (!/^\d{14}$/.test(proFormstate.siret)) {
      newErrors.siret = "Le numéro SIRET doit contenir 14 chiffres.";
      isValid = false;
    }

    // Nom et Prénom sanitation (simple exemple, peut être étendu/adapté)
    ["rue", "codepostal", "nomentreprise", "nom", "prenom"].forEach((field) => {
      if (!proFormstate[field]) {
        newErrors[field] = `L'adresse est requise.`;
        if (field === "rue") {
        } else if (field === "nomentreprise") {
          newErrors[field] = `Le nom de l'entreprise est requis.`;
        } else {
          newErrors[field] = `Le ${field} est requis.`;
        }
        isValid = false;
      } else {
        proFormstate[field] = validator.escape(proFormstate[field]);
      }
    });

    if (!proFormstate.ville) {
      newErrors.ville = "La ville est requise.";
      isValid = false;
    }

    // Password validation
    if (proFormstate.newPassword.length > 0) {
      let newPasswordError = "";
      let currentPasswordError = "";
      if (proFormstate.newPassword.length < 8) {
        newPasswordError +=
          "Le mot de passe doit contenir au moins 8 caractères. ";
      }
      if (!/\d/.test(proFormstate.newPassword)) {
        newPasswordError +=
          "Le mot de passe doit contenir au moins un chiffre. ";
      }
      if (!/[!@#$%^&*]/.test(proFormstate.newPassword)) {
        newPasswordError +=
          "Le mot de passe doit contenir au moins un caractère spécial (!@#$%^&*). ";
      }
      if (newPasswordError) {
        newErrors.newPassword = newPasswordError;
        isValid = false;
      }
      if (!proFormstate.currentPassword) {
        newErrors.currentPassword =
          "Le mot de passe actuel est necessaire por changer le mot de passe.";
        isValid = false;
      }
    }

    // Téléphone validation
    if (!proFormstate.telephone) {
      newErrors.telephone = "Le numéro de téléphone est requis.";
      isValid = false;
    } else if (
      !validator.isMobilePhone(proFormstate.telephone, "any", {
        strictMode: false,
      })
    ) {
      newErrors.telephone = "Le numéro de téléphone est invalide.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sanitizeInput = (input) => {
    const sanitizedInput = DOMPurify.sanitize(input);
    return sanitizedInput;
  };

  const handleInputChange = (e) => {
    setBtnSave(true);
    const { name, value, type, checked } = e.target;
    const sanitizedValue = sanitizeInput(decodeHtml(value));
    setProFormstate({
      ...proFormstate,
      [name]: type === "checkbox" ? checked : sanitizedValue,
    });
  };

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/")[3];
    if (path) setActiveItem(path);
  }, [location]);

  const handleSetActiveItem = (newItem) => {
    setActiveItem(newItem);
    navigate(`/pro/parametres/${newItem}`); // Utilisation de useNavigate pour changer l'URL
  };

  useEffect(() => {
    if (submitData) {
      setSendSubmit(true);
    }
  }, [submitData]);

  

  const handleSubmit = () => {
    if (validateFields()) {
      const formData = new FormData();
      const sanitizedFormState = {};
      for (const key in proFormstate) {
        if (key === "ville") {
          sanitizedFormState["ville"] = proFormstate[key];
        } else if (key === "taxUpdate") {
          if (proFormstate.isTaxActive !== proInfo.isTaxActive) {
            sanitizedFormState["taxUpdate"] = format(new Date(), "yyyy-MM-dd");
          } else {
            sanitizedFormState["taxUpdate"] = format(
              new Date(proInfo.taxUpdate),
              "yyyy-MM-dd"
            );
          }
        } else if (key === "isTaxActive") {
          sanitizedFormState[key] = proFormstate[key] ? true : false;
        }
        else if (key === "rue" || key === "ville" || key === "nomentreprise") {
          sanitizedFormState[key] = decodeHtml(sanitizeInput(proFormstate[key]));
        }
        else {
          sanitizedFormState[key] = sanitizeInput(proFormstate[key]);
        }
      }

      formData.append("data", JSON.stringify(sanitizedFormState));
      setSubmitData(formData);
    }
  };

  useEffect(() => {
    if (proInfo?.pay?.actif === false) {
      const lastUpdate = proInfo.pay.lastpayUpdate; // '2024-03-30'
      const lastUpdateDate = new Date(lastUpdate);
      const targetDate = new Date(
        lastUpdateDate.setDate(lastUpdateDate.getDate() + 7)
      );
      const currentDate = new Date();
      const diffTime = Math.abs(targetDate - currentDate);
      setDiffDays(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    }
    // Ce code s'exécutera à chaque fois que proInfo change, grâce à l'ajout de proInfo dans le tableau de dépendances
  }, [proInfo]);

  const reloadPayment = (shouldReload) => {
    if (shouldReload) {
      window.location.reload(); // Reload the entire page
    }
  };

  return (
    <div className="page ">
      <NotifPage />
      <Leftmenu />
      <div className="content accueilpro">
        <Hearderpro titre="Paramètres" />

        <SousMenuPro
          items={menuItems}
          confirm={(e) => setConfirmNeed(e)}
          activeItem={activeItem}
          setActiveItem={(e) =>
            confirmNeed ? setNewActiveItem(e) : handleSetActiveItem(e)
          }
        />

        {activeItem === "facturation" && (
          <div
            className={
              proInfo?.statut === "non-valide" ? "flex-h non-active" : "flex-h"
            }
          >
            <div className="center settings">
              {(proInfo?.pay?.actif === false ||
                proInfo?.pay?.length === 0) && (
                <div className="alert">
                  {proInfo?.pay?.problem === "subscription" && (
                    <p>
                      Votre paiement a <strong>échoué</strong>, veuillez mettre
                      à jour vos informations de paiement dans les {diffDays}{" "}
                      prochains jours, avant que votre compte ne soit désactivé.
                    </p>
                  )}
                  {proInfo?.pay?.problem === "planchanged" && (
                    <p>
                      L'offre Bykahomes a changé, veuillez mettre à jour vos
                      informations de paiement dans les {diffDays} prochains
                      jours, avant que votre compte ne soit désactivé.
                    </p>
                  )}
                </div>
              )}
              {payLoading ||
              !proInfo ||
              (proInfo?.pay.id && !sendPay) ||
              (reloadPay && !paymentInfo) ? (
                <Loader />
              ) : !proInfo?.pay?.actif || !proInfo?.pay ? (
                <PayStripe
                  pro={proFormstate}
                  reload={reloadPayment}
                  type={"create"}
                />
              ) : (
                paymentInfo && <PayInfo paymentInfo={paymentInfo} />
              )}
            </div>
          </div>
        )}

        {activeItem === "informations" && proInfo && (
          <div className="flex-h">
            {userLoading ? (
              <Loader />
            ) : (
              <div className="center settings">
                <div className="tsave">
                  <h3>Vos informations</h3>
                  <div className="actions">
                    {" "}
                    <button
                      className={btnSave ? "btn-pro  " : "btn-pro off"}
                      onClick={() => handleSubmit()}
                    >
                      {" "}
                      <p>Sauvegarder</p>
                    </button>
                  </div>
                </div>
                {proInfo && proInfo.nomentreprise && proFormstate?.prenom && (
                  <form action="">
                    <div className="form-group line">
                      <label htmlFor="prenom  line">Prénom</label>
                      <input
                        type="text"
                        className="inputs"
                        name="prenom"
                        value={proFormstate?.prenom}
                        onChange={handleInputChange}
                        placeholder="Prénom"
                      />
                      {errors.prenom && (
                        <p className="error">{errors.prenom}</p>
                      )}
                    </div>
                    <div className="form-group line">
                      <label htmlFor="nom">Nom</label>
                      <input
                        type="text"
                        className="inputs"
                        name="nom"
                        value={proFormstate?.nom}
                        onChange={handleInputChange}
                        placeholder="Nom"
                      />
                      {errors.nom && <p className="error">{errors.nom}</p>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">email</label>
                      <input
                        type="mail"
                        className="inputs"
                        name="mail"
                        value={proFormstate.mail}
                        onChange={handleInputChange}
                        placeholder="email"
                      />
                      {errors.mail && <p className="error">{errors.mail}</p>}
                    </div>
                    <div className="form-group line">
                      <label htmlFor="password">Mot de passe</label>
                      <input
                        type="password"
                        className="inputs"
                        name="currentPassword"
                        value={proFormstate.currentPassword}
                        onChange={handleInputChange}
                        placeholder="Mot de passe"
                      />

                      {errors.currentPassword && (
                        <p className="error">{errors.currentPassword}</p>
                      )}
                    </div>
                    <div className="form-group line">
                      <label htmlFor="confirmPassword">
                        Nouveau mot de passe
                      </label>
                      <input
                        type="password"
                        className="inputs"
                        name="newPassword"
                        value={proFormstate.newPassword}
                        onChange={handleInputChange}
                        placeholder="Votre nouveau mot de passe"
                      />
                      <p className="desc">
                        {" "}
                        ( Au moins 8 caractères, un chiffre et un caractère
                        special )
                      </p>
                      {errors.newPassword && (
                        <p className="error">{errors.newPassword}</p>
                      )}
                    </div>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <h3>Votre entreprise</h3>
                        <div className="tva form-group">
                          <div className="form-group tva">
                            <SimpleSwitch
                              checked={proFormstate.isTaxActive}
                              onChange={(e) => {
                                setBtnSave(true);
                                setProFormstate((prevState) => ({
                                  ...prevState,
                                  isTaxActive: !proFormstate.isTaxActive,
                                }));
                              }}
                            />
                            <h4 htmlFor="statistique">TVA</h4>
                            {proFormstate.isTaxActive && (
                              <div className="form-group">
                                <label>Pourcentage de TVA</label>
                                <input
                                  type="number"
                                  className="inputs mid"
                                  value={proFormstate.taxPercentage}
                                  onChange={(e) => {
                                    setBtnSave(true);
                                    setProFormstate((prevState) => ({
                                      ...prevState,
                                      taxPercentage: e.target.value,
                                    }));
                                  }}
                                  min={0}
                                  max={100}
                                />
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label>Dernière mise à jour: </label>
                            <p>
                              {" "}
                              <strong> {proFormstate.taxUpdate}</strong>
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="nomentreprise">
                            Nom de l'entreprise
                          </label>
                          <input
                            type="text"
                            className="inputs"
                            name="nomentreprise"
                            value={proFormstate.nomentreprise}
                            onChange={handleInputChange}
                            placeholder="Nom de l'entreprise"
                          />
                          {errors.nomentreprise && (
                            <p className="error">{errors.nomentreprise}</p>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="siret">SIRET</label>
                          <input
                            type="text"
                            className="inputs"
                            name="siret"
                            value={proFormstate.siret}
                            onChange={handleInputChange}
                            placeholder="SIRET"
                          />
                          {errors.siret && (
                            <p className="error">{errors.siret}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="telephone">Téléphone</label>
                          <input
                            type="tel"
                            className="inputs"
                            name="telephone"
                            value={proFormstate.telephone}
                            onChange={handleInputChange}
                            placeholder="Téléphone"
                          />
                          {errors.telephone && (
                            <p className="error">{errors.telephone}</p>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="rue">Adresse</label>
                          <input
                            type="text"
                            className="inputs"
                            name="rue"
                            value={proFormstate.rue}
                            onChange={handleInputChange}
                            placeholder="Rue"
                          />
                          {errors.rue && <p className="error">{errors.rue}</p>}
                        </div>
                        <AddressAuto
                          err={errors.ville ? errors.ville : false}
                          type={"cities"}
                          onAddressSelect={(ville) =>
                            setProFormstate({ ...proFormstate, ville: ville })
                          }
                          newSelect={proFormstate.ville}
                        ></AddressAuto>
                        <div className="form-group line">
                          <label htmlFor="codepostal">Code postal</label>
                          <input
                            type="tel"
                            className="inputs"
                            name="codepostal"
                            value={proFormstate.codepostal}
                            onChange={handleInputChange}
                            placeholder="Code postal"
                          />
                          {errors.codepostal && (
                            <p className="error">Le code postal est requis</p>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="codepostal">Votre médiateur</label>
                          <input
                            type="text"
                            className="inputs"
                            name="mediateurNom"
                            value={proFormstate.mediateurNom}
                            onChange={handleInputChange}
                            placeholder="Nom du médiateur"
                          />
                          {errors.mediateurNom && (
                            <p className="error">Le code postal est requis</p>
                          )}
                        </div>
                      </>
                    )}
                  </form>
                )}
                <button
                  className="btn-base danger"
                  onClick={() => openModal("deletecompte")}
                >
                  <p>Supprimer mon compte</p>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
