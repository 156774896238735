import React, { useContext, useState, useCallback, useEffect } from 'react';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import MapsApiContext from '../contexts/MapLoadContext';
import AddressAuto from './AddressAuto';
import { useAuth } from '../contexts/AuthContext';
import useFetch from "../hooks/useFetch";
import Loader from "./Loader";
import { data } from 'jquery';

import {useAlert} from '../contexts/AlertContext';

const containerStyle = {
    width: '100%',
    height: '100%'
};
 



const RayonMap = ({ dataPage, newData, confirm , statut}) => {

    const [submitData, setSubmitData] = useState(null);
    const [sendSubmit, setSendSubmit] = useState(false);
    const [idPage, setIdPage] = useState(null);
    const [btnSave, setBtnSave] = useState(false);
    const {openAlert} = useAlert();

    const isLoaded = useContext(MapsApiContext);
    const [map, setMap] = useState(null);
    const [adresse, setAdresse] = useState({ value: '' });
    const [radius, setRadius] = useState(0); // Rayon initial en mètres
    const [temps, setTemps] = useState(0); // Rayon initial en mètres
    const [mapLoaded, setMapLoaded] = useState(false); // Nouvel état pour suivre le chargement de la carte
    const [mapCenter, setMapCenter] = useState(null); // Nouvel état pour suivre le chargement de la carte
    const { data: soummission, loading, error } = useFetch(`/prestataire-pages/${idPage}`, 'Put', submitData, true, sendSubmit);

    const { getUserLoc,  verifyPro   } = useAuth();

    const [valid, setValid] = useState(false);

    const [circleOptions, setCircleOptions] = useState({
        fillColor: "yellow",
        fillOpacity: 0,
        strokeColor: "black",
        strokeOpacity: 0,
        strokeWeight: 0,
    })

    useEffect(() => {
        if (soummission) {
            newData();
            verifyPro(); 
            setBtnSave(false);
            openAlert("Votre champ d'action a été mise à jour avec succès", true);
        } 
        setSendSubmit(false);
    }, [soummission, error]);




    useEffect(() => {
        if (submitData) {
            setSendSubmit(true);
        }

    }, [submitData]);

    const handleSave = async (e) => {
        // Empêcher le rechargement de la page
        e.preventDefault();
        const formData = new FormData();
        const newdata = {};

        newdata.distance = radius;
        newdata.temps = temps;
        newdata.adresse = adresse;
        newdata.lat = adresse.latLng.lat.toString();
        newdata.lng = adresse.latLng.lng.toString();

        formData.append('data', JSON.stringify(newdata));

        setSubmitData(formData);

    };


    useEffect(() => {
        if (dataPage) {
            setRadius(dataPage.distance);
            setTemps(dataPage.temps);
            setAdresse(dataPage.adresse);
            setMapCenter(dataPage.adresse.latLng);
            setIdPage(dataPage.id);
            confirm(false);
            setBtnSave(false);
            setSendSubmit(false);
            dataPage.distance && dataPage.temps && dataPage.lat ? setValid(true) : setValid(false);
        } 
    }, [dataPage]);

    
    const handleRetour = () => {
        setBtnSave(false);
        confirm(false);  
         setRadius(dataPage.distance);
        setTemps(dataPage.temps)
    }

    const handleMapLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
        // Définir un délai de 10 secondes avant d'afficher le cercle
        setTimeout(() => {
            setMapLoaded(true);
        }, 100);
        setTimeout(() => {
            setCircleOptions({
                fillColor: "#006963",
                fillOpacity: .5,
                strokeColor: "##002926",
                strokeOpacity: 1,
                strokeWeight: 2,
            });
        }, 1000);  // Mettre à jour l'état lorsque la carte est chargée
    }, []);

    const convertkm = (distanceEnMetres) => {
        const distanceEnKilometres = distanceEnMetres / 1000;
        return distanceEnKilometres.toFixed(2); // Arrondi à 2 décimales  
    }

    const newAdresse = (adresse) => {
        setAdresse(adresse);
        setMapCenter(adresse.latLng)
    }

    return isLoaded ? (
        <div className={ statut === "non-valide" ? 'flex-h non-active rayon' : 'flex-h rayon'}  >
            <div className="left">
            {valid ? null :
                    <div className="itemnotif alert">
                        <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#FF5E00" />
                            <path d="M9.88412 4.22228L9.88412 11.899L8.70462 11.899L8.70462 4.22228L9.88412 4.22228ZM9.28438 13.1785C9.51428 13.1785 9.71253 13.2568 9.87913 13.4134C10.0424 13.57 10.124 13.7583 10.124 13.9782C10.124 14.1981 10.0424 14.3863 9.87913 14.5429C9.71253 14.6995 9.51428 14.7778 9.28438 14.7778C9.05447 14.7778 8.85789 14.6995 8.69463 14.5429C8.52803 14.3863 8.44473 14.1981 8.44473 13.9782C8.44473 13.7583 8.52803 13.57 8.69463 13.4134C8.85789 13.2568 9.05447 13.1785 9.28438 13.1785Z" fill="white" />
                        </svg>
                        <p>Vous devez complêter votre champ d'action  <br/> pour que votre page soit disponible.</p>
                    </div>
                }
                <form action="">
                    {mapCenter && adresse && (
                        <AddressAuto onAddressSelect={(adresse) => { confirm(true); setBtnSave(true); newAdresse(adresse)
                        }} newSelect={adresse.value}></AddressAuto>
                    )}
                    <div className='distance form-group'>
                        <div>
                            <p className='desc'>Distance:</p>
                            <h4>{convertkm(radius)} km </h4>
                        </div>
                        <input // Slider pour le rayon
                            type="range"
                            min="1000"
                            max="20000"
                            value={radius}
                            onChange={e => { confirm(true); setBtnSave(true);setRadius(Number(e.target.value))}}
                        />
                    </div>

                    <div className='distance form-group'>
                        <div className="form-group">

                            <label htmlFor="tempsprevu">Temps prévu entre les rendez-vous en minutes</label>
                            <input
                                type="number"
                                className="inputs"
                                name='tempsprevu'
                                value={temps}
                                onChange={(e) =>{confirm(true); setBtnSave(true); setTemps(e.target.value)}}
                                placeholder="Temps en minutes"
                            />
                        </div>
                    </div>

                    <div className="actions btns">
                        <button className={btnSave ? "btn-pro scnd " : "btn-pro scnd off"} type='button' onClick={() => handleRetour()}> <p>Annuler</p></button>
                        <button className={btnSave ? "btn-pro  " : "btn-pro off"} type='button' onClick={(e) => handleSave(e)}> <p>Sauvegarder</p></button>
                    </div>
                </form>

            </div>

            <div className="right">

                {mapCenter && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapCenter}
                        zoom={10}
                        options={{ gestureHandling: 'none' }} // Bloque le déplacement de la carte
                        onLoad={handleMapLoad} // Ajout du callback onLoad

                    >
                        {mapLoaded && (
                            <div>
                                <Marker position={mapCenter} />
                                <Circle
                                    center={mapCenter}
                                    radius={radius}
                                    options={circleOptions}
                                />
                            </div>
                        )}
                    </GoogleMap>
                )}

            </div>


        </div >
    ) : <></>
};

export default RayonMap;