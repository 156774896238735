import React from 'react';
import { ReactComponent as LoadSvg } from '../LoadSvg.svg';
import { ReactComponent as LoadButtonSvg } from '../Loadbutton.svg';

const Loader = (type) => {
    return (
        <div className='loadingAnim'>
            {type === 'button' ? <LoadButtonSvg></LoadButtonSvg> :<LoadSvg></LoadSvg>}
        </div>
    );
};

export default Loader;