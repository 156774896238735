import React, { useEffect, useState } from 'react';
import AvailableCal from './AvailableCal';
import { parseISO, isBefore, isAfter, isEqual, compareDesc } from 'date-fns';

const ListCal = ({ horaires, idPage, newHoraires, actualHoraires, nActiveId }) => {
    const [openId, setOpenId] = useState(null);
    const [activeId, setActiveId] = useState(nActiveId ? nActiveId : null);
    const [isNewAgenda, setIsNewAgenda] = useState(false);
    const [allHoraires, setAllHoraires] = useState(horaires);


    const handleToggleAvailableCal = (id) => {
        setOpenId(openId === id ? null : id);
        setActiveId(id);
        const selectedHoraire = allHoraires.find(horaire => horaire.id === id);
        if (selectedHoraire) {
            actualHoraires(selectedHoraire.details, selectedHoraire.id);
        }
        setIsNewAgenda(false);
    };

    

    const handleToggleActive = (id) => {
        setActiveId(id);
        setIsNewAgenda(false);
        const selectedHoraire = allHoraires.find(horaire => horaire.id === id);
        if (selectedHoraire) {
            actualHoraires(selectedHoraire.details, selectedHoraire.id);
        }
    };

    const handleAddNewAgenda = () => {
        setOpenId(null);
        setActiveId(null);
        setIsNewAgenda(true);
    };

    const handleDelete = () => { 
        newHoraires(true);
    };

    const currentDate = new Date();

    const activeAndUpcomingHoraires = allHoraires
        .filter((horaire) => {
            const endDate = parseISO(horaire.end);
            return isAfter(endDate, currentDate) || isEqual(endDate, currentDate);
        })
        .sort((b, a) => compareDesc(parseISO(a.end), parseISO(b.end)));

    const historicalHoraires = allHoraires
        .filter((horaire) => {
            const endDate = parseISO(horaire.end);
            return isBefore(endDate, currentDate);
        })
        .sort((b, a) => compareDesc(parseISO(a.end), parseISO(b.end)));

    useEffect(() => {
        setAllHoraires(horaires);
        setOpenId(null);
    }
    , [horaires]);

    useEffect(() => { 
        if (nActiveId){ setActiveId(nActiveId); };
    }
    , [nActiveId]);

    return (
        <div className='calList'>
            {!isNewAgenda &&<button onClick={handleAddNewAgenda} className="btn-pro"><p>Ajouter un nouvel agenda</p></button>}

             {openId || isNewAgenda ? (
                horaires
                    .filter((horaire) => horaire.id === openId)
                    .map((horaire) => (
                        <AvailableCal
                            key={horaire.id}
                            id={horaire.id}
                            idPage={idPage}
                            action={"Put"} 
                            open={true}
                            active={activeId === horaire.id}
                            toggleOpen={handleToggleAvailableCal}
                            horaires={horaires}
                            toggleActive={handleToggleActive}
                            onDelete={handleDelete}  
                            actualData={(data) => { actualHoraires(data, horaire.id); }}  
                        />
                    ))
            ) :  (
                <>
                    <h2>En cours et à venir</h2>
                    {activeAndUpcomingHoraires.map((horaire) => (
                        <AvailableCal
                            key={horaire.id}
                            id={horaire.id}
                            idPage={idPage}
                            type="Actif"
                            open={false}
                            active={activeId === horaire.id}
                            toggleOpen={handleToggleAvailableCal}
                            horaires={horaires}
                            toggleActive={handleToggleActive}
                            onDelete={handleDelete}
                            actualData={(data) => { actualHoraires(data, horaire.id); }}  
                        />
                    ))}

                    <h2>Historique</h2>
                    {historicalHoraires.map((horaire) => (
                        <AvailableCal
                            key={horaire.id}
                            id={horaire.id}
                            idPage={idPage}
                            open={false}
                            type="Historique"
                            active={activeId === horaire.id}
                            toggleOpen={handleToggleAvailableCal}
                            horaires={horaires}
                            toggleActive={handleToggleActive}
                            onDelete={handleDelete}
                            actualData={(data) => { actualHoraires(data, horaire.id); }}  

                            
                        />
                    ))}
                </>

            )}

            {isNewAgenda && (
                <AvailableCal
                    id={null}
                    idPage={idPage}
                    open={true}
                    active={true}
                    type="new"
                    action={"Post"}
                    toggleOpen={handleToggleAvailableCal}
                    horaires={horaires}
                    toggleActive={handleToggleActive}
                    onDelete={handleDelete}
                    onNewHoraires={newHoraires}
                    actualData={(data) => { actualHoraires(data, activeId); }}                />
            )}

        </div>
    );
};

export default ListCal;
