import React,{ createContext, useContext, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import useFetch from '../../hooks/useFetch';
import { PDFDocument } from 'pdf-lib';
import conditionsGeneralesDeVente from '../../cgv.pdf';

const PdfView = () => {
    // récupère l'url actuel 
    const [type, setType] = useState(null);   
    const url = window.location.pathname.split('/')[1]; 
    useEffect(() => {
        if (url === 'cgu') {
            setType(1);
        } else if (url === 'cgv') {
            setType(2); 
        }
    }, [url]);
    const { data, loading, error } = useFetch(`/docs/${type}`, 'GET', null, false, type? true : false);
    const [pdfDataUri, setPdfDataUri] = React.useState("");

 
    useEffect(() => {
        if (data?.File?.url) {
            const fileUrl =  (process.env.REACT_APP_UPLOAD_URL + data?.File?.url);
            const fetchPdf = async () => {
                const existingPdfBytes = await fetch(fileUrl).then(res => res.arrayBuffer());
                const pdfDoc = await PDFDocument.load(existingPdfBytes);
                const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
                setPdfDataUri(pdfBytes);
              };
          
              fetchPdf();
        }
    }, [data]);
  
    return (
        <div className='pdfView'>
            {loading ? <div>Chargement...</div>
            :
            data?.File?.url && 
            pdfDataUri ? (
                <embed src={pdfDataUri} width="100%" height="500px" type="application/pdf" />
              ) : (
                <p>Chargement du PDF...</p>
              )}
        </div>
    );
};

export default PdfView;